import React, {useState, useEffect, useContext, useRef} from 'react';
import TourLink from '../../components/tour-link';
import { useHistory, useLocation } from 'react-router-dom';
import { setItem, getUserID } from '../../helpers/localStorage';
import FloorplanManager from '../../helpers/floorplanManager';
import { FLOORPLAN_NAME, USER_ID } from '../../constants';
import {get, updateAllFloorplans} from '../../api/floorplan';
import LoadingContext from '../../hoc/loading/index';

import './style.scss'

export default () => {
    const history = useHistory();
    const [floorplans, setFloorplans] = useState([]);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const userID = searchParams.get("userID") || getUserID();
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const scrollContainerRef = useRef()

    const createNewTour = () => {
        const layout = new FloorplanManager();
        const layer = layout.getClearFloorplan();
        setItem(FLOORPLAN_NAME, layer);

        history.push(`/new`);
    }

    useEffect(() => {
        if (!userID) {
            return alert('Please enter user ID');
        } else {
            setItem(USER_ID, userID);
            const getFloorplans = async () => {
                showLoading();
                const userId = getUserID();
                const data = await get(userId);
                setFloorplans(data);
                updateAllFloorplans(userId, data);
                hideLoading();
            }

            getFloorplans();
        }
    }, [])

    return (
        <div className="home layout">
            <div className="nav flex-row space-between align-center">
                <span className="h3">Floor Plans</span>
                <button className="btn btn-primary" onClick={createNewTour} disabled={!userID}><span>New</span></button>
            </div>
            <div className="links" ref={ scrollContainerRef }>
                <ul>
                    {floorplans.map(({name, id, floorplan_json}, ind) => (<li key={`tours-link${ind}`}>
                        <TourLink scrollContainerRef={scrollContainerRef} name={name} key={`tours-link${ind}`} floorplan_json={floorplan_json} id={id} last={ind === floorplans.length - 1 ? 'last' : ''} />
                    </li>))}
                </ul>
            </div>
        </div>
    )
};
