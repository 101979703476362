import React, { Component, createRef, memo } from 'react';
import PropTypes from 'prop-types';
import polylabel from 'polylabel';
import { STYLE_TEXT, MODE_2D_SCREENSHOT, FONT_SIZE_ASPECT } from 'a_root-folder/constants';
import { diff } from 'deep-object-diff';
import { describePolygon } from 'a_root-folder/utils/areas-helper';
import { shadowDefault } from 'a_root-folder/services/shadow';
import { isExistMergeConnection } from 'a_root-folder/utils/objects-utils';
import detectBrowser from 'a_common-folder/utils/detectBrowser';

require('a_root-folder/utils/RotatingCalipers.js');

const renderLabelTextOrInput = (area, fontSize, textRef) => {
    return (
        <text className="label-text" ref={textRef}>
            {area.get('name')}
        </text>
    );
};

const LabelTextGroup = ({ area, layer, textRef }) => {
    if (area.vertices.size < 3 || isExistMergeConnection(layer, area.id)) return null;

    const fontSize = area.properties.get('fontSize');

    return (
        <g style={{ ...STYLE_TEXT }}>
            {renderLabelTextOrInput(area, fontSize, textRef)}
        </g>
    );
};

const renderEditIcon = (textSize) => (
        <g>
            <defs>
                <pattern id="edit" width="24" height="24">
                    <image x="0" y="0" width="24" height="24" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3ElEQVRoge3UwWnDQBCG0QcpJFOMD6nFFbgSV+Bu3ITKSHKxD8nBlq2NFMJuBuYDXSQE7wdpqaqqqgic8YkjXoZqNhaYcLm5TpKMCI/4NCPCMj7FiL02PsWIg3UjjqOA8wK72b01Iz76EZcLX9/8O95mz34ace6FXCrc/7BbRkx47cN8Xnh+2qwZMX2/P6zQPipbIyb/HN8asZcE3xoxrLANfztifsR2L/wOn+qbL/xfFwo/plD4MYXCjykUfkyh8GMKifEkx5McT3I8yfEkx5McT3I8yfFVVVV9ugJnq7DbBJTmawAAAABJRU5ErkJggg=="/>
                </pattern>
            </defs>
            <rect 
            x={textSize.width / 2} 
            y={-textSize.height - 12} 
            selected={true}
            width="24" 
            height="24" 
            fill="url(#edit)" 
            data-part="edit"
            style={{ cursor: 'pointer' }}
            />
        </g>
)

class Label extends Component {
    constructor(props) {
        super(props);
        this._is_Safari = detectBrowser.is_Safari;
        this.labeInput = createRef();
        this.textRef = createRef();
        this.state = { textSize: { width: 0, height: 0 }, isEditing: false };
    }

    componentDidMount() {
        this.updateTextSize();
    }

    updateTextSize() {
        if (this.textRef.current && this.textRef.current.getBBox) {
            const bbox = this.textRef.current.getBBox();
            this.setState({ textSize: { width: bbox.width, height: bbox.height } })
        }
    }

    toggleEdit () {
        this.setState(prevState => ({ isEditing: !prevState.isEditing }));
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { area, mode, hideMeasurement, layer } = this.props;
        const oldVertices = this.getVerticesData(area, layer, hideMeasurement);
        const newVertices = this.getVerticesData(nextProps.area, nextProps.layer, nextProps.hideMeasurement);

        return (
            area.get('name') !== nextProps.area.get('name') ||
            area.properties.get('fontSize') !== nextProps.area.properties.get('fontSize') ||
            Object.entries(diff(oldVertices, newVertices)).length ||
            area.get('vertices').size !== nextProps.area.get('vertices').size ||
            mode === MODE_2D_SCREENSHOT ||
            this.state.textSize.width !== nextState.textSize.width || 
            this.state.textSize.height !== nextState.textSize.height  
        );
    }

    getVerticesData(area, layer, hideMeasurement) {
        const verticesData = [{ selected: area.selected }];
        area.vertices.forEach((vertexID) => {
            if (layer.vertices.get(vertexID)) {
                const { x, y } = layer.vertices.get(vertexID);
                verticesData.push({ '2d': layer.vertices.get(vertexID)['2d'], x, y, hideMeasurement });
            }
        });
        return verticesData;
    }

    render() {
        const { layer, area, scene } = this.props;
        const unit = scene.unit;
        const labelShadow = { ...shadowDefault };
        const polygon = describePolygon(area, layer, unit);
        const center = polygon.length ? polylabel([polygon], 1.0) : [];
        const paddingY = 0;

        return (
            <g
                data-element-root
                data-prototype={'label'}
                data-selected={false}
                data-id={area.id}
                data-layer={layer.get('id')}
                data-name={area.get('name')}
                transform={`translate(${center[0]} ${center[1] - paddingY}) scale(1, -1)`}
                style={{cursor: 'move'}}
            >
                { area.selected ? (renderEditIcon(this.state.textSize)) : '' }
                <Shadow {...labelShadow} />
                <LabelTextGroup
                    area={area}
                    layer={layer}
                    paddingY={paddingY}
                    unit={unit}
                    textSize={this.state.textSize}
                    textRef={this.textRef}
                    selected={area.selected}
                    isEditing={this.state.isEditing}
                    onEditChange={this.toggleEdit}
                />
            </g>
        );
    }
}

const Shadow = memo(({ x, y, width, height, center, transform }) => (
    <rect
        x={x}
        y={y}
        width={width}
        height={height}
        rx="10"
        fill="#ffffff"
        transform={transform || `translate(${center[0]} ${center[1]}) scale(1, -1)`}
    />
));

Label.propTypes = {
    area: PropTypes.object.isRequired,
    layer: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
};

export default Label;
