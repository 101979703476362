// Generated by CoffeeScript 1.3.1
(function() {
  var __slice = [].slice;

  window.RotatingCalipers = (function() {
    /* PRIVATE
    */

    var _distance, _inputVertices, _quickHull;

    RotatingCalipers.name = 'RotatingCalipers';

    _inputVertices = null;

    /*
      #._distance
      @sign Number _distance(Array start, Array end, Array point)
      @param start - the start point forming the dividing line.
      @param end - the end point forming the dividing line.
      @param point - the point from which the distance to the line is calculated.
      Find the distance between a point and a line formed by a start and end points.
      All params have first index as the x and second index as y coordinate.
    
      The real distance value could be calculated as follows:
    
      Calculate the 2D Pseudo crossproduct of the line vector (start 
      to end) and the start to point vector. 
      ((y2*x1) - (x2*y1))
      The result of this is the area of the parallelogram created by the 
      two given vectors. The Area formula can be written as follows:
      A = |start->end| h
      Therefore the distance or height is the Area divided by the length 
      of the first vector. This division is not done here for performance 
      reasons. The length of the line does not change for each of the 
      comparison cycles, therefore the resulting value can be used to 
      finde the point with the maximal distance without performing the 
      division.
    
      Because the result is not returned as an absolute value its 
      algebraic sign indicates of the point is right or left of the given 
      line
    */


    _distance = function(start, end, point) {
      return (point[1] - start[1]) * (end[0] - start[0]) - (point[0] - start[0]) * (end[1] - start[1]);
    };

    /*
      #._quickHull
      @sign Array _quickHull(Array vertices, Array start, Array end)
      @param vertices - Contains the set of points to calculate the hull for.
                        Each point is an array with the form [x, y].
      @param start - The start point of the line, in the form [x, y].
      @param end - The end point of the line, in the form [x, y].
      @return set of points forming the convex hull, in clockwise order.
      Execute a QuickHull run on the given set of points, using the provided 
      line as delimiter of the search space.
    */


    _quickHull = function(vertices, start, end) {
      var d, maxDistance, maxPoint, newPoints, vertex, _i, _len;
      maxPoint = null;
      maxDistance = 0;
      newPoints = [];
      for (_i = 0, _len = vertices.length; _i < _len; _i++) {
        vertex = vertices[_i];
        if (!((d = _distance(start, end, vertex)) > 0)) {
          continue;
        }
        newPoints.push(vertex);
        if (d < maxDistance) {
          continue;
        }
        maxDistance = d;
        maxPoint = vertex;
      }
      /*
          The current delimiter line is the only one left and therefore a 
          segment of the convex hull. Only the end of the line is returned 
          to not have points multiple times in the result set.
      */

      if (!(maxPoint != null)) {
        return [end];
      }
      /*
          The new maximal point creates a triangle together with start and 
          end, Everything inside this trianlge can be ignored. Everything 
          else needs to handled recursively. Because the quickHull invocation 
          only handles points left of the line we can simply call it for the 
          different line segements to process the right kind of points.
      */

      return _quickHull(newPoints, start, maxPoint).concat(_quickHull(newPoints, maxPoint, end));
    };

    /* PUBLIC
    */


    /*
      #RotatingCalipers.constructor
      @sign void constructor(Array vertices)
      @sign void RotatingCalipers(Array vertex, Array vertex, Array vertex[, Array vertex...])
      @param vertices - An array contains vertices in form of an array. Can also take 
                        each vertex as arguments
    */


    function RotatingCalipers(verticesOrFirst) {
      var rest, vertex, vertex1, vertex2, vertex3, _i, _len;
      if (!(verticesOrFirst != null)) {
        throw new Error("Argument required");
      }
      if (!(verticesOrFirst instanceof Array) || verticesOrFirst.length < 3) {
        throw new Error("Array of vertices required");
      }
      vertex1 = verticesOrFirst[0], vertex2 = verticesOrFirst[1], vertex3 = verticesOrFirst[2], rest = 4 <= verticesOrFirst.length ? __slice.call(verticesOrFirst, 3) : [];
      for (_i = 0, _len = verticesOrFirst.length; _i < _len; _i++) {
        vertex = verticesOrFirst[_i];
        if (!(vertex instanceof Array) || vertex.length < 2) {
          throw new Error("Invalid vertex");
        }
        if (isNaN(vertex[0]) || isNaN(vertex[1])) {
          throw new Error("Invalid vertex");
        }
      }
      _inputVertices = verticesOrFirst;
    }

    /*
      RotatingCalipers.convexHull
      @sign Array convexHull(void)
      @return an Array of the points forming the minimal convex set containing all
              input vertices.
      Calculates the convex hull of the arbitrary vertices defined in constructor.
    */


    RotatingCalipers.prototype.convexHull = function() {
      var extremeX, finder;
      finder = function(arr) {
        var el, ret, _i, _len;
        ret = {};
        ret.min = ret.max = arr[0];
        for (_i = 0, _len = arr.length; _i < _len; _i++) {
          el = arr[_i];
          if (el[0] < ret.min[0]) {
            ret.min = el;
          }
          if (el[0] > ret.max[0]) {
            ret.max = el;
          }
        }
        return ret;
      };
      extremeX = finder(_inputVertices);
      return _quickHull(_inputVertices, extremeX.min, extremeX.max).concat(_quickHull(_inputVertices, extremeX.max, extremeX.min));
    };

    /*
      RotatingCalipers.angleBetweenVectors
      @sign Number angleBetweenVectors(Array vector1, Array vector2)
      @param vector1 - the first vector
      @param vector2 - the second vector
      @return the angle between them, in radian
      Calculate the angle between two vectors.
    */


    RotatingCalipers.prototype.angleBetweenVectors = function(vector1, vector2) {
      var dotProduct, magnitude1, magnitude2;
      dotProduct = vector1[0] * vector2[0] + vector1[1] * vector2[1];
      magnitude1 = Math.sqrt(vector1[0] * vector1[0] + vector1[1] * vector1[1]);
      magnitude2 = Math.sqrt(vector2[0] * vector2[0] + vector2[1] * vector2[1]);
      return Math.acos(dotProduct / (magnitude1 * magnitude2));
    };

    /*
      RotatingCalipers.rotateVector
      @sign Array rotateVector(Array vector, Number angle)
      @param vector - the vector to rotate
      @param angle - the angle to rotate to, in radian
      @return the rotated vector as an array
      Rotate a vector to an angle and return the rotated vector.
    */


    RotatingCalipers.prototype.rotateVector = function(vector, angle) {
      var rotated;
      rotated = [];
      rotated[0] = vector[0] * Math.cos(angle) - vector[1] * Math.sin(angle);
      rotated[1] = vector[0] * Math.sin(angle) + vector[1] * Math.cos(angle);
      return rotated;
    };

    /*
      RotatingCalipers.shortestDistance
      @sign Number shortestDistance(Array p, Array t, Array v)
      @param p - the point to which the shortest distance is calculated
      @param t - the point through which the vector extends
      @param v - the vector extended to t to form a line
      Calculate the shortest distance from point p to the line formed by extending
      the vector v through point t
    */


    RotatingCalipers.prototype.shortestDistance = function(p, t, v) {
      var a, c;
      if (v[0] === 0) {
        return Math.abs(p[0] - t[0]);
      }
      a = v[1] / v[0];
      c = t[1] - a * t[0];
      return Math.abs(p[1] - c - a * p[0]) / Math.sqrt(a * a + 1);
    };

    /*
      RotatingCalipers.intersection
      @sign Array intersection(Array point1, Array vector1, Array point2, Array vector2)
      @param point1 - the point through which the first vector passing
      @param vector1 - the vector passing through point1
      @param point2 - the point through which the second vector passing
      @param vector2 - the vector passing through point2
      @return the intersecting point between two vectors
      Finds the intersection of the lines formed by vector1 passing through
      point1 and vector2 passing through point2
    */


    RotatingCalipers.prototype.intersection = function(point1, vector1, point2, vector2) {
      var b1, b2, m1, m2, point;
      if (vector1[0] === 0 && vector2[0] === 0) {
        return false;
      }
      if (vector1[0] !== 0) {
        m1 = vector1[1] / vector1[0];
        b1 = point1[1] - m1 * point1[0];
      }
      if (vector2[0] !== 0) {
        m2 = vector2[1] / vector2[0];
        b2 = point2[1] - m2 * point2[0];
      }
      if (vector1[0] === 0) {
        return [point1[0], m2 * point1[0] + b2];
      }
      if (vector2[0] === 0) {
        return [point2[0], m1 * point2[0] + b1];
      }
      if (m1 === m2) {
        return false;
      }
      point = [];
      point[0] = (b2 - b1) / (m1 - m2);
      point[1] = m1 * point[0] + b1;
      return point;
    };

    /*
      RotatingCalipers.minAreaEnclosingRectangle
      @sign Object minAreaEnclosingRectangle(void)
      @return an object containing the vertices, width, height and area of the
      enclosing rectangle that has the minimum area.
      Calculate the mimimum area enclosing retangle for a convex polygon with n
      vertices given in clockwise order.
      The algorithm is based on Godfried Toussaint's 1983 whitepaper on "Solving
      geometric problems with the rotating calipers" and the Wikipedia page for 
      "Rotating Calipers". More info at http://cgm.cs.mcgill.ca/~orm/maer.html.
      Ported from Geoffrey Cox's PHP port (github.com/brainbook/BbsRotatingCalipers).
      Adapted for CoffeeScript by Son Tran.
      The general guidelines for the algorithm is as followed:
      1. Compute all four extreme points, and call them xminP, xmaxP, yminP ymaxP.
      2. Construct four lines of support for P through all four points. 
        These determine two sets of "calipers".
      3. If one (or more) lines coincide with an edge, then compute the area of the
        rectangle determined by the four lines, and keep as minimum. Otherwise, 
        consider the current minimum area to be infinite.
      4. Rotate the lines clockwise until one of them coincides with an edge.
      5. Compute area of new rectangle, and compare it to the current minimum area. 
        Update the minimum if necessary, keeping track of the rectangle determining the minimum. 
      6. Repeat steps 4 and 5, until the lines have been rotated an angle greater than 90 degrees.
      7. Output the minimum area enclosing rectangle.
    */


    RotatingCalipers.prototype.minAreaEnclosingRectangle = function() {
      var angles, area, caliper, calipers, getEdge, getItem, height, hull, i, idx, minAngle, minArea, minHeight, minPairs, minWidth, point, rotatedAngle, vertices, width, xIndices, _i, _len;
      hull = this.convexHull().reverse();
      xIndices = [0, 0, 0, 0];
      getItem = function(idxOfExtremePointInHull) {
        return hull[idxOfExtremePointInHull % hull.length];
      };
      getEdge = function(idxOfExtremePointInHull) {
        var pointA, pointB;
        pointA = getItem(idxOfExtremePointInHull + 1);
        pointB = getItem(idxOfExtremePointInHull);
        return [pointA[0] - pointB[0], pointA[1] - pointB[1]];
      };
      /*
          Compute all four extreme points for the polygon, store their indices.
      */

      for (idx = _i = 0, _len = hull.length; _i < _len; idx = ++_i) {
        point = hull[idx];
        if (point[1] < hull[xIndices[0]][1]) {
          xIndices[0] = idx;
        }
        if (point[1] > hull[xIndices[1]][1]) {
          xIndices[1] = idx;
        }
        if (point[0] < hull[xIndices[2]][0]) {
          xIndices[2] = idx;
        }
        if (point[0] > hull[xIndices[3]][0]) {
          xIndices[3] = idx;
        }
      }
      rotatedAngle = 0;
      minArea = minWidth = minHeight = null;
      calipers = [[1, 0], [-1, 0], [0, -1], [0, 1]];
      /*
          Repeat computing, until the lines have been rotated an angle greater than 90 degrees.
      */

      while (rotatedAngle < Math.PI) {
        /*
              Calculate the angle between the edge next adjacent to each extreme point
              and its caliper. The minimum of those angles indicates the angle needed
              to rotate all calipers to coincide with the nearest edge.
        */

        angles = (function() {
          var _j, _len1, _results;
          _results = [];
          for (i = _j = 0, _len1 = xIndices.length; _j < _len1; i = ++_j) {
            idx = xIndices[i];
            _results.push(this.angleBetweenVectors(getEdge(idx), calipers[i]));
          }
          return _results;
        }).call(this);
        minAngle = Math.min.apply(Math, angles);
        /*
              Then rotate all calipers to that minimum angle.
        */

        calipers = (function() {
          var _j, _len1, _results;
          _results = [];
          for (_j = 0, _len1 = calipers.length; _j < _len1; _j++) {
            caliper = calipers[_j];
            _results.push(this.rotateVector(caliper, minAngle));
          }
          return _results;
        }).call(this);
        idx = angles.indexOf(minAngle);
        /* 
        Compute the area of the new rectangle
        */

        switch (idx) {
          case 0:
          case 2:
            width = this.shortestDistance(getItem(xIndices[1]), getItem(xIndices[0]), calipers[0]);
            height = this.shortestDistance(getItem(xIndices[3]), getItem(xIndices[2]), calipers[2]);
            break;
          case 1:
            width = this.shortestDistance(getItem(xIndices[0]), getItem(xIndices[1]), calipers[1]);
            height = this.shortestDistance(getItem(xIndices[3]), getItem(xIndices[2]), calipers[2]);
            break;
          case 3:
            width = this.shortestDistance(getItem(xIndices[1]), getItem(xIndices[0]), calipers[0]);
            height = this.shortestDistance(getItem(xIndices[2]), getItem(xIndices[3]), calipers[3]);
        }
        rotatedAngle += minAngle;
        area = width * height;
        /*
              Compare the new area to the current minArea.
        */

        if (!(minArea != null) || area < minArea) {
          /*
                  Update the minArea, keeping track of the rectangle determining the minimum.
          */

          minArea = area;
          minPairs = (function() {
            var _j, _results;
            _results = [];
            for (i = _j = 0; _j < 4; i = ++_j) {
              _results.push([getItem(xIndices[i]), calipers[i]]);
            }
            return _results;
          })();
          minWidth = width;
          minHeight = height;
        }
        /*
              Update the index of the extreme point with the minimum angle to the next point
              of the polygon.
        */

        xIndices[idx]++;
      }
      vertices = [this.intersection(minPairs[0][0], minPairs[0][1], minPairs[3][0], minPairs[3][1]), this.intersection(minPairs[3][0], minPairs[3][1], minPairs[1][0], minPairs[1][1]), this.intersection(minPairs[1][0], minPairs[1][1], minPairs[2][0], minPairs[2][1]), this.intersection(minPairs[2][0], minPairs[2][1], minPairs[0][0], minPairs[0][1])];
      return {
        vertices: vertices,
        width: minWidth,
        height: minHeight,
        area: minArea
      };
    };

    return RotatingCalipers;

  })();

}).call(this);
