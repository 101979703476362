import React from 'react';

const DimensionInput = ({ name, value, placeholder, onChange, tabIndex, refInputDimension }) => (
    <div className={name}>
        <input
            type='text'
            className='shape--measure dimension'
            tabIndex={tabIndex}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            ref={refInputDimension}
        />
        <label>cm</label>
    </div>
);

export default DimensionInput;
