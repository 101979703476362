exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.new-page {\n  gap: 16px; }\n  .new-page--content {\n    gap: 16px; }\n", ""]);

// exports
exports.locals = {
	"new-page": "new-page",
	"new-page--content": "new-page--content"
};