module.exports = {
    isAndroid : /android/i.test(window.navigator.userAgent),
    is_iOS : /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream,
    isWebView : /;\swv\)/.test(window.navigator.userAgent) && !/OPR/g.test(window.navigator.userAgent),
    isIosWebView : /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(window.navigator.userAgent) && !/OPiOS/g.test(window.navigator.userAgent),
    isFBapp : /FBAV/i.test(window.navigator.userAgent),
    // isOperaMini : /OPR/g.test(window.navigator.userAgent) || /OPiOS/g.test(window.navigator.userAgent),
    isOperaMini : /Opera/g.test(window.navigator.userAgent) || /OPiOS/.test(window.navigator.userAgent) || /OPR\//.test(window.navigator.userAgent),
    isIosChrome : /(iPhone|iPad).*CriOS/.test(window.navigator.userAgent),
    isEdge: /Edge\/\d./i.test(navigator.userAgent),
    isIE: (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))),
    is_Safari : (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) || /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || safari.pushNotification),
    isFirefox: typeof InstallTrigger !== 'undefined',
    isMac: /(Mac)/i.test(navigator.platform),
    isOculusBrowser: /OculusBrowser\/\d./i.test(navigator.userAgent)
}
