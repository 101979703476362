import React, {Component} from 'react';
import PropTypes from 'prop-types';

import s from './layers-maneger.scss';

export default class LayersManagerListItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {layer, selectedLayer, onSelect, layersSize} = this.props;
    const active = selectedLayer === layer.id;

    return <div className={`${s['layers-manager-list-item']} ${ active ? 'active' : ''}`}
                onClick={e => onSelect(e, layer.id)}>
      <label>
        <i className="fa fa-bars" aria-hidden="true"></i>
        <input type="radio" checked={active} id="radioButton" onChange={e => {}}/>
        <label> {layer.name}</label>
      </label>
    </div>
  }
}

LayersManagerListItem.propTypes = {
  layer: PropTypes.object.isRequired,
  selectedLayer: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};
