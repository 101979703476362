import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FormNumberInput, FormTextInput} from 'a_component-styles/export';
import {calculateLayerCenter} from 'a_root-folder/utils/layer-operations'

import s from './layers-maneger.scss';
import c from 'a_component-styles/common.scss'
import {UNIT_COEF, LENGTH, UNIT_MILLIMETER, UNIT_INCH} from "a_root-folder/constants";
import {ModalConfirm} from "a_root-folder/components/modal/export";

const PRECISION = 0;

export default class LayersManagerEditor extends Component {
    constructor(props) {
        super(props);

        const {layer: selectedLayerId, scene: {layers}} = props;

        if (!selectedLayerId) {
            this.state = {shown: false};

            return;
        }

        let layer = layers.get(selectedLayerId);
        let {id, name, angle, altitude} = layer;

        this.state = {id, name, altitude, angle, confirm: false};
    }

    submit(e) {
        if (e) e.preventDefault();
        const {id, name, altitude} = this.state;
        this.context.messageActions.setLayerProperties({id, name, altitude});
    }

    componentWillReceiveProps(nextProps) {
        const {layer: selectedLayerId, scene: {layers}} = nextProps;
        let altitudeProps = -1;

        const {altitude: altitudeNextScene} = layers.get(selectedLayerId);
        if(this.props.scene.layers.get(selectedLayerId)){
            altitudeProps = this.props.scene.layers.get(selectedLayerId).get('altitude');
        }

        if (!selectedLayerId || (selectedLayerId === this.props.layer && altitudeNextScene === altitudeProps)) return;

        const layer = layers.get(selectedLayerId);
        const {name, altitude, angle} = layer;

        this.setState({id: selectedLayerId, name, altitude, angle});
    }

    openModalConfirm(e) {
        e.preventDefault();
        this.setState({confirm: true});
    }

    openModalDismiss() {
        this.setState({confirm: false});
    }

    layerDelete() {
        let {id} = this.state;
        this.context.sceneActions.removeLayer(id);
        this.context.messageActions.removeLayer(id);
        this.setState({confirm: false});
    }

    render() {
        const {scene: {unit}, layer: selectedLayerId, layerSize} = this.props;
        let {name, altitude, id, confirm} = this.state;
        const deleteButton = layerSize !== 1;

        if (!selectedLayerId || selectedLayerId === '') return null;

        return <div className={s['layers-manager-editor']}>
            <form action="" onSubmit={e => this.submit(e)} onReset={e => this.reset(e)}>
                <div className={c['form-group-wrapper']}>
                    <div className={`${c['form-group-row']} form-group`}>
                        <label className={c['form-group-label']}>Name</label>
                        <div className={c['form-group-field']}>
                            <FormTextInput
                                value={name}
                                onChange={e => this.setState({name: e.target.value})}
                                placeholder="Name"
                            />
                        </div>
                    </div>
                    <div className={`${c['form-group-row']} form-group`}>
                        <label className={c['form-group-label']}>Altitude</label>
                        <div className={`${c['form-group-field']} ${c['field-measure']}`}>
                            <FormNumberInput
                                value={altitude}
                                onChange={e => this.setState({altitude: e.target.value})}
                                placeholder="Altitude"
                                configs={{precision: PRECISION}}
                            />
                            <span className='units-label'>{unit}</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="btn-group btn-group-justified">
                            <div className="btn-group" tabIndex="1">
                                <button type="submit" className="btn blue btn-sm">Save</button>
                            </div>
                            <div className="btn-group" tabIndex="2">
                                {deleteButton ? <button onClick={e => {
                                    e.stopPropagation();
                                    this.openModalConfirm(e)
                                }} className="btn red btn-sm">Delete</button> : ''}
                                {confirm ?
                                    <ModalConfirm title="Are you sure you want to delete this floor?"
                                                  onConfirm={() => {
                                                      this.layerDelete()
                                                  }}
                                                  onDismiss={() => this.openModalDismiss()}/> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    }
}

LayersManagerEditor.propTypes = {
    layer: PropTypes.string.isRequired,
    scene: PropTypes.object.isRequired,
};

LayersManagerEditor.contextTypes = {
    sceneActions: PropTypes.object.isRequired,
    messageActions: PropTypes.object.isRequired,
    layerActions: PropTypes.object.isRequired,
    projectActions: PropTypes.object.isRequired,
};
