export function browserDownload(json) {
  let fileOutputLink = document.createElement('a');

  let filename = 'output' + Date.now() + '.json';
  filename = window.prompt('Insert output filename', filename);
  if (!filename) return;

  let output = JSON.stringify(json);
  let data = new Blob([output], {type: 'text/plain'});
  let url = window.URL.createObjectURL(data);
  fileOutputLink.setAttribute('download', filename);
  fileOutputLink.href = url;
  fileOutputLink.style.display = 'none';
  document.body.appendChild(fileOutputLink);
  fileOutputLink.click();
  document.body.removeChild(fileOutputLink);
}

export function browserUpload() {
  return new Promise(function (resolve, reject) {

    let fileInput = document.createElement('input');
    fileInput.type = 'file';

    fileInput.addEventListener('change', function (event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.addEventListener('load', (fileEvent) => {
        let loadedData = fileEvent.target.result;
        resolve(loadedData);
      });
      reader.readAsText(file);
    });

    fileInput.click();
  });
}

export const savePng = (svgData, filename = 'floorplan.png') => {
  const img = document.createElement('img');
  
  img.src = svgData;

  img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(function (blob) {
          const a = document.createElement('a');
          const url = URL.createObjectURL(blob);

          a.href = url;
          a.download = filename;

          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
          document.body.removeChild(a);
      }, 'image/svg+xml');
  };

  img.onerror = function () {
      console.error('Failed to load the SVG image.');
  };
}

export const saveSvg = (svgString, fileName="floorplan.svg") => {
  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  
  const link = document.createElement('a');
  link.download = fileName;
  
  link.href = URL.createObjectURL(blob);
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  
  URL.revokeObjectURL(link.href);
}