// ACTIONS project
export const GEOIP_URL = '/p/ip.pl';

// METRIC TYPE
export const AREA = 'AREA';
export const LENGTH = 'LENGTH';
export const RESOLUTION = {
    default: {width: {ideal: 160}, height: {ideal: 120}},
    ios: {width: 352, height: 288},
    safari: {width: {ideal: 320}, height: {ideal: 240}}
};

export const MEASUREMENT = "measurement";

//COUNTY CODE WITH IMPERIAL SYSTEM https://en.wikipedia.org/wiki/Metrication
export const COUNTRY_IMPERIAL_SYSTEM = ['Thailand', 'China', 'Indonesia', 'United Kingdom',
    'Malaysia', 'United States', 'North Korea', 'Sri Lanka', 'Hong Kong', 'Taiwan', 'Macau', 'Liberia', 'Myanmar'];
//UNITS
export const UNIT_MILLIMETER = 'mm';
export const UNIT_CENTIMETER = 'cm';
export const UNIT_METER = 'm';
export const UNIT_METER_SQUARE = 'm2';
export const UNIT_INCH = 'in';
export const UNIT_FOOT = 'ft';
export const UNIT_FOOT_SQUARE = 'ft2';
export const UNIT_MILE = 'mi';

export const CONVERT_METER_INCH = 'meter_inch';
export const CONVERT_INCH_METER = 'inch_meter';
export const CONVERT_FOOT_METER = 'foot_meter';

export const UNITS_LENGTH = [
    UNIT_MILLIMETER,
    UNIT_CENTIMETER,
    UNIT_METER,
    UNIT_INCH,
    UNIT_FOOT,
    UNIT_MILE
];

export const UNIT_COEF = {
    [UNIT_MILLIMETER]: 0.1,
    [UNIT_CENTIMETER]: 1,
    [UNIT_METER]: 100,
    [UNIT_INCH]: 2.54,
    [UNIT_FOOT]: 30.48,
    [UNIT_MILE]: 160934.4,
};

export const AUTOROTATE = {
    NONE: 0,
    INITIAL: true,
    ALL_ROOMS: 'always'
};

export const QUERY_PARAMS = [
    {
        name: 'initvars.autorotate.enabled',
        value: AUTOROTATE.NONE,
        alias: 'a',
    },
    {
        name: 'hidehotspotlabels',
        value: false,
        alias: 'b',
    },
    {
        name: 'hidetitle',
        value: false,
        alias: 'c',
    },
    {
        name: 'autoe3play',
        value: false,
        alias: 'd',
    },
    {
        name: 'disclaimer',
        value: false,
        alias: 'd',
    },
    {
        name: 'dimensions',
        value: true,
        alias: 'e',
    },
    {
        name: 'hidelive',
        value: true,
        alias: 'hl',
    },
    {
        name: 'autoe3plan',
        value: false,
        alias: 'f',
    },
    {
        name: 'hide_nadir',
        value: false,
        alias: 'g',
    },
    {
        name: 'horizontal_transitions',
        value: true,
        alias: 'h',
    },
    {
        name: 'share_button',
        value: true,
        alias: 'i',
    },
    {
        name: 'hide_logo',
        value: false,
        alias: 'j',
    },
    {
        name: 'hide_info',
        value: false,
        alias: 'k',
    },
    {
        name: 'fadetransition',
        value: true,
        alias: 'm',
    },
    {
        name: 'hide_background_audio',
        value: false,
        alias: 'n',
    },
    {
        name: 'intro_mouse_icon',
        value: true,
        alias: 'o',
    },
    {
        name: 'lead_gen',
        value: false,
        alias: 'p',
    },
    {
        name: 't_3d_model_dimensions',
        value: true,
        alias: 'q',
    },
    {
        name: 't_3d_model',
        value: true,
        alias: 'r',
    },
    {
        name: 'hide_e3play',
        value: false,
        alias: 's',
    },
    {
        name: 'mls_compliant',
        value: false,
        alias: 't',
    },
    {
        name: 'hotspotlabels',
        value: false,
        alias: 'u',
    },
    {
        name: 'title',
        value: false,
        alias: 'w',
    },
    {
        name: 'live',
        value: false,
        alias: 'x',
    },
    {
        name: 'e3play',
        value: false,
        alias: 'y',
    },
    {
        name: 'show_thumbnails',
        value: false,
        alias: 'z',
    },
    {
        name: 'show_google_map',
        value: false,
        alias: 'aa',
    },
    {
        name: 'autovideo',
        value: false,
        alias: 'ab',
    },
    {
        name: 'nadir_orientation',
        value: 'viewer',
        alias: 'ac',
    },
    {
        name: 'mini_floorplan',
        value: false,
        alias: 'ad',
    },
    {
        name: 'autofloorplan',
        value: false,
        alias: 'ae',
    },
    {
        name: 'measuring_tool',
        value: false,
        alias: 'af',
    },
    {
        name: 'accessibility',
        value: true,
        alias: 'ag',
    }
]
