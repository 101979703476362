import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Modal from './wrapper';

export default class ModalConfirm extends Component {
  constructor(props) {
    super(props);
  }

  confirm(e) {
    e.preventDefault();
    this.props.onConfirm();
    this.dismiss(e)
  }

  dismiss(e) {
    e && e.preventDefault();
    this.props.onDismiss();
    this.context.modalActions.hideModal();
  }

  render() {
    const {title} = this.props;
    return <Modal title={title} onClose={(e) => this.dismiss(e)}>
      <div className='modal-content-body-footer'>
        <div className="form-group">
          <div className="btn-group btn-group-justified">
            <div className="btn-group">
              <button className="btn blue" onClick={(e) => {e.stopPropagation(); this.confirm(e)}}>Confirm</button>
              <button className="btn white" onClick={(e) => {e.stopPropagation(); this.dismiss(e)}}>Dismiss</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  }
}

ModalConfirm.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onConfirm: PropTypes.func.isRequired,
};

ModalConfirm.contextTypes = {
  modalActions: PropTypes.object.isRequired
};

