/** lines features **/
import {Map, Set, List, fromJS, Seq} from 'immutable';
import IDBroker from './id-broker';
import NameGenerator from './name-generator';
import * as Geometry from './geometry';
import {isClockWiseOrder, calculateInnerCycles} from './graph-inner-cycles';

import {Scene, Layer, Catalog, Vertex, Line} from 'a_root-folder/models';
import {UNIT_COEF, UNIT_MILLIMETER, UNIT_METER} from 'a_root-folder/constants';
import polylabel from "polylabel";
import {calculateMinSizes} from "./helper";
const _ = require('lodash');
import {
  MODE_DRAGGING_LAYER,
  MODE_ROTATING_LAYER,
  MODE_IDLE,
  MODE_MANAGING_LAYERS
} from 'a_root-folder/constants';

import MessageEmitter from 'a_root-folder/utils/message-emitter';
const emitter = new MessageEmitter();

const flatten = list => list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

export function addLine(layer, type, x0, y0, x1, y1, catalog, properties = {}) {
    let line;

    layer = layer.withMutations(layer => {
        let lineID = IDBroker.acquireID();

        let v0, v1;
        ({layer, vertex: v0} = addVertex(layer, x0, y0, 'lines', lineID));
        ({layer, vertex: v1} = addVertex(layer, x1, y1, 'lines', lineID));

        line = catalog.factoryElement(type, {
            id: lineID,
            name: NameGenerator.generateName('lines', catalog.getIn(['elements', type, 'info', 'title'])),
            vertices: new List([v0.id, v1.id]),
            type
        }, properties);

        layer.setIn(['lines', lineID], line);
    });

    return {layer, line};
}

export function addUnnamedLine(layer, type, x0, y0, x1, y1, properties) {
    let line;

    layer = layer.withMutations(layer => {
        let lineID = IDBroker.acquireID();

        let v0, v1;
        ({layer, vertex: v0} = addVertex(layer, x0, y0, 'lines', lineID));
        ({layer, vertex: v1} = addVertex(layer, x1, y1, 'lines', lineID));

        line = new Line({
            id: lineID,
            name: lineID,
            vertices: new List([v0.id, v1.id]),
            type
        });

        line = line.merge({properties});

        layer.setIn(['lines', lineID], line);
    });

    return {layer, line};
}

export function replaceLineVertex(layer, lineID, vertexIndex, x, y) {
    let line = layer.getIn(['lines', lineID]);
    let vertex;

    layer = layer.withMutations(layer => layer.withMutations(layer => {
        let vertexID = line.vertices.get(vertexIndex);
        unselect(layer, 'vertices', vertexID);
        removeVertex(layer, vertexID, 'lines', line.id);
        ({layer, vertex} = addVertex(layer, x, y, 'lines', line.id));
        line = line.setIn(['vertices', vertexIndex], vertex.id);
        layer.setIn(['lines', lineID], line);
    }));
    return {layer, line, vertex};
}

export function removeLine(layer, lineID) {
    let line = layer.getIn(['lines', lineID]);

    layer = layer.withMutations(layer => {
        unselect(layer, 'lines', lineID);
        line.holes.forEach(holeID => removeHole(layer, holeID));
        layer.deleteIn(['lines', line.id]);
        line.vertices.forEach(vertexID => removeVertex(layer, vertexID, 'lines', line.id));
    });

    return {layer, line};
}

export function splitLine(layer, lineID, x, y, catalog) {
    let line0, line1;

    layer = layer.withMutations(layer => {
        let line = layer.getIn(['lines', lineID]);
        let v0 = layer.vertices.get(line.vertices.get(0));
        let v1 = layer.vertices.get(line.vertices.get(1));
        let {x: x0, y: y0} = v0;
        let {x: x1, y: y1} = v1;

        ({line: line0} = addLine(layer, line.type, x0, y0, x, y, catalog, line.properties));
        // ({line: line1} = addLine(layer, line.type, x1, y1, x, y, catalog, line.properties));
        ({line: line1} = addLine(layer, line.type, x, y, x1, y1, catalog, line.properties));

        let splitPointOffset = Geometry.pointPositionOnLineSegment(x0, y0, x1, y1, x, y);
        let minVertex = Geometry.minVertex(v0, v1);

        line.holes.forEach(holeID => {
            let hole = layer.holes.get(holeID);

            let holeOffset = hole.offset;
            if (minVertex.x === x1 && minVertex.y === y1) {
                splitPointOffset = 1 - splitPointOffset;
                holeOffset = 1 - hole.offset;
            }

            if (holeOffset < splitPointOffset) {
                let offset = holeOffset / splitPointOffset;
                if (minVertex.x === x1 && minVertex.y === y1) {
                    offset = 1 - offset;
                }
                addHole(layer, hole.type, line0.id, offset, catalog, hole.properties);
            } else {
                let offset = (holeOffset - splitPointOffset) / (1 - splitPointOffset);
                if (minVertex.x === x1 && minVertex.y === y1) {
                    offset = 1 - offset;
                }
                addHole(layer, hole.type, line1.id, offset, catalog, hole.properties);
            }
        });
        removeLine(layer, lineID);
    });

    return {layer, lines: new List([line0, line1])};
}

/**
 * Creating lines from input vertices including update holes logic
 *
 * @param layer
 * @param type
 * @param vertices
 * @param holes
 * @param properties
 * @param areas
 * @returns {{layer: *, holes: *|Immutable.Map<any, any>|Immutable.Map<string, any>, lines: Immutable.List<any>}}
 */
export function addLinesFromVertices(layer, type, vertices, holes, properties = {}, areas = List()) {
    let _holes = Map();
    vertices = vertices
        .sort(({x: x1, y: y1}, {x: x2, y: y2}) => x1 === x2 ? y1 - y2 : x1 - x2);

    let verticesPairs = vertices.zip(vertices.skip(1))
        .filterNot(([{x: x1, y: y1}, {x: x2, y: y2}]) => x1 === x2 && y1 === y2);

    let lines = (new List()).withMutations(lines => {
        layer = layer.withMutations(layer => {
            verticesPairs.forEach(([{x: x1, y: y1}, {x: x2, y: y2}]) => {

                let lineID = IDBroker.acquireID();
                let vs = [];

                ({layer, vertex: vs[0]} = addVertex(layer, x1, y1, 'lines', lineID));
                ({layer, vertex: vs[1]} = addVertex(layer, x2, y2, 'lines', lineID));

                let line = new Line({
                    id: lineID,
                    name: lineID,
                    vertices: new List([vs[0].id, vs[1].id]),
                    type
                });

                line = line.merge({properties});

                if (holes) {
                    holes.forEach(holeWithOffsetPoint => {
                        let {x: xp, y: yp} = holeWithOffsetPoint.offsetPosition;

                        if (Geometry.isPointOnLineSegment(x1, y1, x2, y2, xp, yp, 1)) {
                            let newOffset = Geometry.pointPositionOnLineSegment(x1, y1, x2, y2, xp, yp);

                            if (newOffset >= 0 && newOffset <= 1) {
                                line = line.update('holes', holes => holes.push(holeWithOffsetPoint.hole.id));
                                holeWithOffsetPoint.hole = holeWithOffsetPoint.hole.merge({
                                    offset: newOffset,
                                    line: lineID
                                });

                                _holes = _holes.set(holeWithOffsetPoint.hole.id, holeWithOffsetPoint.hole);

                                if (areas.size) {
                                    vs.forEach((v, i, _vertices) => {
                                        let areaToAdd = areas.subtract(v.areas).intersect(areas).first();

                                        if (areaToAdd) {
                                            let pairPoint = layer.vertices.get(_vertices[i + 1 >= _vertices.length ? 0 : i + 1].id);

                                            if (!pairPoint.areas.includes(areaToAdd)) {
                                                let vIndex = vertices.findIndex(_v => _v.id === v.id);
                                                pairPoint = vIndex
                                                    ? layer.vertices.get(vertices.get(vIndex - 1).id)
                                                    : layer.vertices.get(vertices.last().id);
                                                if (!pairPoint.areas.includes(areaToAdd)) {

                                                    // todo: Review this part. It has not to be case
                                                    console.error('Unreachable/unexpected case, results might be wrong');
                                                    pairPoint = vIndex + 1 >= vertices.size
                                                        ? layer.vertices.get(vertices.first().id)
                                                        : layer.vertices.get(vertices.get(vIndex + 1).id);
                                                }
                                            }

                                            layer = _updateAreaPoints(layer, v, pairPoint, areaToAdd);
                                        }
                                    });
                                }
                            }
                        }
                    });
                }

                lines.push(line);
            })
        });
    });

    layer = layer.merge({
        lines: layer.lines.merge(Map(lines.map(line => [line.id, line]))),
        holes: layer.holes.merge(_holes)
    });

    return {layer, holes: _holes, lines}
}

export function addLinesFromPoints(layer, type, points, catalog, properties, holes) {
    points = new List(points)
        .sort(({x: x1, y: y1}, {x: x2, y: y2}) => {
            return x1 === x2 ? y1 - y2 : x1 - x2;
        });

    let pointsPair = points.zip(points.skip(1))
        .filterNot(([{x: x1, y: y1}, {x: x2, y: y2}]) => {
            return x1 === x2 && y1 === y2;
        });

    let lines = (new List()).withMutations(lines => {
        layer = layer.withMutations(layer => {
            pointsPair.forEach(([{x: x1, y: y1}, {x: x2, y: y2}]) => {
                let {line} = addLine(layer, type, x1, y1, x2, y2, catalog, properties);
                if (holes) {
                    holes.forEach(holeWithOffsetPoint => {

                        let {x: xp, y: yp} = holeWithOffsetPoint.offsetPosition;

                        if (Geometry.isPointOnLineSegment(x1, y1, x2, y2, xp, yp)) {

                            let newOffset = Geometry.pointPositionOnLineSegment(x1, y1, x2, y2, xp, yp);

                            if (newOffset >= 0 && newOffset <= 1) {

                                addHole(layer, holeWithOffsetPoint.hole.type, line.id, newOffset, catalog,
                                    holeWithOffsetPoint.hole.properties);
                            }
                        }
                    });
                }

                lines.push(line);
            });
        });
    });

    return {layer, lines};
}

export function addLineAvoidingIntersections(layer, type, x0, y0, x1, y1, catalog, oldProperties, oldHoles) {

    let points = [{x: x0, y: y0}, {x: x1, y: y1}];

    layer = layer.withMutations(layer => {
        let {lines, vertices} = layer;
        lines.forEach(line => {
            let [v0, v1] = line.vertices.map(vertexID => vertices.get(vertexID)).toArray();

            let hasCommonEndpoint =
                (Geometry.samePoints(v0, points[0])
                    || Geometry.samePoints(v0, points[1])
                    || Geometry.samePoints(v1, points[0])
                    || Geometry.samePoints(v1, points[1]));


            let intersection = Geometry.intersectionFromTwoLineSegment(
                points[0], points[1], v0, v1
            );

            if (intersection.type === 'colinear') {
                if (!oldHoles) {
                    oldHoles = [];
                }

                let orderedVertices = Geometry.orderVertices(points);

                layer.lines.get(line.id).holes.forEach(holeID => {
                    let hole = layer.holes.get(holeID);
                    let oldLineLength = Geometry.pointsDistance(v0.x, v0.y, v1.x, v1.y);

                    let alpha = Math.atan2(orderedVertices[1].y - orderedVertices[0].y,
                        orderedVertices[1].x - orderedVertices[0].x);

                    let offset = hole.offset;

                    if (orderedVertices[1].x === line.vertices.get(1).x
                        && orderedVertices[1].y === line.vertices(1).y) {
                        offset = 1 - offset;
                    }

                    let xp = oldLineLength * offset * Math.cos(alpha) + v0.x;
                    let yp = oldLineLength * offset * Math.sin(alpha) + v0.y;

                    oldHoles.push({hole, offsetPosition: {x: xp, y: yp}});
                });

                removeLine(layer, line.id);
                points.push(v0, v1);
            }

            if (intersection.type === 'intersecting' && (!hasCommonEndpoint)) {
                splitLine(layer, line.id, intersection.point.x, intersection.point.y, catalog);
                points.push(intersection.point);
            }

        });
        addLinesFromPoints(layer, type, points, catalog, oldProperties, oldHoles);
    });

    return {layer};
}

/** vertices features **/
export function addVertex(layer, x, y, relatedPrototype, relatedID) {
    let vertex = layer.vertices.find(vertex => Geometry.samePoints(vertex, {x, y}));

    if (vertex) {
        vertex = vertex.update(relatedPrototype, related => related.push(relatedID));
    }
    else {
        vertex = new Vertex({
            id: IDBroker.acquireID(),
            name: 'Vertex',
            x, y,
            [relatedPrototype]: new List([relatedID])
        });
    }

    layer = layer.setIn(['vertices', vertex.id], vertex);
    return {layer, vertex};
}

export function removeVertex(layer, vertexID, relatedPrototype, relatedID) {
    let vertex = layer.vertices.get(vertexID);
    vertex = vertex.update(relatedPrototype, related => {
        let index = related.findIndex(ID => relatedID === ID);
        return related.delete(index);
    });

    layer =
        vertex.areas.size || vertex.lines.size ?
            layer.setIn(['vertices', vertex.id], vertex) :
            layer.deleteIn(['vertices', vertex.id]);

    return {layer, vertex};
}

export function mergeEqualsVertices(layer, vertexID) {

    //1. find vertices to remove
    let vertex = layer.getIn(['vertices', vertexID]);

    let doubleVertices = layer.vertices
    // .filter(v => v.id !== vertexID && Geometry.samePoints(vertex, v));
        .filter(v => v.id !== vertexID && Geometry.samePoints(vertex, v));

    if (doubleVertices.isEmpty()) return layer;

    //2. remove double vertices
    let vertices, lines, areas;
    vertices = layer.vertices.withMutations(vertices => {
        lines = layer.lines.withMutations(lines => {
            areas = layer.areas.withMutations(areas => {

                doubleVertices.forEach(doubleVertex => {

                    doubleVertex.lines.forEach(lineID => {
                        let line = lines.get(lineID);
                        line = line.update('vertices', vertices => vertices.map(v => v === doubleVertex.id ? vertexID : v));
                        lines.set(lineID, line);
                        vertices.updateIn([vertexID, 'lines'], l => l.push(lineID));
                    });

                    doubleVertex.areas.forEach(areaID => {
                        let area = areas.get(areaID);
                        area = area.update('vertices', vertices => vertices.map(v => v === doubleVertex.id ? vertexID : v));
                        areas.set(areaID, area);
                        vertices.updateIn([vertexID, 'areas'], area => area.push(areaID));
                    });

                    vertices.remove(doubleVertex.id);

                });
            });
        });
    });

    //3. update layer
    return layer.merge({
        vertices, lines, areas
    });
}

export function select(layer, prototype, ID) {
    return layer.withMutations(layer => {
        layer.setIn([prototype, ID, 'selected'], true);
        layer.updateIn(['selected', prototype], elements => elements.push(ID));
    });
}

export function unselect(layer, prototype, ID) {
    return layer.withMutations(layer => {
        let ids = layer.getIn(['selected', prototype]);
        ids = ids.remove(ids.indexOf(ID));
        let selected = ids.some(key => key === ID);
        layer.setIn(['selected', prototype], ids);
        if(layer.hasIn([prototype, ID, 'selected'])){
            layer.setIn([prototype, ID, 'selected'], selected);
        }
    });
}

function opSetProperties(layer, prototype, ID, properties) {
    properties = fromJS(properties);
    layer.mergeIn([prototype, ID, 'properties'], properties);
}

function opUpdateProperties(layer, prototype, ID, properties) {
    fromJS(properties).forEach((v, k) => {
        if (layer.hasIn([prototype, ID, 'properties', k]))
            layer.mergeIn([prototype, ID, 'properties', k], v);
    });
}

function opSetItemsAttributes(layer, prototype, ID, itemsAttributes) {
    itemsAttributes = fromJS(itemsAttributes);
    layer.mergeIn([prototype, ID], itemsAttributes);
}

function opSetLinesAttributes(layer, prototype, ID, linesAttributes, catalog) {

    let lAttr = linesAttributes.toJS();
    let {vertexOne, vertexTwo, lineLength} = lAttr;

    delete lAttr['vertexOne'];
    delete lAttr['vertexTwo'];
    delete lAttr['lineLength'];

    layer = layer
        .mergeIn([prototype, ID], fromJS(lAttr))  //all the others attributes
        .mergeIn(['vertices', vertexOne.id], {x: vertexOne.x, y: vertexOne.y})
        .mergeIn(['vertices', vertexTwo.id], {x: vertexTwo.x, y: vertexTwo.y})
        .mergeDeepIn([prototype, ID, 'misc'], new Map({'_unitLength': lineLength._unit}));

    layer = mergeEqualsVertices(layer, vertexOne.id);
    //check if second vertex has different coordinates than the first
    if (vertexOne.x != vertexTwo.x && vertexOne.y != vertexTwo.y) layer = mergeEqualsVertices(layer, vertexTwo.id);

    detectAndUpdateAreas(layer, catalog);
}

function opSetHolesAttributes(layer, prototype, ID, holesAttributes) {

    let hAttr = holesAttributes.toJS();
    let {offsetA, offsetB, offset} = hAttr;

    delete hAttr['offsetA'];
    delete hAttr['offsetB'];
    delete hAttr['offset'];

    let misc = new Map({_unitA: offsetA._unit, _unitB: offsetB._unit});

    layer
        .mergeIn([prototype, ID], fromJS(hAttr))  //all the others attributes
        .mergeDeepIn([prototype, ID], new Map({offset, misc}));
}


export function setPropertiesOnSelected(layer, properties) {
    return layer.withMutations(layer => {
        let selected = layer.selected;
        selected.lines.forEach(lineID => opSetProperties(layer, 'lines', lineID, properties));
        selected.holes.forEach(holeID => opSetProperties(layer, 'holes', holeID, properties));
        selected.areas.forEach(areaID => opSetProperties(layer, 'areas', areaID, properties));
        selected.items.forEach(itemID => opSetProperties(layer, 'items', itemID, properties));
    });
}

export function updatePropertiesOnSelected(layer, properties) {
    return layer.withMutations(layer => {
        let selected = layer.selected;
        selected.lines.forEach(lineID => opUpdateProperties(layer, 'lines', lineID, properties));
        selected.holes.forEach(holeID => opUpdateProperties(layer, 'holes', holeID, properties));
        selected.areas.forEach(areaID => opUpdateProperties(layer, 'areas', areaID, properties));
        selected.items.forEach(itemID => opUpdateProperties(layer, 'items', itemID, properties));
    });
}

export function setAttributesOnSelected(layer, attributes, catalog) {
    return layer.withMutations(layer => {
        let selected = layer.selected;
        selected.lines.forEach(lineID => opSetLinesAttributes(layer, 'lines', lineID, attributes, catalog));
        selected.holes.forEach(holeID => opSetHolesAttributes(layer, 'holes', holeID, attributes, catalog));
        selected.items.forEach(itemID => opSetItemsAttributes(layer, 'items', itemID, attributes, catalog));
        //selected.areas.forEach(areaID => opSetItemsAttributes(layer, 'areas', areaID, attributes, catalog));
    });
}

export function unselectAll(layer) {
    return layer.withMutations(layer => {
        layer.selected.forEach((ids, prototype) => {
            ids.forEach(id => unselect(layer, prototype, id));
        });
    });
}

/** areas features **/
export function addArea(layer, type, verticesCoords, catalog, properties) {
    let area;

    layer = layer.withMutations(layer => {
        let areaID = IDBroker.acquireID();

        let vertices = verticesCoords.map((v) => addVertex(layer, v.x, v.y, 'areas', areaID).vertex.id);

        area = catalog.factoryElement(type, {
            id: areaID,
            name: NameGenerator.generateName('areas', catalog.getIn(['elements', type, 'info', 'title'])),
            type,
            prototype: 'areas',
            vertices
        }, properties);

        layer.setIn(['areas', areaID], area);
    });

    return {layer, area};
}

export function removeArea(layer, areaID) {
    let area = layer.getIn(['areas', areaID]);

    layer = layer.withMutations(layer => {
        unselect(layer, 'areas', areaID);
        layer.deleteIn(['areas', area.id]);
        area.vertices.forEach(vertexID => removeVertex(layer, vertexID, 'areas', area.id));
    });

    return {layer, area};
}

const sameSet = (set1, set2) => set1.size === set2.size && set1.isSuperset(set2) && set1.isSubset(set2);

//https://github.com/MartyWallace/PolyK
function ContainsPoint(polygon, pointX, pointY) {
    let n = polygon.length >> 1;

    let ax, lup;
    let ay = polygon[2 * n - 3] - pointY;
    let bx = polygon[2 * n - 2] - pointX;
    let by = polygon[2 * n - 1] - pointY;

    if (bx === 0 && by === 0) return false; // point on edge

    // let lup = by > ay;
    for (let ii = 0; ii < n; ii++) {
        ax = bx;
        ay = by;
        bx = polygon[2 * ii] - pointX;
        by = polygon[2 * ii + 1] - pointY;
        if (bx === 0 && by === 0) return false; // point on edge
        if (ay === by) continue;
        lup = by > ay;
    }

    let depth = 0;
    for (let i = 0; i < n; i++) {
        ax = bx;
        ay = by;
        bx = polygon[2 * i] - pointX;
        by = polygon[2 * i + 1] - pointY;
        if (ay < 0 && by < 0) continue;  // both 'up' or both 'down'
        if (ay > 0 && by > 0) continue;  // both 'up' or both 'down'
        if (ax < 0 && bx < 0) continue;   // both points on the left

        if (ay === by && Math.min(ax, bx) < 0) return true;
        if (ay === by) continue;

        let lx = ax + (bx - ax) * (-ay) / (by - ay);
        if (lx === 0) return false;      // point on edge
        if (lx > 0) depth++;
        if (ay === 0 && lup && by > ay) depth--;  // hit vertex, both up
        if (ay === 0 && !lup && by < ay) depth--; // hit vertex, both down
        lup = by > ay;
    }
    return (depth & 1) === 1;
}

export function detectAndUpdateAreas(layer, catalog) {

    let verticesArray = [];           //array with vertices coords
    let linesArray;                   //array with edges
    let areasToUpdate = {};           //store for props of updating areas

    let vertexID_to_verticesArrayIndex = {};
    let verticesArrayIndex_to_vertexID = {};

    layer.vertices.forEach(vertex => {
        let verticesCount = verticesArray.push([vertex.x, vertex.y]);
        let latestVertexIndex = verticesCount - 1;
        vertexID_to_verticesArrayIndex[vertex.id] = latestVertexIndex;
        verticesArrayIndex_to_vertexID[latestVertexIndex] = vertex.id;
    });

    linesArray = layer.lines.map(line => line.vertices.map(vertexID => vertexID_to_verticesArrayIndex[vertexID]).toArray());

    let innerCyclesByVerticesArrayIndex = calculateInnerCycles(verticesArray, linesArray);

    let innerCyclesByVerticesID = innerCyclesByVerticesArrayIndex
        .map(cycle => cycle.map(vertexIndex => verticesArrayIndex_to_vertexID[vertexIndex]));

    // All area vertices should be ordered in counterclockwise order
    innerCyclesByVerticesID = innerCyclesByVerticesID.map((area) =>
        isClockWiseOrder(area.map(vertexID => layer.vertices.get(vertexID))) ? area.reverse() : area
    );

    let areaIDs = [];

    layer = layer.withMutations(layer => {
        //remove areas
        layer.areas.forEach(area => {
            let areaInUse = innerCyclesByVerticesID.some((vertices, key) => {
                let list = new List(vertices);
                if ((area.vertices.isSubset(list) || area.vertices.isSuperset(list))
                    && !(area.vertices.isSuperset(list) && area.vertices.isSubset(list))) areasToUpdate[key] = area.properties;
                return sameSet(list, area.vertices);
            });
            if (!areaInUse) removeArea(layer, area.id);
        });

        //add new areas
        innerCyclesByVerticesID.forEach((cycle, ind) => {
            let areaInUse = layer.areas.find(area => {
                let list = new List(cycle);
                return sameSet(area.vertices, list)
            });

            if (areaInUse) {
                areaIDs[ind] = areaInUse.id;
            } else {
                let areaVerticesCoords = cycle.map(vertexId => layer.vertices.get(vertexId));
                let {area} = addArea(layer, 'area', areaVerticesCoords, catalog, areasToUpdate[ind]);
                areaIDs[ind] = area.id;
            }
        });
    });

    layer = layer.set('center', calculateLayerCenter(layer.vertices));

    return {layer};
}

/** holes features **/
export function addHole(layer, type, lineID, offset, catalog, properties = {}) {
    let hole;

    layer = layer.withMutations(layer => {
        let holeID = IDBroker.acquireID();

        hole = catalog.factoryElement(type, {
            id: holeID,
            name: NameGenerator.generateName('holes', catalog.getIn(['elements', type, 'info', 'title'])),
            type,
            offset,
            line: lineID
        }, properties);

        layer.setIn(['holes', holeID], hole);
        layer.updateIn(['lines', lineID, 'holes'], holes => holes.push(holeID));
    });

    return {layer, hole};
}

export function removeHole(layer, holeID, removeFromPlan = false) {
    let hole = layer.getIn(['holes', holeID]);
    layer = layer.withMutations(layer => {
        unselect(layer, 'holes', holeID);
        layer.deleteIn(['holes', hole.id]);
        if(removeFromPlan) emitter.send({event:'e3.planner.remove.hole', holeID});
        layer.updateIn(['lines', hole.line, 'holes'], holes => {
            let index = holes.findIndex(ID => holeID === ID);
            return holes.remove(index);
        });
    });

    return {layer, hole};
}

/** items features **/
export function addItem(layer, type, x, y, width, height, rotation, catalog) {
    let item;

    layer = layer.withMutations(layer => {
        let itemID = IDBroker.acquireID();

        item = catalog.factoryElement(type, {
            id: itemID,
            name: NameGenerator.generateName('items', catalog.getIn(['elements', type, 'info', 'title'])),
            type,
            height,
            width,
            x,
            y,
            rotation
        });

        layer.setIn(['items', itemID], item);
    });

    return {layer, item};
}

export function removeItem(layer, itemID) {
    let item = layer.getIn(['items', itemID]);
    layer = layer.withMutations(layer => {
        unselect(layer, 'items', itemID);
        layer.deleteIn(['items', item.id]);
    });

    return {layer, item};
}

export function getGeneratedLayerId(id) {
    return `floor-${id}`;
}

export function expandCatalogValues(catalog, scene) {
    scene.layers.forEach((layer) => {
        layer.areas.forEach((area) => {
            let scene = area.properties.get('scene');
            if (scene) {
                let {camera, id, name, rotateDirection, viewPitchOffset, viewYawOffset, height_lens, ceiling} = scene.toJS();
                catalog.updateElementProperties('area', 'scene', {
                    [id]: {
                        id,
                        name,
                        camera,
                        rotateDirection,
                        viewPitchOffset,
                        viewYawOffset,
                        height_lens,
                        ceiling,
                    }
                });
                catalog.updateElementProperties('door', 'connect', {[id]: name});
            }
        })
    });

    return {catalog};
}

export function createSceneWithDefaultValues(config, catalog, clean, metrical) {
    let scene = new Scene(config);
    let checkValues = ['areas', 'lines', 'holes', 'items'];

    let layers = scene.layers.map((layer) => {
        layer = layer.withMutations((layer) => {
            checkValues.forEach((value) => {
                layer[value] = layer[value].map((valueIn) => {
                    let type = valueIn.get('type');
                    let catalogElem = catalog.elements[type];

                    if (!catalogElem) return;

                    for (let key in catalogElem.properties) {
                        if (catalogElem.properties.hasOwnProperty(key) && !valueIn.properties.get(key) && key !== 'visible') {
                            valueIn = valueIn.setIn(['properties', key], catalogElem.properties[key].defaultValue);
                        }
                    }

                    return valueIn;
                })
            });

            layer.areas = layer.areas.map((area) => area.vertices.size > 1 ? area.set('vertices', area.vertices.reverse()) : area.set('vertices', new List()));
            // layer.areas = layer.areas.map((area) => area.vertices.size > 1 ? area : area.set('vertices', new List()));
            layer.center = calculateLayerCenter(layer.vertices);
            layer.items.forEach((item) => {
                let itemID = item.get('id');
               unselect(layer, 'items', itemID);
             })
            if (clean) ({layer} = _cleanUpHoles(layer));

        });

        return layer;
    });
    scene = scene.set('metrical', metrical);
    if (!scene.width) scene = scene.set('width', 1);
    if (!scene.height) scene = scene.set('height', 1);

    return scene.set('layers', layers);
}

export function updateWallColor(wall, layer, catalog = {}) {
    let {areas} = layer;
    let vertex0 = layer.vertices.get(wall.vertices.get(0));
    let vertex1 = layer.vertices.get(wall.vertices.get(1));

    if (vertex0.x > vertex1.x) {
        let temp = vertex0;
        vertex0 = vertex1;
        vertex1 = temp;
    }

    let defaultFace = catalog.toJS
        ? catalog.elements.getIn(['wall', 'properties', 'faceA', 'defaultValue']).toJS()
        : new Map(catalog.elements.wall.properties.faceA.defaultValue);

    let middlePoint = {x: (vertex1.x + vertex0.x) / 2, y: (vertex1.y + vertex0.y) / 2}, pointA, pointB;
    if (vertex1.x === vertex0.x) {
        let {x, y} = middlePoint;
        pointA = {x: x - 5, y};
        pointB = {x: x + 5, y};
    } else {
        let {x, y} = middlePoint;
        pointA = {x, y: y + 5};
        pointB = {x, y: y - 5};
    }

    // Compute the distance between the two vertices
    let xDiff = vertex0.x - vertex1.x;
    let yDiff = vertex0.y - vertex1.y;
    let distance = Math.sqrt((xDiff * xDiff) + (yDiff * yDiff));

    let alpha = Math.asin((vertex1.y - vertex0.y) / distance);
    wall = wall.mergeDeep({
        properties: {
            faceA: defaultFace,
            faceB: defaultFace
        }
    });

    areas.forEach((area) => {
        let polygon = area.vertices.map((id) => layer.vertices.get(id)).toJS();

        if (Geometry.isVertexInsidePolygon(pointA, polygon))
            wall = wall.setIn(['properties', alpha > 0 ? 'faceB' : 'faceA'], new Map({
                opacity: 1,
                color: area.properties.get('wallColor')
            }));
        else if (Geometry.isVertexInsidePolygon(pointB, polygon))
            wall = wall.setIn(['properties', alpha > 0 ? 'faceA' : 'faceB'], new Map({
                opacity: 1,
                color: area.properties.get('wallColor')
            }));
    });

    return wall;
}

export function checkExternalWall(vertex0, vertex1, layer, sceneUnit) {
    let {areas} = layer;

    if (vertex0.x > vertex1.x) {
        let temp = vertex0;
        vertex0 = vertex1;
        vertex1 = temp;
    }
    let {x: x1, y: y1} = getVertexRenderCoords(vertex0, sceneUnit);
    let {x: x2, y: y2} = getVertexRenderCoords(vertex1, sceneUnit);

    let start = checkMiddleParallelLine({x: x2, y: y2}, {x: x1, y: y1}, 45);
    let end = checkMiddleParallelLine({x: x1, y: y1}, {x: x2, y: y2}, 45);
    let pointA = {x: (start.x + end.x) / 2, y: (start.y + end.y) / 2};

    start = checkMiddleParallelLine({x: x2, y: y2}, {x: x1, y: y1}, -45);
    end = checkMiddleParallelLine({x: x1, y: y1}, {x: x2, y: y2}, -45);
    let pointB = {x: (start.x + end.x) / 2, y: (start.y + end.y) / 2};

    // Compute the distance between the two vertices

    let distance = Math.sqrt(Math.pow(vertex0.x - vertex1.x, 2) + Math.pow(vertex0.y - vertex1.y, 2));

    let externalA = true;
    let externalB = true;
    areas.forEach((area) => {
        let polygon = area.vertices.map((id) => getVertexRenderCoords({
            x: layer.vertices.get(id).x,
            y: layer.vertices.get(id).y
        }, sceneUnit)).toJS();
        if (Geometry.isVertexInsidePolygon(pointA, polygon)) {
            externalA = false;
        }
        if (Geometry.isVertexInsidePolygon(pointB, polygon)) {
            externalB = false;
        }
    });

    return (externalA || externalB) && distance > 400 ? true : false;
}

function checkMiddleParallelLine(b, a, d) {
    let x2x1 = a.x - b.x;
    let y2y1 = a.y - b.y;
    let ab = Math.sqrt((x2x1 * x2x1 + y2y1 * y2y1));
    let v1x = (b.x - a.x) / ab;
    let v1y = (b.y - a.y) / ab;

    let v3x = (v1y > 0 ? -v1y : v1y) * d;
    let v3y = (v1x > 0 ? v1x : -v1x) * d;

    let xc_ = a.x + v3x;
    let yc_ = a.y + v3y;
    return {x: xc_, y: yc_}
}

export function generatePointArea(lines, vertices, unit = UNIT_MILLIMETER) {
    const points = [];

    lines.map((line) => {
        let start = getVertexRenderCoords(vertices.filter(ver => ver.id == line.vertices[0])[0], unit);
        let end = getVertexRenderCoords(vertices.filter(ver => ver.id == line.vertices[1])[0], unit);
        points.push({x: start.x, y: start.y})
        points.push({x: end.x, y: end.y})
    })

    return points;
}

export function getVertexRenderCoords(vertex, unit = UNIT_MILLIMETER) {
    let {x, y} = vertex;
    let coef = UNIT_COEF[unit];

    return {x: x * coef, y: y * coef};
}

export function convertPointsDimension(points, units, padding = 0, reflect = null) {
    if (reflect && reflect.x)
        return points.map((v) => {
            return {x: reflect.x - (Math.round(v[0] * units) + padding / 2), y: Math.round(v[1] * units) + padding / 2}
        });
    else
        return points.map((v) => {
            return {x: Math.round(v[0] * units) + padding / 2, y: Math.round(v[1] * units) + padding / 2}
        });
}

export function reflectVertices(vertices, reflect) {
    return vertices.size > 1 ? vertices.map((v) => v.merge({x: reflect.x - Math.round(v.x)})) : Map({});
}

export function getLayerRotationHelpers(vertices, coef = 1, angle = 0, center = [0, 0]) {
    let r = [-Infinity, -Infinity, Infinity, Infinity]; // top -> right -> bottom -> left
    let polyline, rotate, padding = 20;

    vertices.forEach((v) => {
        r[0] = v.y > r[0] ? v.y : r[0];
        r[1] = v.x > r[1] ? v.x : r[1];
        r[2] = v.y < r[2] ? v.y : r[2];
        r[3] = v.x < r[3] ? v.x : r[3];
    });

    r = r.map((v, i) => i < 2 ? v * coef + padding : v * coef - padding);

    let polylineCoords = r.map((v, i, arr) => i % 2 ? [v, arr[i - 1 >= 0 ? i - 1 : arr.length - 1]] : [arr[i - 1 >= 0 ? i - 1 : arr.length - 1], v])
        .map((p) => Geometry.rotatePointOnAngle(p, center, angle, coef));
    polyline = polylineCoords.join(' ') + ` ${polylineCoords[0]}`;

    rotate = Geometry.rotatePointOnAngle([(r[1] + r[3]) / 2, r[0]], center, angle, coef);

    return {polyline, rotate}
}

// rectangle based
export function calculateLayerCenter(vertices) {
    if (vertices.size < 3) return [0, 0];
    let r = [-Infinity, -Infinity, Infinity, Infinity];

    vertices.forEach((v) => {
        r[0] = v.y > r[0] ? v.y : r[0];
        r[1] = v.x > r[1] ? v.x : r[1];
        r[2] = v.y < r[2] ? v.y : r[2];
        r[3] = v.x < r[3] ? v.x : r[3];
    });

    let polygon = r.map((v, i, arr) => {
        return i % 2 ? [v, arr[i + 1] || arr[0]] : [arr[i + 1] || arr[0], v];
    });

    return polylabel([polygon], 1.0);
}

/**
 * Calculate vertex coordinate in case of rotation related to rotatioCenter point
 * @param vertex {Object} - Immutable.Map vertex
 * @param center {Array} - rotation point
 * @param angle {number} - rotation angle
 * @returns {Object} - Immutable.Map vertex
 */
export function rotateVertex(vertex, center, angle) {
    let x = vertex.get('x'), y = vertex.get('y');
    let dX = x - center[0];
    let dY = y - center[1];
    let r = Math.sqrt(dX * dX + dY * dY);
    let selfAngle = Math.atan2(dY, dX) * 180 / Math.PI;

    return vertex.merge({
        x: r * Math.cos((selfAngle + angle) * Math.PI / 180) + center[0],
        y: r * Math.sin((selfAngle + angle) * Math.PI / 180) + center[1]
    });
}

export function moveVertex(vertex, shift) {
    let {x, y} = vertex;
    return vertex.merge({
        x: x + shift[0],
        y: y + shift[1]
    })
}

export function checkSizeCanvas(state, projectActions) {
    let layers = _.cloneDeep(state.scene.layers);
    let {width: dataWidth, height: dataHeight, selectedLayer} = state.scene;
    dataWidth = parseInt(dataWidth);
    dataHeight = parseInt(dataHeight);

    const layersActive = layers.filter((layer) => selectedLayer == layer.id);
    const offsets = calculateMinSizes(layersActive, dataWidth, dataHeight);
    let {x, y, offsetPlanY, offsetPlanX, allow} = offsets;
    let { mode } = state;

    if (allow.includes(true)) {
      if (allow[2] || allow[3]) {
        layers = layers.map((layer) => {
          if (selectedLayer == layer.id) return moveLayer(layer, [x, y], false);
          return layer;
        });
      }

      projectActions.setProjectProperties({
        width: dataWidth + x + offsetPlanX,
        height: dataHeight + y + offsetPlanY,
        layers
      }, mode);
    }
    return state;
}

export function moveLayer(layer, shift) {
    let items = layer.items.map((v) => moveVertex(v, shift));
    let vertices = layer.vertices.map((v) => moveVertex(v, shift));
    layer = layer.set('center', calculateLayerCenter(vertices));
    let obj = {vertices};
    let obj1 = {items};
    let areas = layer.areas.map((area) => {
        return area;
    });
    obj.areas = areas
    // to keep data flows as an array instead of Immutable.List
    layer = layer.merge(obj);
    return layer.merge(obj1);

}

export function rotateLayer(layer, angle, startAngle, center) {
    let {areas} = layer;

    // areas = areas.map((area) => {
    //     let camera = area.properties.getIn(['scene', 'camera']);
    //     return area.setIn(['properties', 'scene', 'camera'], rotateVertex(camera, center, angle - startAngle));
    // });

    return layer.merge({areas, angle});
}

export function calculateHoleOffsetPoint(hole, layer) {
    let {line: lineId} = hole;
    let {vertices: verticesId, inverted} = layer.lines.get(lineId);
    let v = verticesId.map((id) => layer.vertices.get(id)).toJS();

    v = inverted ? Geometry.orderVertices(v) : v;

    let length = Geometry.pointsDistance(v[0].x, v[0].y, v[1].x, v[1].y);
    let alpha = Math.atan2(v[1].y - v[0].y,
        v[1].x - v[0].x);
    let offset = hole.offset;
    let xp = length * offset * Math.cos(alpha) + v[0].x;
    let yp = length * offset * Math.sin(alpha) + v[0].y;

    return {hole, offsetPosition: {x: xp, y: yp}};
}

export function getSelectedElement(scene) {
    let activeLayer = scene.layers.get(scene.selectedLayer);

    if (!activeLayer) return null;

    let selected = Seq()
        .concat(activeLayer.lines, activeLayer.holes, activeLayer.areas, activeLayer.items)
        .find((el) => el.selected);

    return selected ? selected : null;
}

// export function recreateHoles(scene) {
//   scene.layers.forEach(layer => {
//     let areas = layer.areas.map(area => area.vertices.map(v => layer.vertices.get(v).toJS()));
//     let _layer = layer;
//
//     layer.holes.forEach(hole => {
//       let line = layer.lines.get(hole.line);
//       if (!line) return true;
//       let vertices = line.vertices.map(v => layer.vertices.get(v));
//       let vCoords = vertices.map(v => [v.x, v.y]);
//       let coordsToCheck = Geometry.getPerpendicularCoords(...vCoords.get(0), ...vCoords.get(1)).map(arr => ({x: arr[0], y:arr[1]}));
//
//       let areasToChange = [];
//
//       areas.forEach((area, key) => {
//         coordsToCheck.some(point => {
//           if (Geometry.isVertexInsidePolygon(point, area.toJS())){
//             areasToChange.push(key);
//             return true;
//           }
//         })
//       });
//
//       areasToChange = areasToChange.forEach(a => {
//         let area = layer.areas.get(a);
//         let indexes = List(vertices.map(v => [area.vertices.indexOf(v.id), v.id]));
//         indexes = indexes.get(0)[0] > indexes.get(1)[0] ? [indexes.get(1), indexes.get(0)] : [indexes.get(0), indexes.get(1)];
//         // let splice = [0,0];
//         let shiftL = 1;
//         let shiftR = 1;
//         let end = false;
//         let linesVertices ;
//         let counter = 0;
//
//         while(!end) {
//           let l = layer.vertices.get(indexes[0][1]);
//           let ln = indexes[0][0] ? layer.vertices.get(area.vertices.get(indexes[0][0] - 1)) : layer.vertices.get(area.vertices.last());
//           let h = layer.vertices.get(indexes[1][1]);
//           let hn = indexes[1][0] + 1 === area.vertices.size ? layer.vertices.get(area.vertices.first()) : layer.vertices.get(area.vertices.get(indexes[1][0] + 1));
//           if (shiftL && Geometry.isPointOnLineSegment(ln.x,ln.y,h.x,h.y,l.x,l.y)) {
//             let _i = indexes[0][0] ? indexes[0][0] - 1 : area.vertices.size - 1;
//             indexes[0][0] = _i;
//             indexes[0][1] = ln.id;
//             // splice[0] = _i + 1 === area.vertices.size ? 0 : _i + 1;
//             // splice[1] += 1;
//           } else {shiftL = 0}
//
//           if (shiftR && Geometry.isPointOnLineSegment(l.x,l.y,hn.x,hn.y,h.x,h.y)) {
//             indexes[1][0] = indexes[1][0] + 1 === area.vertices.size ? 0 : indexes[1][0] + 1;
//             indexes[1][1] = hn.id;
//             // splice[1] += 1;
//           } else {shiftR = 0}
//
//           if (!(shiftR || shiftL)) end = true;
//
//           counter++;
//           // debug stuff
//           if (counter > 100) { console.error('Infinity loop!'); break; }
//         }
//
//         // means that hole should lies on line without changes
//         if (counter === 1) {
//           let _hole = layer.holes.get(hole.id);
//           let _line = layer.lines.get(_hole.line);
//           let index = _line.vertices.indexOf(hole.id);
//           let id = IDBroker.acquireID();
//           _hole = _hole.set('id', id);
//           _line = _line.setIn(['holes',index], id);
//
//           layer = layer.merge({
//             lines: layer.lines.set(_line.id, _line),
//             holes: layer.holes.set(_hole.id, _hole)
//           });
//
//           return;
//         }
//
//         if(indexes[0][0] > indexes[1][0]) {
//           let start = indexes[0][0] + 1 === area.vertices.size ? 0 : indexes[0][0] + 1;
//           linesVertices  = start ? [...area.vertices.slice(start - area.vertices.size), ...area.vertices.slice(0, indexes[1][0])] : area.vertices.slice(start, indexes[1][0]);
//           area = area.set('vertices', start ? area.vertices.splice(start - area.vertices.size).splice(0, indexes[1][0]) : area.vertices.splice(start, indexes[1][0]))
//         } else {
//           linesVertices  = area.vertices.slice(indexes[0][0] + 1, indexes[1][0]);
//           area = area.set('vertices', area.vertices.splice(indexes[0][0] + 1, indexes[1][0] - indexes[0][0] - 1))
//         }
//         linesVertices = linesVertices.map(v => {
//           let vertex = layer.vertices.get(v);
//           let index = vertex.areas.indexOf(area.id);
//           if (index !== -1) {
//              layer = layer.setIn(['vertices',v], vertex.set('areas', vertex.areas.splice(index, 1)));
//           }
//
//           return v;
//         });
//         linesVertices.unshift(indexes[0][1]);
//         linesVertices.push(indexes[1][1]);
//         // linesVertices  = linesVertices.unshift(indexes[0][1]).push(indexes[1][1]);
//         let holesToPush = Set();
//         let linesToRemove = [];
//
//         linesVertices.reduce((v1, v2) => {
//           let line = layer.lines.find(l => l.vertices.every(_v => [v1,v2].includes(_v)));
//
//           if (!line) return v2;
//           let {holes} = line;
//           if (holes.size === 0 || holes.get(0) === hole.id) {
//             linesToRemove.push(line.id)
//           } else {
//             holesToPush = holesToPush.union(holes);
//           }
//
//           return v2;
//         });
//
//         let {x: x0, y: y0} = layer.vertices.get(indexes[0][1]);
//         let {x: x1, y: y1} = layer.vertices.get(indexes[1][1]);
//         let _holes;
//
//         holesToPush = holesToPush.union([hole.id]).map(h => {
//           let _hole = layer.holes.get(h);
//           return _hole.set('id', IDBroker.acquireID())
//         }).map(h => calculateHoleOffsetPoint(h, _layer));
//
//         layer = _removeLines(layer, linesToRemove);
//         ({layer, holes: _holes} = addLinesFromVertices(layer, line.type, List([{x:x0,y:y0},{x:x1,y:y1}]), holesToPush));
//
//         layer = layer.set('holes', layer.holes.merge(_holes));
//         layer = layer.setIn(['areas',area.id], area);
//       });
//
//       layer = layer.set('holes', layer.holes.remove(hole.id));
//     });
//
//     scene = scene.setIn(['layers',layer.id], layer);
//   });
//   return {scene};
// }

/**
 *   ====================
 *   Non-export functions
 *   ====================
 **/

// function _cleanUpHoles(layer) {
//   let {holes} = layer;
//   let track = Map();
//   let holesPairs = Map();
//
//   holes.forEach(h => {
//     let holePair = [...holesPairs.keys()].find(k => k.every(_k => h.properties.get('connect').includes(_k)));
//
//     if (holePair) holesPairs = holesPairs.set(holePair, holesPairs.get(holePair).push(h.id));
//     else holesPairs = holesPairs.set(h.properties.get('connect').toArray(), List([h.id]));
//   });
//
//   if (!holesPairs.filter(hp => hp.size > 1).size) return {layer};
//
//   let _holesPairs = holesPairs.filter(hp => hp.size > 1);
//   _holesPairs.forEach((hp, key) => {
//       let lineType, lineProps,
//         vertices = Map(),
//         selectedHole = layer.holes.get(hp.get(0)),
//         lines = List(),
//         holes = Map({[selectedHole.id]: selectedHole}),
//         areas = List(),
//         holesWithOffset;
//
//       hp.forEach(h => {
//         let hole = layer.holes.get(h);
//         let _area = Set();
//         layer.lines.get(hole.line).vertices
//           .map(v => layer.vertices.get(v))
//           .forEach(v => _area = _area.size ? _area.intersect(v.areas) : _area.union(v.areas));
//         areas = areas.push(_area);
//         lines = lines.push(hole.line);
//         vertices = vertices.merge(Map(layer.lines.get(hole.line).vertices.map(v => [v,layer.vertices.get(v)])));
//         holes = holes.merge(Map(layer.lines.get(hole.line).holes.filter(_h => !hp.includes(_h)).map(_h => [_h, layer.holes.get(_h)])));
//       });
//
//       let {duplicated, hashMap} = _isLineDuplicated(layer, lines.get(0), lines.get(1));
//
//       if (duplicated) {
//         let {layer: _layer, hole} = _mergeLines(layer, lines, hashMap);
//         layer = _layer;
//         track = track.merge({[hole.id]:hole});
//
//         return;
//       }
//
//       areas = areas.map(a => a.first()).toSet();
//
//       lineType = layer.lines.get(lines.get(0)).type;
//       lineProps = layer.lines.get(lines.get(0)).properties;
//
//       holesWithOffset = holes.map(h => calculateHoleOffsetPoint(h, layer));
//       layer = _removeLines(layer, hp.map(hid => layer.holes.get(hid).line));
//       vertices = vertices.map(v => layer.vertices.get(v.id)).toList();
//       ({layer, holes} = addLinesFromVertices(layer, lineType, vertices, holesWithOffset, lineProps, areas));
//
//       // keep holesPairs to be updated to current number of holes
//       holesPairs = holesPairs.set(key, List().push(hp.get(0)));
//
//       track = track.merge(holes);
//     });
//
//   // add non-paired holes to track Immuteble.Map
//   let holesToPush = Map(holesPairs.map(h => track.get(h.get(0)) ? null : [h.get(0), layer.holes.get(h.get(0))]).toList());
//   if (holesToPush.size) track = track.merge(holesToPush);
//
//   return {layer: layer.set('holes', track)};
// }

function _cleanUpHoles(layer) {
    let {holes} = layer;

    let track = Map();
    let holesPairs = Map();

    holes.forEach(h => {
        if (!h.properties.get('hotSpotId')) {
            let line = layer.getIn(['lines', h.line]);
            if(!line) return ;

            layer = layer.setIn(['lines', h.line], line.set('holes', line.holes.push(h.id)));

            return track = track.set(h.id, h);
        }

        let holePair = [...holesPairs.keys()].find(k => k.every(_k => h.properties.get('connect').includes(_k)));

        if (holePair) holesPairs = holesPairs.set(holePair, holesPairs.get(holePair).push(h.id));
        else holesPairs = holesPairs.set(h.properties.get('connect').toArray(), List([h.id]));
    });

    if (!holesPairs.filter(hp => hp.size > 1).size && !track.size) return {layer};

    holesPairs.forEach(hp => {
        let hole = holes.get(hp.get(0));

        track = track.set(hole.id, hole);

        if (hp.size < 2) return;

        // Removing hole from layer
        let holeToRemove = holes.get(hp.get(1));
        let line = layer.lines.get(holeToRemove.line);
        if(line){
            line = line.set('holes', line.holes.filter(h => h !== holeToRemove.id));
            layer = layer.setIn(['lines', line.id], line);
        }
    });

    return {layer: layer.set('holes', track)}
}

function _mergeLines(layer, linesToMerge, verticesMap) {
    let line1 = linesToMerge.get(0); // line which will be updated
    let line2 = linesToMerge.get(1); // line which will be removed

    let verticesToRemove = layer.lines.get(line2).vertices.map(v => layer.vertices.get(v));
    let verticesToUpdate = layer.lines.get(line1).vertices.map(v => layer.vertices.get(v));
    let areaToUpdate = layer.areas.get(verticesToRemove.get(0).areas.get(0));
    let linesToUpdate = List();

    // Debug condition
    // if (layer.lines.get(line1).holes.size > 1) {
    //   console.error('Uncovered case of holes merging');
    // }

    let hole = layer.holes.get(layer.lines.get(line1).holes.first());
    verticesToRemove.forEach(v => linesToUpdate = linesToUpdate.push(...v.lines.filter(l => l !== line2).map(l => layer.lines.get(l))));
    verticesToUpdate = verticesToUpdate.map(v => !~v.areas.indexOf(areaToUpdate.id) ? v.set('areas', v.areas.push(areaToUpdate.id)) : v);

    areaToUpdate = areaToUpdate.set('vertices', areaToUpdate.vertices.map(v => {
        let _vertex = verticesToRemove.find(_v => _v.id === v);
        return _vertex ? verticesMap[_vertex.id] : v;
    }));

    linesToUpdate = linesToUpdate.map(l => {
        let index = -1;
        let _vertex = verticesToRemove.find(v => !!~(index = l.vertices.indexOf(v.id)));
        return _vertex && !!~index ? l.set('vertices', l.vertices.set(index, verticesMap[_vertex.id])) : l
    });

    layer = layer.merge({
        vertices: layer.vertices.map((v, k) => {
            let vertexUpdate = verticesToUpdate.find(_v => _v.id === k);
            let vertexRemove = verticesToRemove.find(_v => _v.id === k);
            return vertexUpdate
                ? vertexUpdate
                : vertexRemove
                    ? null
                    : v
        }).filter(Boolean),
        lines: layer.lines.remove(line2).map(l => {
            let _line = linesToUpdate.find(_l => _l.id === l.id);
            return _line ? _line : l
        }),
        areas: layer.areas.set(areaToUpdate.id, areaToUpdate)
    });

    return {layer, hole}
}

function _removeLines(layer, lines) {
    lines.forEach(l => {
        let vertices = Map(layer.lines.get(l).vertices.map(v => {
            let vertex = layer.vertices.get(v);
            return [v, vertex.set('lines', vertex.lines.splice(vertex.lines.indexOf(l), 1))]
        }));

        layer = layer.merge({
            lines: layer.lines.delete(l),
            vertices: layer.vertices.merge(vertices)
        })
    });

    return layer;
}

function _isLineDuplicated(layer, line1, line2) {
    let x = Set();
    let y = Set();
    let vertices = [];
    let hashMap = {};
    let duplicated;
    [line1, line2].forEach((l) => {
        vertices.push(...layer.lines.get(l).vertices.map(v => {
            let vertex = layer.vertices.get(v);
            return [vertex.x, vertex.y, v];
        }).toArray());

        x = x.union(vertices.map(v => v[0]));
        y = y.union(vertices.map(v => v[1]));
    });

    duplicated = x.size <= 2 && y.size <= 2;

    if (duplicated) {
        vertices.forEach(v => {
            let key = vertices.find(_v => ((_v[0] === v[0]) && (_v[1] === v[1]) && (_v[2] !== v[2])));
            hashMap[v[2]] = key[2];
        })
    }

    return ({duplicated, hashMap})
}

function _updateAreaPoints(layer, vertexToUpdate, pairVertex, areaId) {
    let indexes = [];
    let area = layer.areas.get(areaId);

    indexes[1] = area.vertices.indexOf(pairVertex.id);
    indexes[0] = indexes[1] - 1 < 0 ? area.vertices.size - 1 : indexes[1] - 1;
    indexes[2] = indexes[1] + 1 > area.vertices.size - 1 ? 0 : indexes[1] + 1;

    let {x, y} = vertexToUpdate;
    let {x: x1, y: y1} = pairVertex;
    let {x: x0, y: y0} = layer.vertices.get(area.vertices.get(indexes[0]));
    let {x: x2, y: y2} = layer.vertices.get(area.vertices.get(indexes[2]));
    let distancePrev = Geometry.distancePointFromLineSegment(x0, y0, x1, y1, x, y);
    let distanceNext = Geometry.distancePointFromLineSegment(x1, y1, x2, y2, x, y);

    // todo: need to be related with pairVertex case
    let index = distancePrev < distanceNext ? indexes[1] : indexes[2];

    layer = layer.setIn(['areas', area.id, 'vertices'], area.vertices.splice(index, 0, vertexToUpdate.id));

    return layer.setIn(['vertices', vertexToUpdate.id], vertexToUpdate.set('areas', vertexToUpdate.areas.push(areaId)));
}
