exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.home {\n  height: 100vh;\n  overflow: hidden; }\n  .home .h3 {\n    font-size: 32px; }\n    @media (max-width: 768px) {\n      .home .h3 {\n        font-size: 5vw; } }\n  .home .links {\n    padding: 16px 16px;\n    border-radius: 16px;\n    overflow: auto;\n    overflow-y: auto;\n    height: 100%;\n    margin-bottom: 32px; }\n    .home .links ul {\n      margin: 0;\n      padding: 0;\n      margin-bottom: 32px; }\n", ""]);

// exports
exports.locals = {
	"home": "home",
	"h3": "h3",
	"links": "links"
};