import React from 'react';
import ShapeTemplate from '../../share-template';

import './style.scss';

const shape = (dimensions) => {
    const { up, right, down, left } = dimensions;
    return [
        [0.00000000, 0.00000000],
        [0.00000000, left],
        [up, left],
        [down, right],
        [down, 0]
    ];
};

export default () => (
    <ShapeTemplate 
        defaultDimensions={{up: 100,
            right: 100,
            down: 200,
            left: 200}}
        classType="c-shape"
        shapeType="panorama"
        generateShapeHtml={shape}
    />
);
