export function objectsMap(object, func){
  let mappedObject = {};
  for (let key in object) {
    mappedObject[key] = func(key, mappedObject[key]);
  }
  return mappedObject;
}

export function objectsCompare( x, y ) {
  if ( x === y ) return true;
  if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
  if ( x.constructor !== y.constructor ) return false;
  
  for ( let p in x ) {
    if ( ! x.hasOwnProperty( p ) ) continue;
    if ( ! y.hasOwnProperty( p ) ) return false;
    if ( x[ p ] === y[ p ] ) continue;
    if ( typeof( x[ p ] ) !== 'object' ) return false;
    if ( ! objectsCompare( x[ p ],  y[ p ] ) ) return false;
  }

  for ( let p in y ) {
    if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
  }

  return true;
}

export function getAreas(scene) {
  const selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
  const selectedAreas = selectedLayer.getIn(['selected', 'areas']);
  const mergeAreas = selectedLayer.get('mergeAreas');

  return {selectedAreas, mergeAreas}
}

export function getMergeConnection(layer, id) {
  let mergeObj = {};
  const layerObj = layer.toJS()

  // for(let i of layer.getIn(['mergeAreas']).keys()){
  //     const mergeArea = layerObj.mergeAreas[i];

  //     if(layerObj.mergeAreas[i].connections.includes(id)) {
  //         mergeObj = mergeArea;
  //         break;
  //     }
  // }

  return layerObj;
}

export function isExist(scene) {
  return false;
  
  const {selectedAreas, mergeAreas} = getAreas(scene)
  const array1 = selectedAreas.toJS();
  let isExist = false;

  for(let i of mergeAreas.keys()){
      const mergeObj = mergeAreas.toJS();
      const obj = mergeObj[i];
      const array2 = obj.connections;
      isExist = checkItemPresence(array1, array2);

      if(isExist) {
          isExist = obj;
          break;
      }
  }

  return isExist;
}

export function isExistMergeConnection(layer, id) {
  return false;
  // let areaIds = [];
  // const layerObj = layer.toJS()

  // for(let i of layer.getIn(['mergeAreas']).keys()){
  //     areaIds = areaIds.concat(layerObj.mergeAreas[i].connections);
  // }

  // return areaIds.includes(id);

  let areaIds = new Set();
  const layerObj = layer.toJS();

  // Collect all connection ids in a Set for faster lookup
  for (let i of layer.getIn(['mergeAreas']).keys()) {
      const connections = layerObj.mergeAreas[i].connections;
      for (let conn of connections) {
          areaIds.add(conn);  // Using Set to prevent duplicates and speed up lookups
      }
  }

  // Check if the id exists in the Set
  return areaIds.has(id);
}