import React from 'react';
import ShapeTemplate from '../../share-template';

import './style.scss';

const shape = (dimensions) => {
    const { up, right, down, left } = dimensions;
    return [
        [0.00000000, 0.00000000],
        [0.00000000, right],
        [up, right],
        [down-up, left-right],
        [down-up, left],
        [down, left],
        [down, 0.00000000]
    ];
};

export default () => (
    <ShapeTemplate 
        defaultDimensions={{
            up: 100,
            right: 100,
            down: 200,
            left: 200
        }}
        classType="l-shape"
        shapeType="panorama"
        generateShapeHtml={shape}
    />
);
