import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FormNumberInput, FormTextInput } from 'a_component-styles/export';
import { PropertyLengthMeasure } from 'a_root-folder/catalog/properties/export';

// import s from '../element-editor.scss';
import c from 'a_component-styles/common.scss'

const PRECISION = 0;

export default function LineAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {

  // let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;name
  let vertexOne = attributeFormData.has('vertexOne') ? attributeFormData.get('vertexOne') : null;
  let vertexTwo = attributeFormData.has('vertexTwo') ? attributeFormData.get('vertexTwo') : null;
  let lineLength = attributeFormData.has('lineLength') ? attributeFormData.get('lineLength') : null;

  return <div className={c['form-group-wrapper']}>
    {/*<div className={`${c['form-group-row']} form-group`}>*/}
    {/*  <label className={c['form-group-label']}>X1</label>*/}
    {/*  <div className={c['form-group-field']}>*/}
    {/*    <FormNumberInput*/}
    {/*       value={vertexOne.get('x')}*/}
    {/*       onChange={event => onUpdate('vertexOne', {'x': event.target.value})}*/}
    {/*       state={state}*/}
    {/*       configs={{precision: PRECISION}}*/}
    {/*       {...rest}*/}
    {/*     />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div className={`${c['form-group-row']} form-group`}>*/}
    {/*  <label className={c['form-group-label']}>Y1</label>*/}
    {/*  <div className={c['form-group-field']}>*/}
    {/*    <FormNumberInput*/}
    {/*      value={vertexOne.get('y')}*/}
    {/*      onChange={event => onUpdate('vertexOne', {'y': event.target.value})}*/}
    {/*      state={state}*/}
    {/*      configs={{precision: PRECISION}}*/}
    {/*      {...rest}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div className={`${c['form-group-row']} form-group`}>*/}
    {/*  <label className={c['form-group-label']}>X2</label>*/}
    {/*  <div className={c['form-group-field']}>*/}
    {/*    <FormNumberInput*/}
    {/*      value={vertexTwo.get('x')}*/}
    {/*      onChange={event => onUpdate('vertexTwo', {'x': event.target.value})}*/}
    {/*      state={state}*/}
    {/*      configs={{precision: PRECISION}}*/}
    {/*      {...rest}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div className={`${c['form-group-row']} form-group`}>*/}
    {/*  <label className={c['form-group-label']}>Y2</label>*/}
    {/*  <div className={c['form-group-field']}>*/}
    {/*    <FormNumberInput*/}
    {/*      value={vertexTwo.get('y')}*/}
    {/*      onChange={event => onUpdate('vertexTwo', {'y': event.target.value})}*/}
    {/*      state={state}*/}
    {/*      configs={{precision: PRECISION}}*/}
    {/*      {...rest}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<PropertyLengthMeasure*/}
    {/*  value={ lineLength }*/}
    {/*  onUpdate={mapped => onUpdate('lineLength', mapped)}*/}
    {/*  configs={{label: translator.t('Length'), min: 0, max: Infinity, precision: PRECISION}}*/}
    {/*  state={state}*/}
    {/*/>*/}
  </div>;
}

LineAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

LineAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
