import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Modal from './wrapper';

export default class ModalAlert extends Component {
  constructor(props) {
    super(props);
  }

  dismiss() {
    this.context.modalActions.hideModal();
  }

  render() {
    const {title} = this.props;
    return <Modal title={title} onClose={(e) => this.dismiss()}>
      <div className='modal-content-body-footer'>
        <button className="btn white" onClick={(e) => this.dismiss()}>Got it!</button>
      </div>
    </Modal>
  }
}

ModalAlert.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ModalAlert.contextTypes = {
  modalActions: PropTypes.object.isRequired
};
