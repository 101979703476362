import React from 'react';
import PropTypes from 'prop-types';
import {
  Line,
  Area,
  Vertex,
  Item,
  Group,
  Label
} from './export';
import Outline from './outline';

export default function Layer({ layer, scene, catalog, rotatingSupport, mode }) {
  let { unit, groups, hideMeasurement, metrical } = scene;
  let { lines, areas, vertices, holes, id: layerID, items, opacity } = layer;

  return (
    <g opacity={opacity}>
       {areas.entrySeq().map(([areaID, area]) => (<React.Fragment key={`${areaID}-areas-fragment`}>
              <Outline key={`${areaID}-Outline`} layer={layer} area={area}
                        rotatingSupport={rotatingSupport} unit={unit} catalog={catalog} scene={scene}
                        mode={mode}/>
      </React.Fragment>))}
      {
        areas.valueSeq().map(area =>
        <Area key={area.id} layer={layer} area={area} unit={unit} catalog={catalog} scene={scene}
          rotatingSupport={rotatingSupport} mode={mode} />)
      }
      {areas.entrySeq().map(([areaID, area]) => (<Label metrical={metrical} hideMeasurement={hideMeasurement} mode={mode} key={`${areaID}-Label`}
                  layer={layer} area={area}
                  unit={unit} catalog={catalog} scene={scene}/>))}
      {
        lines.valueSeq().map(line =>
          <Line key={line.id} layer={layer} line={line} scene={scene} catalog={catalog} />)
      }
      {
        items.valueSeq().map(item =>
          <Item key={item.id} layer={layer} item={item} scene={scene} catalog={catalog} />)
      }
      {
        vertices
          .valueSeq()
          .filter(v => v.selected)
          .map(vertex => <Vertex key={vertex.id} layer={layer} vertex={vertex} />)
      }
      {
        groups
          .valueSeq()
          .filter(g => g.hasIn(['elements', layerID]) && g.get('selected'))
          .map(group => <Group key={group.get('id')} layer={layer} group={group} scene={scene} catalog={catalog} />)
      }
    </g>
  );

}

Layer.propTypes = {
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
