import React from 'react';
import ReactDOM from 'react-dom';
import Immutable, {Map} from 'immutable';
import immutableDevtools from 'immutable-devtools';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import { thunk } from 'redux-thunk';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/home';
import NewTour from './pages/new-tour';
import ChooseShape from './pages/choose-shape';
import EditShape from './pages/edit-shape';
import Planner from './pages/planner';
import NavigationBack from './components/navigation-back';
import Scan from './pages/scan';
import {MessageProvider} from './hoc/messages/index';
import {LoadingProvider} from './hoc/loading/index';
import {UploadQueueProvider} from './hoc/with-upload-queue/index';
import './style/index.scss';
import {
  Models as PlannerModels,
  reducer as PlannerReducer
} from 'react-planner';
import NoSleepApp from 'no-sleep-app';
var noSleep = new NoSleepApp();
noSleep.enable();
//define state
let AppState = Map({
  'react-planner': new PlannerModels.State()
});

//define reducer
let reducer = (state, action) => {
  state = state || AppState;
  state = state.update('react-planner', plannerState => PlannerReducer(plannerState, action));
  return state;
};

let blackList = isProduction === true ? [] : [
  'UPDATE_MOUSE_COORDS',
  'UPDATE_ZOOM_SCALE',
  'UPDATE_2D_CAMERA'
];

if( !isProduction ) {
  console.info('Environment is in development and these actions will be blacklisted', blackList);
  console.info('Enable Chrome custom formatter for Immutable pretty print');
  immutableDevtools( Immutable );
}

//init store
// let store = createStore(
//   reducer,
//   null,
//   !isProduction && window.devToolsExtension ?
//     window.devToolsExtension({
//       features: {
//         pause   : true,     // start/pause recording of dispatched actions
//         lock    : true,     // lock/unlock dispatching actions and side effects
//         persist : true,     // persist states on page reloading
//         export  : true,     // export history of actions in a file
//         import  : 'custom', // import history of actions from a file
//         jump    : true,     // jump back and forth (time travelling)
//         skip    : true,     // skip (cancel) actions
//         reorder : true,     // drag and drop actions in the history list
//         dispatch: true,     // dispatch custom actions or action creators
//         test    : true      // generate tests for the selected actions
//       },
//       actionsBlacklist: blackList,
//       maxAge: 999999
//     }) :
//     f => f
// );

let store = createStore(reducer, null, applyMiddleware(thunk));

export const ROUTER_LINKS = [
  {
      label: 'Home',
      to: "/",
      component: Home,
      exact: true
  },
  {
      label: 'New tour',
      to: "/new",
      component: NewTour,
      exact: false
  },
  {
      label: 'Planner',
      to: "/planner",
      component: Planner,
      exact: false
  },
  {
      label: 'Add room',
      to: "/add-room",
      component: ChooseShape,
      exact: false
  },
  {
      label: 'Edit room',
      to: "/edit-room/:id",
      component: EditShape,
      exact: false
  },
  {
    label: 'Scan',
    to: "/scan/:type",
    component: Scan,
    exact: false
}
]

ReactDOM.render(
  (
      <LoadingProvider>
        <MessageProvider>
          <Router>
            <div className="wrapper flex-column">
              {/* <Navigation /> */}
              {/* <NavigationBack />  */}
              <Provider store={store}>
                <UploadQueueProvider>
                  <Switch>
                    {ROUTER_LINKS.map(({component, to, exact}, ind) => (
                      <Route exact={exact} path={to} component={component} key={ind} />
                    ))}
                    <Redirect to='/' />
                  </Switch>
                </UploadQueueProvider>
              </Provider>
            </div>
          </Router>
        </MessageProvider>
      </LoadingProvider>
  ),
  document.getElementById('app')
);

