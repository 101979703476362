import React, { useEffect, useRef, useState } from 'react';
import { ROOM_NAMES } from '../../constants';
import ReactAutocomplete from 'react-autocomplete';
import './style.scss';

function RoomTypePicker({ title, setTitle, onChooseRoom }) {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(true);

  const onChange = (value) => {
    setOpen(false);
    setTitle(value);
    onChooseRoom();
  }

  useEffect(() => {
    inputRef.current.select();
    inputRef.current.focus();
  }, []);

  return (
    <div className="roomTypePicker flex-column">
      <label htmlFor="roomInput">Choose or type a room:</label>
      <div className='roomTypePicker--input'>
        <ReactAutocomplete
          items={ROOM_NAMES.slice().sort().map((room, index) => {
            return { id: index, label: room };
          })}
          shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
          open={open}
          getItemValue={item => item.label}
          renderItem={(item, highlighted) =>
            <div
              key={item.id}
              style={{ backgroundColor: highlighted ? '#eee' : 'transparent', textAlign: 'left', padding: '5px', cursor: 'pointer' }}
            >
              {item.label}
            </div>
          }
          value={title}
          onSelect={onChange}
          placeholder="Select or type your room"
          onChange={e => {
            setTitle(e.target.value);
            setOpen(true);
          }}
          renderInput={(params) => (
            <input {...params} tabIndex="1" variant="outlined" />
          )}
          autoHighlight={true}
          selectOnBlur={false}
          selectOnTab={false}
          ref={ inputRef }
        />
      </div>
    </div>
  );
}

export default RoomTypePicker;
