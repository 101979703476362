import FloorplanManager from './floorplanManager';
const polygonsIntersect = require('polygons-intersect');

export const createArea = (coords, name, shape, id) => {
    const layout = new FloorplanManager();
    const {layer, areaId} = layout.createArea(coords, coords, name, shape, id);
    const layers = JSON.parse(layer).layers['layer-1'];

    layout.mergeArea(layers, areaId);

    guessDoor();
    
    return layers;
}

function findMiddleOfLine(coord) {
    const [{x: x1, y: y1}, {x: x2, y: y2}] = coord;

    const midX = (x1 + x2) / 2;
    const midY = (y1 + y2) / 2;

    return [midX, midY];
}

const findAngleBetweenLines = (line1, line2) => {
   // Destructure start and end points of the lines
   const [start1, end1] = line1;
   const [start2, end2] = line2;

   // Calculate the direction vectors for each line
   const vector1 = { x: end1.x - start1.x, y: end1.y - start1.y };
   const vector2 = { x: end2.x - start2.x, y: end2.y - start2.y };

   // Calculate the dot product of the two vectors
   const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;

   // Calculate the magnitudes of the vectors
   const magnitude1 = Math.sqrt(vector1.x ** 2 + vector1.y ** 2);
   const magnitude2 = Math.sqrt(vector2.x ** 2 + vector2.y ** 2);

   // Calculate the cosine of the angle between the vectors
   const cosTheta = dotProduct / (magnitude1 * magnitude2);

   // Clamp the cosine value to avoid numerical errors outside the range [-1, 1]
   const clampedCosTheta = Math.max(-1, Math.min(1, cosTheta));

   // Calculate the angle in radians and then convert to degrees
   const angleInRadians = Math.acos(clampedCosTheta);
   const angleInDegrees = angleInRadians * (180 / Math.PI);

   return angleInDegrees;
}

const retrieveVerticesAndLines = (layout, prev, current) => {
    return {
        vertices: {
            prev: layout.getVertices(prev.vertices),
            current: layout.getVertices(current.vertices),
        },
        lines: {
            prev: layout.getLines(prev.vertices),
            current: layout.getLines(current.vertices),
        },
    };
};

function resetArea(layout, current) {
    layout.rotateArea(current.id, 0, true);
    layout.resetAreaAngle(current.id, 0);
    updateLayout(layout);
}

function updateLayout(layout) {
    layout.saveLayout();
    layout.updateFloorplan();
}

const processAutoConnect = (current, sortedDistances) => {
    current.autoconnect = (current.autoconnect || 0) % sortedDistances.length;
    console.log('sortedDistances', sortedDistances.length)
    console.log('autoconnect', current.autoconnect)
};

const removePreviousHoles = (layout, prev) => {
    prev.holes.forEach(id => layout.removeHole(id));
    prev.holes = [];
};

const getUpdateVertices = (layout, to) => {
    const toLine = layout.getLinesID(to.id);
    return layout.getVertices(toLine.vertices);
}

const checkDirection = (layout, current, prev, from, to) => {
    const poly0 = layout.getVertices(current.vertices).map((res) => { return {x: res.x, y: res.y }});
    const poly1 = layout.getVertices(prev.vertices).map((res) => { return {x: res.x, y: res.y }});

    console.log('polygonsIntersect', polygonsIntersect(poly0, poly1));

    if (polygonsIntersect(poly0, poly1).flat().length) {
        layout.rotateArea(current.id, 180);
        updateLayout(layout);
        finalizeAlignment(layout, current, from, to);
        updateLayout(layout);
    }
}

const finalizeAlignment = (layout, current, from, to) => {
    const toVertices = getUpdateVertices(layout, to);
    console.log('toVertices', { x: toVertices[0].x, y: toVertices[0].y }, { x: toVertices[1].x, y: toVertices[1].y });

    const center = findMiddleOfLine(from.coords);
    const centerTo = findMiddleOfLine(toVertices);
    console.log(center);

    ensureVerticesOrder(toVertices);
    ensureVerticesOrder(from.coords);

    layout.translate2DArea(current.id, [center[0] - centerTo[0], center[1] - centerTo[1]]);
};

const ensureVerticesOrder = (vertices) => {
    if (vertices[0].x > vertices[1].x) [vertices[0], vertices[1]] = [vertices[1], vertices[0]];
};

const angleBetweenTwoPointsAndOrigin = (x1, y1, x2, y2) => {
    return -(Math.atan2(y1 - y2, x2 - x1)) * 180 / Math.PI;
  }

const rotateArea = (layout, from, to, current, prev) => {
    let angle = findAngleBetweenLines(from.coords, to.coords);
    if (isNaN(angle)) {
        angle= 0;
    }
    layout.saveAutoConnect(current.id, current.autoconnect);
    layout.addHole({ lineId: from.id, offset: 0.5 }, prev.id);
    layout.rotateArea(current.id, angle, true);
    updateLayout(layout);
    console.log(angle)
    console.log(from.id)
    console.log(to.id)
    console.log('from', from.coords)
    console.log('to', to.coords)
}

const findDoorWall = (layout, prev, current) => {
    const { vertices, lines } = retrieveVerticesAndLines(layout, prev, current);
    const sortedDistances = findClosestDistance([vertices.prev, vertices.current], [lines.prev, lines.current]);
    console.log("Sorted distances:", sortedDistances);
    processAutoConnect(current, sortedDistances);

    return sortedDistances;
}
 
export const guessDoor = () => {
    const layout = new FloorplanManager();
    layout.updateFloorplan();

    let [prev, current] = layout.getOrderAreas();
    if (!current) return;

    resetArea(layout, current);
    updateLayout(layout);
    const sortedDistances = findDoorWall(layout, prev, current);
    const { from, to } = sortedDistances[current.autoconnect];
    current.autoconnect += 1;
    removePreviousHoles(layout, prev);
    rotateArea(layout, from, to, current, prev);
    finalizeAlignment(layout, current, from, to);
    updateLayout(layout);
    checkDirection(layout, current, prev, from, to);
};

const mapLinesToCoords = (lines, vertices, origin=false) => {
    return lines.map(line => ({
        id: line.id,
        coords: line.vertices.map(vertice => {
            const { x, y } = origin ? vertices.find(v => v.id === vertice).origin : vertices.find(v => v.id === vertice);
            return { x, y };
        })
    }));
};

const calculateDistance = (coords1, coords2) => {
    const [p1, p2] = coords1;
    const [q1, q2] = coords2;

    const distance1 = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    const distance2 = Math.sqrt(Math.pow(q2.x - q1.x, 2) + Math.pow(q2.y - q1.y, 2));

    return Math.abs(distance1 - distance2);
};


const findUpperAndRightLine = (lines) => {
    console.log(JSON.stringify(lines));
    let upperLine = null;
    let lowerLine = null;
    let leftLine = null;
    let rightLine = null;

    lines.forEach(line => {
        const [start, end] = line.coords;

        // Calculate the min and max of the line's x and y coordinates
        const minY = Math.min(start.y, end.y);
        const maxY = Math.max(start.y, end.y);
        const minX = Math.min(start.x, end.x);
        const maxX = Math.max(start.x, end.x);

        // Check for the most upper line (smallest minY)
        if (!upperLine || minY < upperLine.minY) {
        upperLine = {
            id: line.id,
            minY,
            coords: line.coords
        };
        }

        // Check for the most lower line (largest maxY)
        if (!lowerLine || maxY > lowerLine.maxY) {
        lowerLine = {
            id: line.id,
            maxY,
            coords: line.coords
        };
        }

        // Check for the most left line (smallest minX)
        if (!leftLine || minX < leftLine.minX) {
        leftLine = {
            id: line.id,
            minX,
            coords: line.coords
        };
        }

        // Check for the most right line (largest maxX)
        if (!rightLine || maxX > rightLine.maxX) {
        rightLine = {
            id: line.id,
            maxX,
            coords: line.coords
        };
        }
    });

    return { upperLine, lowerLine, leftLine, rightLine };
}

const findClosestDistance = ([verticesPre, vertices], [linesPre, lines]) => {
    const prevLinesWithCoords = mapLinesToCoords(linesPre, verticesPre);
    const currentLinesWithCoords = mapLinesToCoords(lines, vertices, true);
    const { upperLine, rightLine } = findUpperAndRightLine(prevLinesWithCoords);
    const referenceLines = [upperLine, rightLine];
    const distances = [];  

    for (const refLine of prevLinesWithCoords) {
        const { coords: refCoords } = refLine;

        for (const currLine of currentLinesWithCoords) {
            const { coords: currCoords } = currLine;

            const distance = calculateDistance(refCoords, currCoords);

            distances.push({
                distance,
                from: refLine,
                to: currLine
            });
        }
    }

    return distances.sort((a, b) => a.distance - b.distance);
};

export const getArea = (id) => {
    const layout = new FloorplanManager();

    return  layout.getArea(id);
}

export const getVertices = (ids) => {
    const layout = new FloorplanManager();

    return  layout.getVertices(ids);
}

export const removeArea = (id) => {
    const layout = new FloorplanManager();

    return  layout.removeArea(id);
}

const SCALE_FACTOR = 100;

export const scaleCoordinates = (coords) => {
    return coords.map(([x, y]) => [x * SCALE_FACTOR, y * SCALE_FACTOR]);
}
