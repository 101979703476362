import React, {useState, useEffect, useRef, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { FLOORPLAN_NAME } from '../../constants';
import { setItem } from '../../helpers/localStorage';
const TEMPLATE_LINKS = require('../../mock/template.json');
import {create, update} from '../../api/floorplan';
import { getUserID, getTourId } from '../../helpers/localStorage';
import LoadingContext from '../../hoc/loading/index';

import './style.scss';

export default () => {
    const history = useHistory();
    const [inputValue, setInputValue] = useState('Untitled floorplan');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); 
    const inputRef = useRef(null); 
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const createNewTour = async(id) => {
        await create(getUserID(), inputValue);

        history.push(`/add-room`);
    }

    const moveToPlanner = () => {
        history.push(`/planner`);
    }

    const onMovePage = async (floorplan_json) => {
        showLoading();
        await create(getUserID(), inputValue);
        setItem(FLOORPLAN_NAME, floorplan_json);
        await update(getUserID(), {id: getTourId(), floorplan_json});
        hideLoading();

        moveToPlanner();
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            createNewTour();
        }
    };

    useEffect(() => {
        setIsButtonDisabled(inputValue.trim() === '');
    }, [inputValue]);

    useEffect(() => {
        inputRef.current.select();
        inputRef.current.focus();
      }, []);

    return (
        <div className='new-page flex-column layout'>
            <div className='new-page--header'>
                <input
                    type='text'
                    className='new-page--text'
                    ref={inputRef}
                    placeholder=''
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)} 
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className='new-page--content flex-column justify-center align-center'>
                <div className='new-page--create'>
                    <button
                        className='btn btn-primary'
                        onClick={() => createNewTour('new')}
                        disabled={isButtonDisabled} 
                    >
                        Create
                    </button>
                </div>
                <div><span className='h4'>or choose template</span></div>
                <div className='new-page--links'>
                    <ul>
                        {TEMPLATE_LINKS.map(({ name, floorplan_json }, ind) => (
                            <li key={`template-links-${ind}`} onClick={() => onMovePage(floorplan_json)}>
                                <button className='btn'>
                                    <span className='h4'>{name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};