import config from '../../../config.json'
export const isProduction = process.env.NODE_ENV === 'production';
export const DOMAIN = config.DOMAIN;
export const DEV_DOMAIN = config.DEV_DOMAIN;

export const SCAN_DOMAIN =  isProduction ? DOMAIN : DEV_DOMAIN;
export const ROOM_NAMES = [
    'Hall',
    'Kitchen',
    'Living Room',
    'Bedroom',
    'Bathroom',
    'Dining Room',
    'Study',
    'Garage',
    'Laundry Room',
    'Basement',
    'Attic'
]

export const API_DOMAIN = 'https://dev4.e3.eyespy360.com';
export const FLOORPLAN_NAME = 'react-planner_v0';
export const USER_ID = 'current_user';
export const TOUR_ID = 'current_tour_id';
