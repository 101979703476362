exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".message {\n  position: fixed;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 300px;\n  text-align: center;\n  padding: 12px 20px;\n  border-radius: 4px;\n  font-size: 16px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  transition: opacity 0.3s ease-in-out;\n  opacity: 0;\n  visibility: hidden; }\n  .message.visible {\n    opacity: 1;\n    visibility: visible; }\n  .message.success {\n    background-color: #d4edda;\n    color: #155724;\n    border: 1px solid #c3e6cb; }\n  .message.warning {\n    background-color: #fff3cd;\n    color: #856404;\n    border: 1px solid #ffeeba; }\n  .message.danger {\n    background-color: #f8d7da;\n    color: #721c24;\n    border: 1px solid #f5c6cb; }\n  .message.info {\n    background-color: #d1ecf1;\n    color: #0c5460;\n    border: 1px solid #bee5eb; }\n  .message.primary {\n    background-color: #cce5ff;\n    color: #004085;\n    border: 1px solid #b8daff; }\n  .message.secondary {\n    background-color: #e2e3e5;\n    color: #383d41;\n    border: 1px solid #d6d8db; }\n  .message.dark {\n    background-color: #d6d8d9;\n    color: #1b1e21;\n    border: 1px solid #c6c8ca; }\n  .message.light {\n    background-color: #fefefe;\n    color: #818182;\n    border: 1px solid #fdfdfe; }\n", ""]);

// exports
exports.locals = {
	"message": "message",
	"visible": "visible",
	"success": "success",
	"warning": "warning",
	"danger": "danger",
	"info": "info",
	"primary": "primary",
	"secondary": "secondary",
	"dark": "dark",
	"light": "light"
};