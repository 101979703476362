import ModalAlert from './alert';
import ModalConfirm from './confirm';

export {
  ModalAlert,
  ModalConfirm,
}

export default {
  ModalAlert,
  ModalConfirm,
}
