import {
    AREA,
    LENGTH,
    UNIT_METER,
    UNIT_MILLIMETER,
    UNIT_FOOT,
    CONVERT_METER_INCH,
    CONVERT_INCH_METER,
    UNIT_METER_SQUARE,
    UNIT_FOOT_SQUARE,
    UNIT_INCH,
    CONVERT_FOOT_METER
} from '../constants';
import convert from 'convert-units';

export function convertMetric(value, type, metrical, unit = UNIT_METER, showUnit = true, format = true, pointSign = 2) {
  switch (type){
    case AREA:
      return removeUnit(metrical ?
        {value: `${(value).toFixed(pointSign)}`, unit: `${UNIT_METER}`} :
        {value: `${convertToImperialStyle(convert((value)).from(UNIT_METER_SQUARE).to(UNIT_FOOT_SQUARE).toFixed(0), format)}`, unit: `${UNIT_FOOT}`}
        , showUnit)

      break;
    case LENGTH:
      switch (unit){
        case UNIT_METER:
          return removeUnit(convertMetricMeter(value, type, metrical, format, pointSign), showUnit);
          break;
        case UNIT_MILLIMETER:
          return removeUnit(convertMetricMillimeter(value, type, metrical, format, pointSign), showUnit);
          break;
        case UNIT_INCH:
          return removeUnit(convertMetricInch(value, type, metrical, pointSign), showUnit);
          break;
        case CONVERT_METER_INCH:
          return removeUnit(convertMetricMeterInch(value, type, metrical, pointSign), showUnit);
          break;
        case CONVERT_INCH_METER:
          return removeUnit(convertMetricInchMeter(value, type, metrical, pointSign), showUnit);
          break;
        case CONVERT_FOOT_METER:
          return removeUnit(convertMetricFootMeter(value, type, metrical, pointSign), showUnit);
          break;
      }

      break;
  }
}

function convertMetricInch(value, type, metrical, pointSign) {
  return metrical ?
    {value: `${convert((value)).from(UNIT_INCH).to(UNIT_MILLIMETER).toFixed(pointSign)}`, unit:  `${UNIT_MILLIMETER}`}:
    {value: `${(value).toFixed(pointSign)}`, unit: `${UNIT_INCH}`};
}

function convertMetricMillimeter(value, type, metrical, format, pointSign) {
  return metrical ?
    {value: `${(value).toFixed(pointSign)}`, unit: `${UNIT_MILLIMETER}`} :
    {value: `${convertToImperialStyle(convert((value)).from(UNIT_MILLIMETER).to(UNIT_INCH).toFixed(pointSign), format)}`, unit: `${UNIT_INCH}`};
}

function convertMetricMeter(value, type, metrical, format, pointSign) {
  return metrical ?
    {value: `${(value).toFixed(pointSign)}`, unit: `${UNIT_METER}`} :
    {value: `${convertToImperialStyle(convert((value)).from(UNIT_METER).to(UNIT_FOOT).toFixed(pointSign), format)}`, unit: `${UNIT_FOOT}`};
}

function convertMetricMeterInch(value, type, metrical, format, pointSign) {
  return {value: `${convert((value)).from(UNIT_METER).to(UNIT_INCH).toFixed(pointSign)}`, unit:  `${UNIT_INCH}`};
}

function convertMetricInchMeter(value, type, metrical, format, pointSign) {
  return {value: `${convert((value)).from(UNIT_INCH).to(UNIT_METER).toFixed(pointSign)}`, unit:  `${UNIT_METER}`};
}

function convertMetricFootMeter(value, type, metrical, pointSign) {
  return {value: `${convert((value)).from(UNIT_FOOT).to(UNIT_METER).toFixed(pointSign)}`, unit:  `${UNIT_METER}`};
}

function removeUnit(obj, showUnit){
    if(!showUnit) obj.unit = '';
    return obj;
}

function convertToImperialStyle(value, format){
    if(!format) return value;
    return value.toString().split('.').map(Number).reduce((a, b) => `${a}'${b >= 0 && (b/10).toFixed(2)*10}`);
}
