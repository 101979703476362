import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './modal.scss';

export default class Modal extends Component {

  constructor(props){
    super(props);

    this.listenKeyboard = this.listenKeyboard.bind(this);
    this.onOverlayClick = this.onOverlayClick.bind(this);
  }

  listenKeyboard(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.onClose();
    }
  };

  componentDidMount () {
    if (this.props.onClose) {
      window.addEventListener('keydown', this.listenKeyboard, true);
    }
  }

  componentWillUnmount () {
    if (this.props.onClose) {
      window.removeEventListener('keydown', this.listenKeyboard, true);
    }
  }

  get title () {
    const { title } = this.props;

    return title ? (
      <div className='modal__title'>
        <h3>{title}</h3>
      </div>
    ) : null;
  }

  get close () {
    const { onClose } = this.props;

    return onClose ? (
      <div className='modal__close' onClick={onClose}>
        <i className="fa fa-times" />
      </div>
    ) : null;
  }

  onOverlayClick() {
    this.props.onClose ? this.props.onClose() : null;
  };

  onDialogClick(event) {
    event.stopPropagation();
  };

  render () {
    return (
      <div className={s.modal}>
        <div className={s['modal-overlay']} />
        <div className={s['modal-content']} onClick={this.onOverlayClick}>
          <div className={s['modal-content-dialog']} onClick={this.onDialogClick}>
            <div className={s['modal-content-header']} >
              {this.title}
              {this.close}
            </div>
            <div className={s['modal-content-body']}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func
};
