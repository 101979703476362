import React from 'react';
import { useParams } from "react-router-dom";
import {SHAPES} from "../choose-shape";

import './style.scss';

export default () => {
    const params = useParams();
    const template = SHAPES.find((shape) => shape.id == params.id);

    return (
        <section className="edit-shape layout">
            <template.component {...template} />
        </section>
    )
};
