import React from 'react';

const Shape = ({ coordinates }) => {
    // Determine the maximum x and y values
    const maxX = Math.max(...coordinates.map(([x, _]) => x));
    const maxY = Math.max(...coordinates.map(([_, y]) => y));
    const maxDimension = Math.max(maxX, maxY); // Define 100% scale based on the larger dimension

    // Calculate the clip-path value based on the largest dimension
    const clipPathValue = coordinates
        .map(([x, y]) => {
            const xPercent = (x / maxDimension) * 100;
            const yPercent = (y / maxDimension) * 100;
            return `${xPercent}% ${yPercent}%`;
        })
        .join(', ');

    // Define the style with dynamically calculated clip-path
    const shapeStyle = {
        width: '200px',  // Adjust the container size as needed
        height: '200px',
        backgroundColor: '#4e4e4e',
        clipPath: `polygon(${clipPathValue})`,
    };

    return <div style={shapeStyle}></div>;
};

export default Shape;
