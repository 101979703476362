import React, { useEffect, useState, useRef, useMemo, createRef } from 'react';
import TemplateAction from '../template-action';
import { createArea, getArea, removeArea, getVertices } from '../../helpers/area';
import Shape from '../shape';
import RoomTypePicker from '../room-type-picker';
import { useParams, useLocation } from "react-router-dom";
import DimensionInput from '../dimension-input';
import {calculateBoundingBox} from "../../helpers/shape";
import {round} from "../../helpers/math"; 

const ShapeTemplate = ({ defaultDimensions, shapeType, generateShapeHtml, classType }) => {
    const [dimensions, setDimensions] = useState(defaultDimensions);
    const [title, setTitle] = useState('');
    const [shape, setShape] = useState([]);
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const inputRef = useRef(null);

    const refsById = useMemo(() => {
		const refs = {}
		Object.entries(dimensions).forEach((item, ind) => {
			refs[ind] = createRef(null)
		})
		return refs
	}, [dimensions])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDimensions(prev => ({
            ...prev,
            [name]: parseInt(value) || 0
        }));
    };

    const onSaveArea = () => {
        if (!id) {
            return createArea(shape, title, params.id);
        }
        removeArea(id);
        createArea(shape, title, params.id, id);
    };
    
    const onChooseRoom = () => {
        refsById[0].current.select();
        refsById[0].current.focus();
    }

    useEffect(() => {
        if (generateShapeHtml) {
            setShape(generateShapeHtml(dimensions));
        }
    }, [dimensions, generateShapeHtml]);

    useEffect(() => {
        const area = getArea(id);
        if (area.name) {
            setTitle(area.name);
            const vertices = getVertices(area.vertices);
            const { width: up, height: right } = calculateBoundingBox(vertices);
            setDimensions({up: round(up), right: round(right)});
        }
    }, [id]);

    return (
        <section className={`${classType}-template`}>
            <div className="rectangle-template--heading">
                <RoomTypePicker title={title} setTitle={setTitle} onChooseRoom={onChooseRoom} />
            </div>
            <div className={`${classType}-template--container shape`}>
                {Object.entries(dimensions).map(([name, value], ind) => (
                    <DimensionInput
                        key={`dimension_${ind}`}
                        name={name}
                        tabIndex={ind + 1}
                        value={value}
                        placeholder={value}
                        onChange={handleInputChange}
                        refInputDimension={refsById[ind]}
                    />
                ))}
                <div className="img" style={{padding: '16px', width: '200px', height: '200px'}}>
                    <Shape coordinates={shape} />
                </div>
            </div>
            <TemplateAction type={shapeType} title={title} dimensions={dimensions} onSaveArea={onSaveArea} />
        </section>
    );
};

export default ShapeTemplate;
