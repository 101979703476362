import {
    select,
    unselect,
    unselectAll,
} from '../utils/layer-operations';

import {
    BEGIN_DRAGGING_AREA, MODE_DRAGGING_AREA, PUT_MERGE_AREAS, MODE_IDLE, SELECT_AREA, SELECT_AREAS, MERGE_AREAS, END_DRAGGING_AREA, UPDATE_DRAGGING_AREA,
    END_SCALING_AREA, BEGIN_SCALING_AREA, UPDATE_SCALING_AREA, MODE_SCALING_AREA, BEGIN_SCALING_AREA_VERTEX,
    THICKNESS, ROTATE, BEGIN_ROTATING_AREA, UPDATE_ROTATING_AREA, REMOVE_MERGE_AREAS, END_ROTATING_AREA, MODE_ROTATING_AREA,
    MODE_MANAGING_LAYERS, UNIT_CENTIMETER, MOVE_AREA, REMOVE_AREA, RENAME_AREA
} from '../constants';
import {List, Map} from "immutable";
import * as Geometry from '../utils/geometry';
import {canMove, canScaling, updatePoints, updatePointForRotation, updateCameraPosition} from '../utils/areas-helper';
import {rotate2DPointAroundCenter} from 'a_root-folder/utils/geometry'
import {deg2rad, rad2deg} from 'a_root-folder/utils/mathematics'
import {calculateRotationAngle, movePoint} from 'a_root-folder/utils/geometry';
import {getVertexRenderCoords} from 'a_root-folder/utils/layer-operations'
import {
    calculateLayerCenter,
    getLayerRotationHelpers,
    rotateVertex,
    calculateHoleOffsetPoint,
    moveVertex,
    moveLayer,
    rotateLayer
} from 'a_root-folder/utils/layer-operations';
import {addLineSnap, nearestSnap} from "../utils/snap";
import {sceneSnapElements} from "../utils/snap-scene";
import {isTextureCeiling, isTextures} from "../components/viewer3d/scene-creator";
import {isSnapActive} from 'utils/helper';
import {func} from 'prop-types';
import {Record} from 'immutable';
import { history } from '../utils/export';

const shortid = require('shortid');

export default function (state, action) {
  switch (action.type) {
        case SELECT_AREAS:
            return selectAreas(state, action.layerID, action.areaID);

        case SELECT_AREA:
            return selectArea(state, action.layerID, action.areaID, action.shiftKey);

        case PUT_MERGE_AREAS:
            return putMergeAreas(state, action.layerID, action.selectedAreas, action.values);
    
        case MERGE_AREAS:
            return mergeAreas(state, action.layerID, action.selectedAreas, action.values);

        case BEGIN_SCALING_AREA:
            return beginScalingArea(state, action.layerID, action.areaID, action.x, action.y);

        case BEGIN_SCALING_AREA_VERTEX:
            return beginScalingAreaVertex(state, action.layerID, action.vertexID, action.x, action.y);

        case UPDATE_SCALING_AREA:
            return updateScalingArea(state, action.x, action.y);

        case BEGIN_DRAGGING_AREA:
            return beginDraggingArea(state, action.layerID, action.areaID, action.x, action.y);

        case UPDATE_DRAGGING_AREA:
            return updateDraggingArea(state, action.x, action.y);

        case END_SCALING_AREA:
            return endScalingArea(state, action.layerID, action.areaID);

        case REMOVE_MERGE_AREAS:
            return  removeMergeAreas(state, action.layerID, action.selectedAreas);

        case END_DRAGGING_AREA:
            return endDraggingArea(state, action.x, action.y);

        case BEGIN_ROTATING_AREA:
            return beginRotatingArea(state, action.layerID, action.areaID, action.x, action.y);

        case UPDATE_ROTATING_AREA:
            return updateRotatingArea(state, action.layerID, action.areaID);

        case END_ROTATING_AREA:
            return endRotatingArea(state, action.layerID, action.areaID);

        case ROTATE:
            return rotatearea(state, action.deg);

        case MOVE_AREA:
            return moveArea(state, action.diffX, action.diffY);

        case REMOVE_AREA:
            return removeArea(state, action.layerID, action.areaID);
            
        case RENAME_AREA:   
            return renameArea(state, action.layerID, action.areaID, action.name);

        default:
            return state;
    }
}

function updateSceneAfterRotateArea(state, rotation) {
    let {rotatingSupport, scene} = state;
    let center = rotatingSupport.get('center');
    let layerID = rotatingSupport.get('layerID');
    let areaID = rotatingSupport.get('areaID');
    let angleBeforeRotate = rotatingSupport.get('angleBeforeRotate');
    let layer = state.scene.layers.get(layerID);
    let area = layer.areas.get(areaID);
    let prevAngle = rotatingSupport.get('angle');
    let rotateDirection = rotatingSupport.get('rotateDirection');

    let vertices = area.vertices.map((v) => rotateVertex(layer.vertices.get(v), center, rotation - prevAngle));
    let camera = area.get('properties').get('scene').get('camera');
    // let pos = rotateVertex(camera, center, rotation - prevAngle);

    scene = scene.updateIn(['layers', scene.selectedLayer], layer => layer.withMutations(layer => {
        vertices.forEach((vertice) => layer.updateIn(['vertices', vertice.get('id')], vertex => vertex.merge(vertice)));
        // layer.updateIn(['areas', areaID, 'properties', 'scene', 'camera'], camera => camera.merge({
        //     x: pos.get('x'),
        //     y: pos.get('y')
        // }));
        layer.setIn(['areas', areaID, 'angle'], rotation);
        layer.updateIn(['areas', areaID, 'properties', 'scene'], scene => scene.merge({rotateDirection: rotateDirection - (deg2rad(angleBeforeRotate) - deg2rad(rotation))}));
    }))

    return scene;
}

function beginRotatingArea(state, layerID, areaID, x, y) {
    let layer = state.scene.layers.get(layerID);
    let area = layer.areas.get(areaID);
    let rotateDirection = area.get('properties').get('scene').get('rotateDirection');
    let vertices = area.vertices.map((v) => getVertexRenderCoords(layer.vertices.get(v), UNIT_CENTIMETER));
    let points = {};
    let verticesIds = area.vertices.toJS();
    verticesIds.forEach((vertice) => {
        let ver = layer.vertices.get(vertice);
        points[ver.id] = {x: ver.x, y: ver.y};
    })
    let centroid = Geometry.getCentroid(Object.values(points));
    let center = [centroid.x, centroid.y]
    let camera = area.get('properties').get('scene').get('camera');
    let angle = area.get('angle') || 0;
    return state.merge({
        mode: MODE_ROTATING_AREA,
        rotatingSupport: new Map({
            layerID,
            areaID,
            angle,
            angleBeforeRotate: angle,
            rotateDirection,
            center,
            vertices,
            camera,
        })
    })
}

function updateRotatingArea(state, x, y) {
    let {rotatingSupport} = state;
    let center = rotatingSupport.get('center');
    let rotation = calculateRotationAngle([x, y], center);
    let scene = updateSceneAfterRotateArea(state, rotation);

    return state.merge({
        scene: scene,
        rotatingSupport: rotatingSupport.set('angle', rotation),
    });
}

function endRotatingArea(state, x, y) {
    let {scene} = state;

    return state.merge({
        mode: MODE_IDLE,
    });
}

function selectArea(state, layerID, areaID, shiftKey) {
    let scene = state.scene;

    scene = scene.merge({
        layers: scene.layers.map(unselectAll),
        selectedLayer: layerID,
    });

    scene = scene.updateIn(['layers', layerID], layer => layer.withMutations(layer => {
            let area = layer.getIn(['areas', areaID]);

            if(shiftKey){
                let connections = area.get('connections');
                if(List.isList(connections)) connections = connections.toJS();

                if(connections && connections.length){
                    connections.map((areaID) => {
                        select(layer, 'areas', areaID);
                    })
                }
            }
          
            select(layer, 'areas', areaID);
            area.vertices.forEach(vertexID => select(layer, 'vertices', vertexID));
        })
    );
    let layer = state.scene.layers.get(layerID);
    let vertices = layer.vertices;
    let area = layer.areas.get(areaID);
    let verticesIds = area.vertices.toJS();

    let pointsForRotate = {};
    verticesIds.forEach((vertice) => {
        let ver = vertices.get(vertice);
        pointsForRotate[ver.id] = {x: ver.x, y: ver.y};
    })
    let camera = area.get('properties').get('scene').get('camera');
    let rotateDirection = area.get('properties').get('scene').get('rotateDirection');
    let rotatingSupport = createRotationSupport(state, layerID, areaID);

    return state.merge({
        scene,
        draggingSupport: Map({
            pointsForRotate,
            camera,
            layerID,
            areaID,
            rotateDirection,
            type: 'area'
        }),
        rotatingSupport,
    })
}

function putMergeAreas(state, layerID, selectedAreas, values) {
    let scene = state.scene;

    selectedAreas.forEach((areaID) => {
        const mergeAreas = scene.getIn(['layers', layerID, 'mergeAreas']);
        let existAreas = null;

        for(let i in mergeAreas.toJS()){
            if(mergeAreas.get(i).connections.includes(areaID)) {
                existAreas = i;
                break ;
            }
        }

        if(existAreas) {
            scene = scene.updateIn(['layers', layerID, 'mergeAreas'], (mergeAreas) => {
                let mergeArea = mergeAreas.getIn([existAreas]);
                if(mergeArea instanceof Record) mergeArea = mergeArea.toJS();

                return mergeAreas.updateIn([existAreas], () => {
                    return {
                        ...mergeArea,
                        ...values
                    }
                });
            });
        }
    })

    return state.merge({
        scene,
    })
}

function mergeAreas(state, layerID, selectedAreas, values) {
    let scene = state.scene;

    scene = scene.merge({
        layers: scene.layers.map(unselectAll),
        selectedLayer: layerID,
    });

    selectedAreas.forEach((areaID) => {
        const mergeAreas = scene.getIn(['layers', layerID, 'mergeAreas']);
        const existAreas = mergeAreas.filter(({connections}) => connections.includes(areaID));

        if(existAreas.size) {
            for (const entry of existAreas.entries()) {
                const [key] = entry;
                scene = scene.deleteIn(['layers', layerID, 'mergeAreas', key]);
              }
        }
    })

    scene = scene.updateIn(['layers', layerID, 'mergeAreas'], mergeAreas => mergeAreas.withMutations(mergeAreas => {
        mergeAreas = mergeAreas.set(shortid.generate(),  {
            ...values,
            label: values.label || 'Merge areas',
            connections: selectedAreas.toJS()
        })
    }));

    return state.merge({
        scene
    })
}

function selectAreas(state, layerID, areaID) {
    let scene = state.scene;

    scene = scene.merge({
        selectedLayer: layerID,
    });

    scene = scene.updateIn(['layers', layerID], layer => layer.withMutations(layer => {
            let area = layer.getIn(['areas', areaID]);
            const listOfSelectedAreas = layer.getIn(['selected', 'areas']);

            if(listOfSelectedAreas.includes(areaID)) {
                unselect(layer, 'areas', areaID);
            }else{

                let connections = area.get('connections');
                if(List.isList(connections)) connections = connections.toJS();
                
                if(connections && connections.length){
                    connections.map((areaID) => {
                        if(!layer.getIn(['areas', areaID, 'selected'])){
                            select(layer, 'areas', areaID);
                        }
                    })
                }
                select(layer, 'areas', areaID);
                area.vertices.forEach(vertexID => select(layer, 'vertices', vertexID));
            }
        })
    );

    return state.merge({
        scene
    })
}


function createRotationSupport(state, layerID, areaID) {
    let layer = state.scene.layers.get(layerID);
    let area = layer.areas.get(areaID);
    let rotateDirection = area.get('properties').get('scene').get('rotateDirection');
    let vertices = area.vertices.map((v) => getVertexRenderCoords(layer.vertices.get(v), UNIT_CENTIMETER));
    let points = {};
    let verticesIds = area.vertices.toJS();
    verticesIds.forEach((vertice) => {
        let ver = layer.vertices.get(vertice);
        points[ver.id] = {x: ver.x, y: ver.y};
    })
    let centroid = Geometry.getCentroid(Object.values(points));
    let center = [centroid.x, centroid.y]
    let camera = area.get('properties').get('scene').get('camera');
    let angle = area.get('angle') || 0;

    return new Map({
        layerID,
        areaID,
        angle,
        angleBeforeRotate: angle,
        rotateDirection,
        center,
        vertices,
        camera,
    })

}

function rotatearea(state, rotation) {
    let {rotatingSupport} = state;
    let scene = updateSceneAfterRotateArea(state, rotation);

    return state.merge({
        scene: scene,
        rotatingSupport: rotatingSupport.set('angle', rotation),
    });

}

function beginScalingArea(state, layerID, lineID, x, y) {
    let layer = state.scene.layers.get(layerID);
    let verticesId = layer.lines.get(lineID).vertices.get(0);
    let vertices = layer.vertices;
    let areaId = layer.vertices.get(verticesId).areas.get(0);
    let area = layer.areas.get(areaId);
    let verticesIds = area.vertices.toJS();

    let line = layer.lines.get(lineID);

    let vertex0 = layer.vertices.get(line.vertices.get(0));
    let vertex1 = layer.vertices.get(line.vertices.get(1));

    if (vertex1.x > vertex0.x) {
        let temp = vertex1;
        vertex1 = vertex0;
        vertex0 = temp;
    }

    let points = {};
    verticesIds.forEach((vertice) => {
        let ver = vertices.get(vertice);
        points[ver.id] = {x: ver.x, y: ver.y};
    })

    let rotateDirection = area.get('properties').get('scene').get('rotateDirection');

    return state.merge({
        mode: MODE_SCALING_AREA,
        draggingSupport: Map({
            layerID,
            areaID: areaId,
            vertexID: verticesId,
            startPointX: x,
            startPointY: y,
            points,
            startVertex0X: vertex0.x,
            startVertex0Y: vertex0.y,
            startVertex1X: vertex1.x,
            startVertex1Y: vertex1.y,
            rotateDirection,
            type: 'area'
        })
    })
}

function updateScalingArea(state, x, y) {
    let draggingSupport = state.draggingSupport;

    let layerID = draggingSupport.get('layerID');
    let areaID = draggingSupport.get('areaID');
    let diffX = x - draggingSupport.get('startPointX');
    let diffY = y - draggingSupport.get('startPointY');
    let newVertex0X = draggingSupport.get('startVertex0X') + diffX;
    let newVertex0Y = draggingSupport.get('startVertex0Y') + diffY;
    let points = draggingSupport.get('points');

    let layer = state.scene.layers.get(layerID);
    let areaCurrent = layer.areas.get(areaID);
    let verticesIds = areaCurrent.vertices.toJS();

    let centroid = Geometry.getCentroid(Object.values(points));
    let dist = Geometry.pointsDistance(draggingSupport.get('startVertex0X'), draggingSupport.get('startVertex0Y'), centroid.x, centroid.y);
    let distAfter = Geometry.pointsDistance(newVertex0X, newVertex0Y, centroid.x, centroid.y);

    let scale = 1 - (dist - distAfter) / distAfter;
    if (Math.sign(scale) == -1) scale *= -1;
    let pointsAfterChange = [];

    let d = Geometry.pointsDistance(points[verticesIds[0]].x, points[verticesIds[0]].y, centroid.x, centroid.y);
    let big_d = d * scale + THICKNESS;
    let scaleAfter = big_d / d;

    verticesIds.forEach((vertex) => {
        pointsAfterChange.push({
            x: (((points[vertex].x - centroid.x) * scale + centroid.x) - centroid.x) * scaleAfter + centroid.x,
            y: (((points[vertex].y - centroid.y) * scale + centroid.y) - centroid.y) * scaleAfter + centroid.y
        })
    })
    const scene = state.scene;

    return state.merge({
        scene: scene.updateIn(['layers', layerID], layer => layer.withMutations(layer => {
            verticesIds.forEach((vertice) => {
                layer.updateIn(['vertices', vertice], vertex => vertex.merge(
                    {
                        x: (points[vertex.id].x - centroid.x) * scale + centroid.x,
                        y: (points[vertex.id].y - centroid.y) * scale + centroid.y
                    }));
            })

            return layer;
        }))
    });
}

function removeMergeAreas(state, layerID, selectedAreas){
    let scene = state.scene;

    scene = scene.merge({
        layers: scene.layers.map(unselectAll),
        selectedLayer: layerID,
    });
    selectedAreas.forEach((areaID) => {
        const mergeAreas = scene.getIn(['layers', layerID, 'mergeAreas']);
        const existAreas = mergeAreas.filter(({connections}) => connections.includes(areaID));

        if(existAreas.size) {
            for (const entry of existAreas.entries()) {
                const [key] = entry;
                scene = scene.deleteIn(['layers', layerID, 'mergeAreas', key]);
              }
        }
    })
    
    return state.merge({
        scene
    })
}

function beginScalingAreaVertex(state, layerID, vertexID, x, y) {
    let layer = state.scene.layers.get(layerID);
    let vertices = layer.vertices;
    let areaId = layer.vertices.get(vertexID).areas.get(0);
    let area = layer.areas.get(areaId);
    let verticesIds = area.vertices.toJS();

    let vertex0 = layer.vertices.get(vertexID);

    let points = {};
    verticesIds.forEach((vertice) => {
        let ver = vertices.get(vertice);
        points[ver.id] = {x: ver.x, y: ver.y};
    })

    let camera = area.get('properties').get('scene').get('camera');
    let rotateDirection = area.get('properties').get('scene').get('rotateDirection');

    return state.merge({
        mode: MODE_SCALING_AREA,
        draggingSupport: Map({
            layerID,
            vertexID,
            areaID: areaId,
            startPointX: x,
            startPointY: y,
            points,
            camera,
            startVertex0X: vertex0.x,
            startVertex0Y: vertex0.y,
            rotateDirection
        })
    })
}

let maxX = 0, maxY = 0;

function beginDraggingArea(state, layerID, areaID, x, y) {
    maxX = 0;
    maxY = 0;
    let layer = state.scene.layers.get(layerID);
    let vertices = layer.vertices;
    let area = layer.areas.get(areaID);
    let verticesIds = area.vertices.toJS();

    let points = {};
    verticesIds.forEach((vertice) => {
        let ver = vertices.get(vertice);
        points[ver.id] = {x: ver.x, y: ver.y};
    })

    let rotateDirection = area.get('properties').get('scene').get('rotateDirection');
    let snapElements = sceneSnapElements(state.scene, new List(), state.snapMask, layerID, areaID);
    let linesLayers = layer.lines.sort((line1, line2) => {
        return line1.holes.size - line2.holes.size;
    });
    let verticesArea = area.get('vertices');
    let lines = linesLayers.filter(line => verticesArea.indexOf(line.get('vertices').get(0)) !== -1 && verticesArea.indexOf(line.get('vertices').get(1)) !== -1)

    return state.merge({
        mode: MODE_DRAGGING_AREA,
        draggingSupport: Map({
            layerID, areaID,
            startPointX: x,
            startPointY: y,
            points,
            pointsForRotate: points,
            rotateDirection,
            type: 'area',
            snapElements,
            lines,
        })
    })
}

function moveArea(state, diffX, diffY) {
    let draggingSupport = state.draggingSupport;
    let layerID = draggingSupport.get('layerID');
    let areaID = draggingSupport.get('areaID');
    let scene = state.scene;

    if (layerID && areaID) {
        let selected = scene.layers.get(layerID).areas.get(areaID).get('selected');
        if (!selected) return state.merge({scene: scene});
        let area = scene.layers.get(layerID).areas.get(areaID);

        scene = scene.updateIn(['layers', layerID], layer => layer.withMutations(layer => {
            let linesLayers = layer.lines.sort((line1, line2) => {
                return line1.holes.size - line2.holes.size;
            });
            let verticesArea = area.get('vertices');
            let lines = linesLayers.filter(line => verticesArea.indexOf(line.get('vertices').get(0)) !== -1 && verticesArea.indexOf(line.get('vertices').get(1)) !== -1)

            lines.forEach(line => {
                let vertices0 = layer.vertices.get(line.get('vertices').get(0));
                let vertices1 = layer.vertices.get(line.get('vertices').get(1));
                let {x: newVertex0X, y: newVertex0Y} = {x: vertices0.get('x') + diffX, y: vertices0.get('y') + diffY};
                let {x: newVertex1X, y: newVertex1Y} = {x: vertices1.get('x') + diffX, y: vertices1.get('y') + diffY};

                layer.updateIn(['vertices', vertices0.get('id')], vertex => vertex.merge({
                    x: newVertex0X,
                    y: newVertex0Y
                }));
                layer.updateIn(['vertices', vertices1.get('id')], vertex => vertex.merge({
                    x: newVertex1X,
                    y: newVertex1Y
                }));
            })

            layer.updateIn(['areas', areaID, 'properties', 'scene', 'camera'], camera => camera.merge({
                x: cameraVertexX,
                y: cameraVertexY
            }));

            return layer;
        }))
    }


    return state.merge({
        scene: scene,
    });
}

function updateDraggingArea(state, x, y) {
    let draggingSupport = state.draggingSupport;
    let layerID = draggingSupport.get('layerID');
    let areaID = draggingSupport.get('areaID');
    let diffX = x - draggingSupport.get('startPointX');
    let diffY = y - draggingSupport.get('startPointY');
    let points = draggingSupport.get('points');
    let snapElements = null;
    if (isSnapActive(state.scene.get('layers'))) {
        snapElements = draggingSupport.get('snapElements');
    }
    let lines = draggingSupport.get('lines');
    let scene = state.scene;

    let snap = null;

    let activeSnapElement = [];
    let curSnap0 = null, curSnap1 = null;

    scene = scene.updateIn(['layers', layerID], layer => layer.withMutations(layer => {
        let deltaX = 0, deltaY = 0;
        lines.forEach(line => {
            let vertices0 = layer.vertices.get(line.get('vertices').get(0));
            let vertices1 = layer.vertices.get(line.get('vertices').get(1));
            let {x: newVertex0X, y: newVertex0Y} = {
                x: points[vertices0.get('id')].x + diffX,
                y: points[vertices0.get('id')].y + diffY
            };
            let {x: newVertex1X, y: newVertex1Y} = {
                x: points[vertices1.get('id')].x + diffX,
                y: points[vertices1.get('id')].y + diffY
            };
            if (isSnapActive(state.scene.get('layers'))) {
                if (state.snapMask && !state.snapMask.isEmpty()) {
                    curSnap0 = nearestSnap(snapElements, newVertex0X, newVertex0Y, state.snapMask);
                    curSnap1 = nearestSnap(snapElements, newVertex1X, newVertex1Y, state.snapMask);
                }
            }
            if (curSnap0 && curSnap1) {
                if (curSnap0.point.distance < curSnap1.point.distance) {
                    deltaX = curSnap0.point.x - newVertex0X;
                    deltaY = curSnap0.point.y - newVertex0Y;
                    activeSnapElement.push(curSnap0.snap);
                } else {
                    deltaX = curSnap1.point.x - newVertex1X;
                    deltaY = curSnap1.point.y - newVertex1Y;
                    activeSnapElement.push(curSnap1.snap);
                }
            } else {
                if (curSnap0) {
                    deltaX = curSnap0.point.x - newVertex0X;
                    deltaY = curSnap0.point.y - newVertex0Y;
                    activeSnapElement.push(curSnap0.snap);
                }
                if (curSnap1) {
                    deltaX = curSnap1.point.x - newVertex1X;
                    deltaY = curSnap1.point.y - newVertex1Y;
                    activeSnapElement.push(curSnap1.snap);
                }
            }
        })
        lines.forEach(line => {
            let vertices0 = layer.vertices.get(line.get('vertices').get(0));
            let vertices1 = layer.vertices.get(line.get('vertices').get(1));
            let {x: newVertex0X, y: newVertex0Y} = {
                x: points[vertices0.get('id')].x + diffX,
                y: points[vertices0.get('id')].y + diffY
            };
            let {x: newVertex1X, y: newVertex1Y} = {
                x: points[vertices1.get('id')].x + diffX,
                y: points[vertices1.get('id')].y + diffY
            };

            newVertex0X += deltaX;
            newVertex0Y += deltaY;
            newVertex1X += deltaX;
            newVertex1Y += deltaY;

            layer.updateIn(['vertices', vertices0.get('id')], vertex => vertex.merge({x: newVertex0X, y: newVertex0Y}));
            layer.updateIn(['vertices', vertices1.get('id')], vertex => vertex.merge({x: newVertex1X, y: newVertex1Y}));
            layer.updateIn(['vertices', vertices1.get('id')], vertex => vertex.merge({timestamp: new Date().getTime()}));
        })

        return layer;
    }))

    return state.merge({
        activeSnapElement,
        scene: scene,
    });
}

function endDraggingArea(state, x, y) {
    let draggingSupport = state.draggingSupport;
    draggingSupport = draggingSupport.setIn(['pointsForRotate'], updatePointForRotation(state))
        .setIn(['camera'], updateCameraPosition(state));
    let layerID = draggingSupport.get('layerID');
    let areaID = draggingSupport.get('areaID');
    let rotatingSupport = createRotationSupport(state, layerID, areaID);
   
    return state.merge({
        mode: MODE_IDLE,
        activeSnapElement: [],
        rotatingSupport,
        draggingSupport
    });
}

function endScalingArea(state, x, y) {
    let draggingSupport = state.draggingSupport;
    draggingSupport = draggingSupport.setIn(['pointsForRotate'], updatePointForRotation(state))
        .setIn(['camera'], updateCameraPosition(state));
    let {scene} = state;

    return state.merge({
        mode: MODE_IDLE,
        scene,
        draggingSupport
    });
}

function removeArea(state, layerID, areaID){
    let scene = state.scene;
    const vertices = scene.getIn(['layers', layerID, 'areas', areaID, 'vertices']);
    const ids = [];

    vertices.forEach((vertice) => {
        const lines = scene.getIn(['layers', layerID, 'vertices', vertice, 'lines']);
        ids.push(...lines.toJS());
        scene = scene.deleteIn(['layers', layerID, 'vertices', vertice]);
    })
    new Set(ids).forEach((id) => {
        scene = scene.deleteIn(['layers', layerID, 'lines', id]);
    })
    scene = scene.deleteIn(['layers', layerID, 'areas', areaID]);

    return state.merge({
        scene
    })
}

function renameArea(state, layerID, areaID, name){
    let scene = state.scene;
    scene = scene.setIn(['layers', layerID, 'areas', areaID, 'name'], name);

    return state.merge({
        scene
    })
}