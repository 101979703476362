import {request} from "./api";
import {getItem, setItem, getFloorplanItems} from '../helpers/localStorage';
import {FLOORPLAN_NAME, TOUR_ID} from '../constants';
import {generateUUID} from '../helpers/uuid';
import FloorplanManager from '../helpers/floorplanManager';

export const create = (userID, name) => {
    const layer = createEmptyTour();
    const data = {};
    const prefix = `floorplan_${userID}`;
    data.floorplan_json = layer;
    data.id = generateUUID();
    data.name = name;

    setItem(TOUR_ID, data.id);
    setItem(`${prefix}_${data.id}`, data);

    return updateFloorplans(userID);
};
  
export const get = (userID) => {  
  return request(`/p/user-data.pl?userID=${userID}`, {
    method: 'GET'
  });
};

export const updateFloorplans = (userID) => {
    const prefix = `floorplan_${userID}`;
    const floorplans = getFloorplanItems(prefix);
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('data', JSON.stringify(floorplans));

    return request(`/p/user-data.pl`, {
        method: 'POST',
        body: formData
    });
}

export const updateAllFloorplans = (userID, floorplans) => {
    floorplans.forEach((floorplan) => updateLocalStorage(userID, floorplan));
}

export const updateLocalStorage = (userID, obj={}) => {
    const prefix = `floorplan_${userID}`;
    const id = `${prefix}_${obj.id}`;
    const data = getItem(id);

    setItem(id, {
        ...data,
        ...obj
    })
}

export const update = (userID, obj={}) => {
    updateLocalStorage(userID, obj);

    return updateFloorplans(userID);
};

export const deleteFloorplan = (id) => {
    return request(`/floorplan/${id}`, {
        method: 'DELETE'
    });
};

export const createEmptyTour = () => {
    const layout = new FloorplanManager();
    const layer = layout.getClearFloorplan();
    setItem(FLOORPLAN_NAME, layer);

    return layer;
}