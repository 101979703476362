exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.scan-page {\n  width: 100vw;\n  height: 100vh; }\n  .scan-page iframe {\n    width: 100%;\n    height: 100%; }\n", ""]);

// exports
exports.locals = {
	"scan-page": "scan-page"
};