import React from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
import loading from '../../assets/loading.gif';
import './style.scss';

const WIDTH = {
    waiting: '0%',
    sending: '66%',
    completed: '100%',
    failed: '100%',
};

const UploadTool = ({ queue, progress, setShow, removeTaskAfterDelay }) => {
    return (
        <div className="upload-tool">
            <div className="upload-tool--title">
                <span>Adding New Room</span> {progress !== 100 ? <img src={loading} alt="loading" /> : ''}
            </div>
            <div className="upload-tool--close" onClick={() => setShow(false)}>
                <MdClose />
            </div>
            <div className="upload-tool--total">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
            {queue.some(({ status }) => status === 'failed') && (
                <div className="upload-tool--error">
                    {queue
                        .filter(({ status }) => status === 'failed')
                        .map(({ id, error }) => (
                            <div key={id}>{error}</div>
                        ))}
                </div>
            )}
            {queue
                .map(({ id, title, status, progress, repeat }) => (
                    <div key={id} className="upload-tool--block">
                        <div className="upload-tool--text">{title}</div>
                        <div className="upload-tool--progress">
                            <span
                                style={{
                                    background:
                                        status === 'waiting'
                                            ? '#ffffff'
                                            : status === 'sending'
                                            ? '#4e4e4e'
                                            : status === 'completed'
                                            ? '#008000'
                                            : '#ee2f29',
                                    width: `${progress}%` || WIDTH[status],
                                }}
                            ></span>
                        </div>
                    </div>
                ))}
        </div>
    );
};

UploadTool.propTypes = {
    queue: PropTypes.array.isRequired,
    progress: PropTypes.number.isRequired,
    setShow: PropTypes.func.isRequired,
    removeTaskAfterDelay: PropTypes.func.isRequired,
};

export default UploadTool;
