exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sidebar {\n  padding: 0 0 10px;\n  width: 100%;\n  overflow: hidden;\n  position: absolute;\n  height: 100%;\n  right: 0; }\n  .sidebar .header {\n    width: 100%;\n    padding: 8px 10px 20px;\n    color: #ffffff;\n    background-color: #323a40; }\n    .sidebar .header > h3 {\n      margin: 0;\n      padding: 25px 10px 5px;\n      text-transform: capitalize; }\n    .sidebar .header-wrapper-modes-toggle {\n      width: 100%; }\n      .sidebar .header-wrapper-modes-toggle .btn-group {\n        display: flex; }\n        .sidebar .header-wrapper-modes-toggle .btn-group .btn {\n          flex-grow: 1; }\n  .sidebar .body {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    position: relative;\n    overflow-y: auto;\n    height: 100%;\n    padding-bottom: 0; }\n  .sidebar .list-element {\n    width: 100%;\n    display: inline-block;\n    padding: 5px 10px 0;\n    color: #ffffff;\n    line-height: 20px; }\n  .sidebar .appended-header {\n    padding: 0px 10px 10px;\n    background-color: #323a40; }\n", ""]);

// exports
exports.locals = {
	"sidebar": "sidebar",
	"header": "header",
	"header-wrapper-modes-toggle": "header-wrapper-modes-toggle",
	"btn-group": "btn-group",
	"btn": "btn",
	"body": "body",
	"list-element": "list-element",
	"appended-header": "appended-header"
};