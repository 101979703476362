import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LayersManagerList from './list';
import LayersManagerEditor from './editor';

import s from './layers-maneger.scss';

export default class LayersManager extends Component {
  constructor(props) {
    super(props);
  }

  addLayer(e) {
    if (e) e.preventDefault();
    const {selectedLayer} = this.props.scene;
    const name = 'New Layer';
    this.context.messageActions.addLayer(name, selectedLayer);
  }

  render() {
    const {scene} = this.props;
    const {layers, selectedLayer} = scene;
    return <div className={s['layers-manager']} tabIndex="0">
      <div className="layers-manager-header form-group-row form-group">
        <label className="form-group-label">Floors</label>
        <div className="form-group-field field-measure">
          <button className="btn btn-block btn-sm white"
                  onClick={e => {e.stopPropagation(); this.addLayer(e);}}> Add <i className="fa fa-plus"/>
          </button>
        </div>
      </div>
      <div className={s['layers-manager-wrapper']}>
        <LayersManagerList scene={scene}/>
        <LayersManagerEditor
          layerSize={layers.size}
          scene={scene}
          layer={selectedLayer}/>
      </div>
    </div>
  }
}

LayersManager.contextTypes = {
  messageActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  sceneActions: PropTypes.object.isRequired,
};
