exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".loading-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 1000; }\n\n.loader {\n  width: 50px;\n  height: 50px;\n  border: 5px solid rgba(255, 255, 255, 0.3);\n  border-top: 5px solid #ffffff;\n  border-radius: 50%;\n  animation: spin 1s linear infinite; }\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n", ""]);

// exports
exports.locals = {
	"loading-overlay": "loading-overlay",
	"loader": "loader",
	"spin": "spin"
};