import React, {useContext} from 'react';
import { useHistory } from 'react-router-dom';
import MessageContext from '../../hoc/messages/index';
import './style.scss';

export default ({dimensions, onSaveArea, type, title}) => {
    const history = useHistory();
    const { showWarning } = useContext(MessageContext);

    const onCreateArea = () => {
        for (let i in dimensions) {
            if (!dimensions[i]) {
                return showWarning('All dimension must be not null');
            }
        }
      
        if (!title) {
            return showWarning('Title must be provided');
        }

        onSaveArea();
        if (!title) {
            return showWarning('Title must be provided');
        }
        history.push(`/`);
        history.push(`/planner`);
    }

    const onScanRoom = () => {
        history.push(`/scan/${type}?title=${title}`);
    }

    return (
        <section className="template-action">
            <div className="template-action--container">
                <div className="action">
                    <button className='btn btn-primary' onClick={() => onCreateArea()}>Create</button>
                </div>
                <div className="label">
                    <span className='text-bold h4'>or</span>
                </div>
                <div className="scan">
                    <button className='btn btn-primary' onClick={() => onScanRoom('new')}>Scan room</button>
                </div>
            </div>
        </section>
    )
};
