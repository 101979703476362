exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".element-editor {\n  color: #d1d6df; }\n  .element-editor .form-group-label {\n    text-transform: capitalize; }\n  .element-editor .apply_to_all {\n    width: 100%;\n    align-content: center;\n    display: flex;\n    justify-content: space-between;\n    margin: 0;\n    padding: 0; }\n    .element-editor .apply_to_all .form-group-label {\n      width: 100%; }\n  .element-editor-header {\n    background-color: #515d69;\n    color: #ffffff;\n    line-height: 48px;\n    padding: 0 10px;\n    height: 48px; }\n  .element-editor-actions .btn i {\n    font-size: 12px;\n    margin-right: 5px; }\n  .element-editor-actions .btn.save-button {\n    width: 100%; }\n  .element-editor-actions .btn.delete-button {\n    width: 100%; }\n  .element-editor-body {\n    max-height: calc(100% - 42px);\n    padding: 10px 15px 0 15px;\n    overflow-y: auto; }\n    .element-editor-body input[type=checkbox] {\n      height: 16px; }\n\n.save-button {\n  color: #FFF;\n  background-color: #3598dc;\n  border-color: #3598dc; }\n\n.delete-button {\n  color: #fff;\n  background-color: #e7505a;\n  border-color: #e7505a; }\n", ""]);

// exports
exports.locals = {
	"element-editor": "element-editor",
	"form-group-label": "form-group-label",
	"apply_to_all": "apply_to_all",
	"element-editor-header": "element-editor-header",
	"element-editor-actions": "element-editor-actions",
	"btn": "btn",
	"save-button": "save-button",
	"delete-button": "delete-button",
	"element-editor-body": "element-editor-body"
};