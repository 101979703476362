import React from 'react';
import { useHistory } from 'react-router-dom';
import Reactangle from '../../components/templates/rectangle';
import lShapeComponent from '../../components/templates/l-shape';
import uShapeComponent from '../../components/templates/u-shape';
import cShapeComponent from '../../components/templates/c-shape';
import corridorComponent from '../../components/templates/corridor-shape';

const rectangle = require('../../assets/shapes/rectangle.png');
const lShape = require('../../assets/shapes/l-shape.png');
const uShape = require('../../assets/shapes/u-shape.png');
const cShape = require('../../assets/shapes/c-shape.png');
const corridor = require('../../assets/shapes/corridor.png');

import './style.scss';

export const SHAPES = [
    {
        name: 'Rectangle',
        url: rectangle,
        alt: 'Rectangle',
        id: 0,
        component: Reactangle
    },
    {
        name: 'L-shape',
        url: lShape,
        alt: 'L-shape',
        id: 1,
        component: lShapeComponent
    },
    {
        name: 'U-shape',
        url: uShape,
        alt: 'U-shape',
        id: 2,
        component: uShapeComponent
    },
    {
        name: 'Comer Cut',
        url: cShape,
        alt: 'Comer Cut',
        id: 3,
        component: cShapeComponent
    },
    {
        name: 'Corridor',
        url: corridor,
        alt: 'Corridor',
        id: 4,
        component: corridorComponent
    }
]

export default () => {
    const history = useHistory();

    const onMovePage = (id) => {
        history.push(`/edit-room/${id}`);
    }

    return (
        <section className="choose-shape layout">
            {SHAPES.map(({name, url, id}) => (
                <div className="choose-shape--block flex-column align-center" onClick={() => onMovePage(id)}>
                    <img src={url} alt={name} />
                    <span>{name}</span>
                </div>
            ))}
        </section>
    )
};
