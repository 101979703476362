exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".modal-overlay {\n  background-color: rgba(0, 0, 0, 0.5);\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  z-index: 10000; }\n\n.modal-content {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 10001;\n  overflow: auto;\n  text-align: center;\n  overflow-scrolling: touch;\n  background-color: transparent; }\n  .modal-content:after {\n    vertical-align: middle;\n    display: inline-block;\n    height: 100%;\n    margin-left: -.05em;\n    content: ''; }\n  .modal-content-dialog {\n    position: relative;\n    outline: 0;\n    width: 100%;\n    display: inline-block;\n    vertical-align: middle;\n    box-sizing: border-box;\n    max-width: 520px;\n    cursor: default;\n    background-color: #ffffff; }\n  .modal-content-header {\n    padding: 10px;\n    position: relative; }\n    .modal-content-header .modal__close {\n      position: absolute;\n      right: 10px;\n      top: 10px;\n      cursor: pointer; }\n      .modal-content-header .modal__close:hover {\n        color: #3598dc; }\n    .modal-content-header .modal__title {\n      padding: 0 10px; }\n  .modal-content-body {\n    padding: 0 10px 10px; }\n    .modal-content-body-footer {\n      text-align: right; }\n      .modal-content-body-footer button {\n        margin-left: 5px; }\n", ""]);

// exports
exports.locals = {
	"modal-overlay": "modal-overlay",
	"modal-content": "modal-content",
	"modal-content-dialog": "modal-content-dialog",
	"modal-content-header": "modal-content-header",
	"modal__close": "modal__close",
	"modal__title": "modal__title",
	"modal-content-body": "modal-content-body",
	"modal-content-body-footer": "modal-content-body-footer"
};