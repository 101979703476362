exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".image-container {\n  position: relative; }\n\n.default-image {\n  display: block; }\n\n.hover-image {\n  display: none; }\n\n.image-container:hover .default-image {\n  display: none; }\n\n.image-container:hover .hover-image {\n  display: block; }\n", ""]);

// exports
exports.locals = {
	"image-container": "image-container",
	"default-image": "default-image",
	"hover-image": "hover-image"
};