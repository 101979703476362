// src/eventService.js

const EventService = {
    /**
     * Dispatch a custom event.
     * @param {string} eventName - The name of the event.
     * @param {*} detail - Data to pass with the event.
     */
    dispatch(eventName, detail = null) {
      const event = new CustomEvent(eventName, { detail });
      window.dispatchEvent(event);
    },
  
    /**
     * Add an event listener for a specific custom event.
     * @param {string} eventName - The name of the event.
     * @param {Function} callback - Function to call when the event is triggered.
     */
    addListener(eventName, callback) {
      window.addEventListener(eventName, callback);
    },
  
    /**
     * Remove an event listener for a specific custom event.
     * @param {string} eventName - The name of the event.
     * @param {Function} callback - The event handler to remove.
     */
    removeListener(eventName, callback) {
      window.removeEventListener(eventName, callback);
    },
  };
  
  export default EventService;
  