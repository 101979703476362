exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".scene-config-header {\n  background-color: #515d69;\n  color: #ffffff;\n  line-height: 48px;\n  padding: 0 10px;\n  height: 48px; }\n\n.scene-config-actions {\n  padding: 10px; }\n  .scene-config-actions .btn i {\n    font-size: 12px;\n    margin-right: 5px; }\n\n.scene-config-body {\n  padding: 10px 10px 20px; }\n", ""]);

// exports
exports.locals = {
	"scene-config-header": "scene-config-header",
	"scene-config-actions": "scene-config-actions",
	"btn": "btn",
	"scene-config-body": "scene-config-body"
};