var JSZip = require("jszip");

export default class ZipHandler {
    constructor() {}

    convertToObj(floorplan) {
        return {
            floorplan: floorplan[0],
            panorama: floorplan[1],
            floor_xy: floorplan[2],
            coord_2d: floorplan[3],
            coord_px: floorplan[4],
        }
    }

    async unzipFiles(blob) {
        const zip = await JSZip.loadAsync(blob);
        const promises = Object.keys(zip.files).map(async (filename) => {
            const blob = await zip.files[filename].async('blob');
            let data = URL.createObjectURL(blob);
            const type = filename.match(/\.txt$/) ? 'txt' : 'img';
            if (type === 'txt') {
                data = await this.parseTxtFile(blob);
            }
            return {
                name: filename,
                data,
                type,
            };
        });

        const floorplan = await Promise.all(promises);
        
        return this.convertToObj(floorplan);
    }

    parseTextToArray(text) {
        const lines = text.trim().split('\n');
        const array = lines.map((line) => {
            return line.trim().split(/\s+/).map(Number);
        });

        return array;
    }

    parseTxtFile(blob) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve(this.parseTextToArray(event.target.result));
                };
                reader.onerror = (event) => {
                    console.error("Error reading the Blob:", event);
                    reject(event);
                };
                reader.readAsText(blob);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
}