import { getItem, setItem } from './localStorage';
import { FLOORPLAN_NAME } from '../constants';

export default class FloorplanManager {
    constructor() {
        this._floorplan = {
            unit: "cm",
            layers: {
                "layer-1": {
                    id: "layer-1",
                    altitude: 0,
                    order: 0,
                    opacity: 1,
                    name: "default",
                    visible: true,
                    vertices: {},
                    lines: {},
                    holes: {},
                    areas: {},
                    items: {},
                    selected: {
                        vertices: [],
                        lines: [],
                        holes: [],
                        areas: [],
                        items: []
                    }
                }
            },
            fit: true,
            grids: {
                h1: {
                    id: "h1",
                    type: "horizontal-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"]
                    }
                },
                v1: {
                    id: "v1",
                    type: "vertical-streak",
                    properties: {
                        step: 20,
                        colors: ["#808080", "#ddd", "#ddd", "#ddd", "#ddd"]
                    }
                }
            },
            selectedLayer: "layer-1",
            groups: {},
            width: 1000,
            height: 1000,
            meta: {},
            guides: {
                horizontal: {},
                vertical: {},
                circular: {}
            }
        };

        this._layerId = "layer-1";
        this.RADIAN = Math.PI / 180;
    }

     translate2DPoint([x, y], [xOffset, yOffset]) {
        return [x + xOffset, y + yOffset];
    }

    translate2DPoints(points, translation) {
        let res = {};
        for (let i in points) {
            if(points.hasOwnProperty(i)) {
                res[i] = this.translate2DPoint(points[i], translation);
            }
        }
        return res;
    }

    translate2DArea(areaId, translation) {
        console.log('translation', translation)
        const layer = this._floorplan.layers[this._layerId];
        const area = layer.areas[areaId];
        const verticesIds = area.vertices;
        verticesIds.forEach((verticesId) => {
            const {x, y} = layer.vertices[verticesId];
            const point = this.translate2DPoint([x, y], translation);
            layer.vertices[verticesId].x = point[0];
            layer.vertices[verticesId].y = point[1];
        })
        // area.holes.forEach(hole => {
        //     hole.coord = this.translate2DPoint(hole.coord, translation);
        // });
        const current = layer.areas[area.id];
        layer.areas[area.id] = {
            ...current, 
            ...area
        }

        return area;
    }

    rotate2DArea(areaId, angle, origin=false) {
        const layer = this._floorplan.layers[this._layerId];
        const area = layer.areas[areaId];
        area.angle = angle;
        angle = this.normalizeYaw(angle * this.RADIAN);
        const verticesIds = area.vertices;
        verticesIds.forEach((verticesId) => {
            const {x, y} = origin ? layer.vertices[verticesId].origin : layer.vertices[verticesId];
            const point = this.rotate2DPoint([x, y], angle);
            layer.vertices[verticesId].x = point[0];
            layer.vertices[verticesId].y = point[1];
        })

        area.holes.forEach(hole => {
            hole.coord = this.rotate2DPoint(hole.coord, angle);
            hole.angle += angle;
        });

        const current = layer.areas[area.id];
        layer.areas[area.id] = {
            ...current, 
            ...area
        }

        return area;
    }

    findMidpoint(point1, point2) {
        const {x: x0, y: y0} = point1;
        const {x: x1, y: y1} = point2;
    
        const midpoint = [
            (x0 + x1) / 2,
            (y0 + y1) / 2  
        ];
    
        return midpoint;
    }

    resetAreaAngle(areaId, angle) {
        const layer = this._floorplan.layers[this._layerId];
        const area = layer.areas[areaId];
        area.angle = angle;
    }

    rotateArea(areaId, angle, origin=false) {
        const layer = this._floorplan.layers[this._layerId];
        const area = layer.areas[areaId];
        area.angle += angle;
        // angle = this.normalizeYaw(angle * this.RADIAN);

        const verticesIds = area.vertices;
        const points = verticesIds.map((verticesId) => {
            const {x, y} = origin ? layer.vertices[verticesId].origin : layer.vertices[verticesId];
            
            return {
                x, 
                y
            }
        })

        const center = this.getCentroid(points);

        verticesIds.forEach((verticesId) => {
            const {x, y} = layer.vertices[verticesId].origin;
            const point = this.rotateVertex({x, y}, [center.x, center.y], area.angle);
            layer.vertices[verticesId].x = point.x;
            layer.vertices[verticesId].y = point.y;
        })

        // area.holes.forEach(hole => {
        //     // hole.coord = this.rotate2DPoint(hole.coord, angle);
        //     hole.angle += angle;
        // });
    }

    rotateVertex({x, y}, center, angle) {
        let dX = x - center[0];
        let dY = y - center[1];
        let r = Math.sqrt(dX * dX + dY * dY);
        let selfAngle = Math.atan2(dY, dX) * 180 / Math.PI;

        return {
            x: r * Math.cos((selfAngle + angle) * Math.PI / 180) + center[0],
            y: r * Math.sin((selfAngle + angle) * Math.PI / 180) + center[1]
        };
    }
    
    getCentroid (points) {
        let l = points.length;
    
        return points.reduce(function(cenr, p, i) {
            cenr.x += p.x;
            cenr.y += p.y;
    
            if(i === l - 1) {
                cenr.x /= l;
                cenr.y /= l;
            }
    
            return cenr;
        }, {x: 0, y: 0});
    }

    rotate2DPoints(points, angle) {
        let res = {};
        for (let i in points) {
            if (points.hasOwnProperty(i))
                res[i] = this.rotate2DPoint(points[i], angle);
        }
        return res;
    }

    rotate2DPoint([x, y], angle) {
        let dist = Math.sqrt(x * x + y * y);
        angle += Math.atan2(y, x);
        return [Math.cos(angle) * dist, Math.sin(angle) * dist];
    }

    getClearFloorplan() {
        return this._floorplan;
    }

    getLayer(id = this._layerId) {
        return this._floorplan.layers[id];
    }

    createArea(vertices, coord_2d, name, shape, areaId=this.generateRandomId()) {
        const layer = this.getLayer(this._layerId);
        const dimensionPadding = 5;
        vertices = this.adjustAndScaleCoordinates(vertices, dimensionPadding);

        let vertexIds = vertices.map(() => this.generateRandomId());

        vertexIds.forEach((vertexId, idx) => {
            let [x, y] = vertices[idx];
            let px = coord_2d[idx];
            layer.vertices[vertexId] = this._createVertice({vertexId, name: vertexId, x, y, px});
        });

        for (let i = 0; i < vertexIds.length; i++) {
            let lineId = this.generateRandomId();
            let startVertex = vertexIds[i];
            let endVertex = vertexIds[(i + 1) % vertexIds.length];
            layer.lines[lineId] = this._createLine({lineId, startVertex, endVertex});
            layer.vertices[startVertex].lines.push(lineId);
            layer.vertices[endVertex].lines.push(lineId);
            layer.vertices[endVertex].selected = true;
        }

        layer.areas[areaId] = this._createArea({areaId, vertexIds, name, shape});
        vertexIds.forEach(vertexId => {
            layer.vertices[vertexId].areas.push(areaId);
        });

        layer.selected = {
            ...layer.selected,
            vertices: [...vertexIds],
            areas: areaId
        }
        this.calculateAreaDimensions(100);

        return {layer: JSON.stringify(this._floorplan, null, 4), areaId,  area: layer.areas[areaId]};
    }

    _createHole({
        id,
        lineId,
        offset,
        properties
    }, areaId) {
        let layer = this._floorplan.layers[this._layerId];
        let area = layer.areas[areaId];
        let hole = {
            id,
            misc: {},
            properties: {
                altitude: {length: 0},
                flip_horizontal: false,
                height: {length: 215},
                thickness: {length: 30},
                width: {length: 80},
                ...properties
            },
            name: 'Door',
            visible: true,
            offset: offset,
            line: lineId,
            type: "door",
            selected: false,
            visible: true,
            prototype: "holes"
        };

        return hole;
    }

    _createVertice({vertexId, x, y, px}) {
        return  {
            id: vertexId,
            type: "",
            prototype: "vertices",
            name: "Vertex",
            misc: {},
            selected: false,
            properties: {},
            visible: true,
            x: x,
            y: y,
            lines: [],
            areas: [],
            px,
            origin: {
                x, y
            }
        };
    }

    saveLayout() { 
        setItem(FLOORPLAN_NAME, this._floorplan);
    }

    _createArea({areaId, vertexIds, name='Area', shape=0}) {
        return {
            id: areaId,
            type: "area",
            prototype: "areas",
            name,
            misc: {},
            autoconnect: 0,
            shape: shape,
            angle: 0,
            selected: true,
            properties: {
                patternColor: "#F5F4F4",
                thickness: { length: 0 },
                texture: "none",
                scene: {
                    posePitch: 0, poseRoll: 0
                }
            },
            vertices: vertexIds,
            visible: true,
            holes: [],
            height_lens: 3000,
            camera: [0, 0],
            points: {},
            holes: [],
            coordsEqui: [],
            direction: 0,
            order: 0
        };
    }

    generateRandomId (length = 10) {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    saveAutoConnect(areaId, autoconnect) {
        const layer = this._floorplan.layers[this._layerId];
        const area = layer.areas[areaId];
        area.autoconnect = autoconnect;
    }

    addHole({lineId, offset=10, properties= {}}, areaId) {
        const id = this.generateRandomId();

        const hole = this._createHole({
            id,
            lineId,
            offset,
            properties
        }, areaId);

        const layer = this.getLayer(this._layerId);
        layer.holes[id] = hole;
        layer.lines[lineId].holes.push(id);
        layer.areas[areaId].holes.push(id);

        return hole;
    }

    _createLine({lineId, startVertex, endVertex}) {
        return  {
            id: lineId,
            type: "wall",
            prototype: "lines",
            name,
            misc: {},
            selected: false,
            properties: {
                height: { length: 300 },
                thickness: { length: 20 },
                textureA: "bricks",
                textureB: "bricks"
            },
            visible: true,
            vertices: [startVertex, endVertex],
            holes: []
        };
    }

    adjustAndScaleCoordinates(vertices, padding = 10) {
        // Convert the coordinates to an array of arrays
        vertices = vertices.map(coord => Array.from(coord));

        // Find the minimum x and y values
        let minX = Math.min(...vertices.map(coord => coord[0]));
        let minY = Math.min(...vertices.map(coord => coord[1]));

        // Adjust coordinates to move them all to > 0 with padding
        vertices = vertices.map(coord => [
            coord[0] - minX + padding / 2,
            coord[1] - minY + padding / 2
        ]);

        vertices = vertices.map(coord => [coord[0], coord[1]]);

        return vertices;
    }

    calculateAreaDimensions(padding = 1000) {
        const vertices = Object.values(this._floorplan.layers["layer-1"].vertices).map(({x, y}) => [x, y])

        let maxX = Math.max(...vertices.map(coord => coord[0]));
        let maxY = Math.max(...vertices.map(coord => coord[1]));
        this._floorplan.width = maxX + padding * 2;
        this._floorplan.height = maxY + padding * 2;
    }

    removeHole(holeId) {
        const layer = this.getLayer(this._layerId);
            
        for(let i in layer.areas) {
            if (layer.areas[i].holes.includes(holeId)) {
                layer.areas[i].holes = layer.areas[i].holes.filter((id) => id !== holeId);
            }
        }    

        for(let i in layer.lines) {
            if (layer.lines[i].holes.includes(holeId)) {
                layer.lines[i].holes = layer.lines[i].holes.filter((id) => id !== holeId);
            }
        }   
        
        for(let i in layer.holes) {
            if (i === holeId) {
                delete layer.holes[i];
            }
        }    
    }

    mergeArea(layers, areaId) {
        const fields = ['areas', 'lines', 'vertices', 'camera'];
        const layout = getItem(FLOORPLAN_NAME);

        const areaIds = Object.keys(layout.layers[this._layerId].areas);
        const order = areaIds.map((id) => layout.layers[this._layerId].areas[id].order).sort().reverse()[0] + 1;

        layers.areas[areaId].order = order || 0;
        
        fields.forEach((i) => {
            layout.layers[this._layerId][i] = {
                ...layout.layers[this._layerId][i],
                ...layers[i]
            }
        })
        layout.layers[this._layerId].fit = true;

        setItem(FLOORPLAN_NAME, layout);
        this.updateFloorplan()
    }

    getCurrentArea() {
        const layer = getItem(FLOORPLAN_NAME);
        const areaIds = Object.keys(layer.layers[this._layerId].areas);
        if(!areaIds.length) return false;

        const order = areaIds.map((id) => layer.layers[this._layerId].areas[id].order).sort().reverse();
        const id = areaIds.find((id) => layer.layers[this._layerId].areas[id].order === order[0])

        return layer.layers[this._layerId].areas[id];
    }

    normalizeYaw(yaw) {
        let PI2 = Math.PI * 2;
        return ((yaw + PI2 + Math.PI) % PI2) - Math.PI;
    }

    getArea(id) {
        const planner = getItem(FLOORPLAN_NAME);
        const layer = planner.layers[this._layerId];

        return layer.areas[id] || {};
    }

    getOrderAreas() {
        const layer = getItem(FLOORPLAN_NAME);
        const areas = layer.layers[this._layerId].areas;
        if (!areas || Object.keys(areas).length === 0) return false;

        const sortedAreaEntries = Object.entries(areas)
            .sort(([, a], [, b]) => b.order - a.order); 
        
        const [currentArea, prevArea] = [sortedAreaEntries[0] ? sortedAreaEntries[0][1] : false, sortedAreaEntries[1] ? sortedAreaEntries[1][1] : false];
        
        return [prevArea, currentArea].filter(Boolean);
    }

    updateFloorplan() {
        this._floorplan = getItem(FLOORPLAN_NAME);
    }

    getVertices(ids) {
        const planner = getItem(FLOORPLAN_NAME);
        const layer = planner.layers[this._layerId];
        const vertices = Object.values(layer.vertices).filter((v) => ids.includes(v.id))

        return vertices;
    }

    getLines(ids) {
        const planner = getItem(FLOORPLAN_NAME);
        const layer = planner.layers[this._layerId];
        const lines = Object.values(layer.lines).filter(({vertices}) => ids.includes(vertices[0]) && ids.includes(vertices[1]));       
        return lines;
    }

    getLinesID(id) {
        const planner = getItem(FLOORPLAN_NAME);
        const layer = planner.layers[this._layerId];
        return layer.lines[id];
    }

    removeArea(id) {
        const planner = getItem(FLOORPLAN_NAME);
        const layer = planner.layers[this._layerId];
        const areas = layer.areas[id];
        const areaVertices = areas.vertices;

        for(let i of areaVertices) {
            const lines = layer.vertices[i].lines;
            for(let line of lines) {
                delete layer.lines[line];
            }
            delete layer.vertices[i];
        }
        delete layer.areas[id];

        for(let i in layer.selected) {
            layer.selected[i] = []; 
        }

        setItem(FLOORPLAN_NAME, planner);

        return planner;
    }
}