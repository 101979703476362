exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.template-action--container {\n  display: grid;\n  grid-template-columns: 1fr auto 1fr;\n  grid-template-row: 1fr;\n  gap: 16px 0;\n  grid-auto-flow: row;\n  grid-template-areas: \"action label scan\";\n  place-items: center; }\n\n.template-action .action {\n  grid-area: action; }\n\n.template-action .label {\n  grid-area: label; }\n\n.template-action .scan {\n  grid-area: scan; }\n", ""]);

// exports
exports.locals = {
	"template-action--container": "template-action--container",
	"template-action": "template-action",
	"action": "action",
	"label": "label",
	"scan": "scan"
};