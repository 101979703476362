import React, { createContext, useState } from 'react';
import './style.scss';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      <div>
        {loading && (
          <div className="loading-overlay">
            <div className="loader"></div>
          </div>
        )}
        {children}
      </div>
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
