import React, {Component} from 'react';
import PropTypes from 'prop-types';
import s from './style.scss'
import FormTextInput from 'a_component-styles/form-text-input';
import FormNumberInput from 'a_component-styles/form-number-input';
import FormColorInput from 'a_component-styles/form-color-input';
import FormColors from 'a_component-styles/form-colors';
import {isExist} from 'a_root-folder/utils/objects-utils';
import {getPatternColors} from 'a_root-folder/utils/areas-helper';
import FormSlider from 'a_component-styles/form-slider';
import c from 'a_component-styles/common.scss'

const _ = require('lodash');

export default class AreasMerge extends Component {
    constructor(props, context) {
        super(props, context);

        const scene = this.props.state.get('scene');
        const {label, width, patternColor, fontSize, area, length} = this.isExist(scene);
        const patternColors = getPatternColors(scene);

        this.state = {
            input: {
                text: <FormTextInput />,
                number: <FormTextInput />,
                range: <FormSlider />,
                color: <FormColorInput />,
                colors: <FormColors />
            },
            customLabel: false,
            values: {
                label: {
                    label: 'Label',
                    type: 'text',
                    name: 'label',
                    value: label || '',
                },
                patternColor: {
                    label: 'Color',
                    type: 'color',
                    name: 'patternColor',
                    value: patternColor || '#ffffff',
                },
                patternColors: {
                    label: 'Colors',
                    type: 'colors',
                    name: 'patternColors',
                    value: patternColors,
                },
                fontSize: {
                    label: 'Font',
                    type: 'range',
                    name: 'fontSize',
                    value: fontSize || 30,
                    min: 10,
                    max: 48,
                    step: 1,
                    precision: 0
                },
                width: {
                    label: 'Width',
                    type: 'number',
                    name: 'width',
                    value: width || 0,
                    configs: {
                        precision: 0
                    },
                    className: ['field-measure'],
                    unit: true
                },
                length: {
                    label: 'Length',
                    type: 'number',
                    name: 'length',
                    value: length || 0,
                    configs: {
                        precision: 0
                    },
                    className: ['field-measure'],
                    unit: true
                },
                area: {
                    label: 'Area',
                    type: 'number',
                    name: 'area',
                    value: area || 0,
                    configs: {
                        precision: 0
                    },
                    className: ['field-measure'],
                    unit: true
                },
            }
        }
    }

    remove() {
        let scene = this.props.state.get('scene');
        let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
        let selectedAreas = selectedLayer.getIn(['selected', 'areas']);

        this.context.areaActions.removeMergeAreas(selectedLayer.get('id'), selectedAreas);
    }

    saveChanges() {
        const scene = this.props.state.get('scene');

        if(!this.isExist(scene)){
            this.createMergeAreas();
        } else {
            this.putMergeAreas();
        }

        this.context.projectActions.unselectAll();
        this.context.projectActions.setCustomUpdate();
    }

    getValues() {
        const values = {};

        Object.values(this.state.values)
            .map(({name, value}) => { return {[name]: value}})
            .forEach(obj => {
                Object.keys(obj)
                    .forEach(key => {
                        values[key] = obj[key];
                    });
            })

        return values;
    }

    putMergeAreas() {
        const options = this.getValues();
        let scene = this.props.state.get('scene');
        let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
        let selectedAreas = selectedLayer.getIn(['selected', 'areas']);

        this.context.areaActions.putMergeAreas(selectedLayer.get('id'), selectedAreas, options);
    }

    createMergeAreas() {
        const options = this.getValues();
        let scene = this.props.state.get('scene');
        let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
        let selectedAreas = selectedLayer.getIn(['selected', 'areas']);

        this.context.areaActions.mergeAreas(selectedLayer.get('id'), selectedAreas, options);
    }

    onUpdate(name, value) {
        if(name === 'patternColors') name = 'patternColor';

        this.setState({
            values: {
                ...this.state.values,
                [name]: {
                    ...this.state.values[name],
                    value
                },
            },
            customLabel: true
        }, this.putMergeAreas);
    }

    isExist(scene) {
        return isExist(scene);
    }
    
    isHide() {
        const merge = this.props.merge;
        const scene = this.props.state.get('scene');

        return !merge && !this.isExist(scene);
    }

    render() {
        if(this.isHide()) return <React.Fragment></React.Fragment>;

        const scene = this.props.state.get('scene');
        let unit = scene.unit;

        return (
            <div className={s['element-editor']}
                    tabIndex="0"
                    onKeyDown={event => event.stopPropagation()}
                    onKeyUp={event => event.stopPropagation()}>
                <div className="element-editor-header">
                    Merge areas
                </div>
                <div className={s['element-editor-body']} style={{color: 'white'}}>
                    {Object.keys(this.state.values).map((name, i) => (
                        <div key={i} className={`${c['form-group-row']} form-group`}>
                            {this.state.values[name].type !== 'range' && <label className={c['form-group-label']}>{this.state.values[name].label}</label>}
                            <div className={this.state.values[name].type==='range' ? c['element-editor-actions'] : `form-group-field ${this.state.values[name].className && this.state.values[name].className.join(' ')}`}>
                                {React.cloneElement(this.state.input[this.state.values[name].type], {
                                    key: `${name}-${i}`,
                                    onChange: event => this.onUpdate(name, event.target.value),
                                    ...this.state.values[name]
                                })}
                                {
                                    this.state.values[name].unit && <span className='units-label'>{unit}{name === 'area' ? <sup><small>2</small></sup> : ''}</span>
                                }
                            </div>
                        </div>
                    ))}
                    {/* <div className="element-editor-actions">
                        <button onClick={e => this.saveChanges()} className='btn blue btn-md save-button'>
                            <i className="fa fa-save"/> Save
                        </button>
                    </div>
                    <div className="element-editor-actions">
                        <button onClick={e => this.remove()} className='btn red btn-md save-button'>
                            <i className="fa fa-remove"/> Remove
                        </button>
                    </div> */}
                </div>
            </div>
        )
    }
}

AreasMerge.propTypes = {
    state: PropTypes.object.isRequired,
    element: PropTypes.object.isRequired,
    layer: PropTypes.object.isRequired
};

AreasMerge.contextTypes = {
    areaActions: PropTypes.object.isRequired,
    projectActions: PropTypes.object.isRequired,
};
