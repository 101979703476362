import React from 'react';
import ShapeTemplate from '../../share-template';

import './style.scss';

const shape = (dimensions) => {
    const { up, right } = dimensions;
    return [
        [0.00000000, 0.00000000],
        [0.00000000, right],
        [up, right],
        [up, 0.00000000]
    ];
};

export default () => (
    <ShapeTemplate 
        defaultDimensions={{
            up: 200,
            right: 400
        }}
        classType="rectangle"
        shapeType="panorama"
        generateShapeHtml={shape}
    />
);
