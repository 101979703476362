import React from 'react';
import ShapeTemplate from '../../share-template';

import './style.scss';

const shape = (dimensions) => {
    const { up, left, depth, down } = dimensions;
    return [
        [0.00000000, 0.00000000],
        [0.00000000, left],
        [up, left],
        [up, left- depth],
        [Number(up) + Number(down) - Number(up) * 2, left - depth],
        [Number(up) + Number(down) - Number(up) * 2, left],
        [down, left],
        [down, 0]
    ];
};

export default () => (
    <ShapeTemplate 
        defaultDimensions={{
            up: 60,
            depth: 100,
            down: 400,
            left: 400,
        }}
        classType="u-shape"
        shapeType="panorama"
        generateShapeHtml={shape}
    />
);
