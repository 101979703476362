import React, { createContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ZipHandler from '../../helpers/zipHandler';
import { createArea, scaleCoordinates} from '../../helpers/area';
import { upload } from '../../api/panorama';
import EventService from '../../helpers/eventService';
import UploadTool from '../../components/upload-tool';

const UploadQueueContext = createContext();

export const UploadQueueProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [queue, setQueue] = useState([]);
    const [progress, setProgress] = useState(0);
    const [abortController, setAbortController] = useState([]);

    const updateTaskStatus = useCallback(({id, status, error = null, progress, repeat=0}) => {
        setQueue((prevQueue) =>
            prevQueue.map((task) =>
                task.id === id ? { ...task, status, error, progress, repeat } : task
            )
        );
    }, []);

    const removeTaskStatus = useCallback(({id}) => {
        setQueue((prevQueue) => prevQueue.filter((task) => task.id !== id));
    }, []);
    
    const onprogress = ({id, loaded, total}) => {
        updateTaskStatus({id, status: 'sending', progress: loaded / total * 66});
    }

    const uploadWithProgress = async (response) => {
        const id = Date.now();
        setShow(true);
     
        setQueue((prevQueue) => [
            ...prevQueue,
            { id, status: 'waiting', ...response },
        ]);
    };

    const sendToServer = async ({id, title, progress = 0, repeat = 0, ...response}) => {
        let isMounted = true;

        const cleanup = () => {
            isMounted = false;
        };

        const controller = new AbortController();
        setAbortController((controllers) => [...controllers, controller]);

        try {
            updateTaskStatus({...response, id, status: 'sending', progress, repeat});
            const blob = await upload({...response, signal: controller.signal, onprogress, id});
            if (!isMounted) return;
            const area = await addFloor(blob);
            if (!isMounted) return;

            createArea(scaleCoordinates(area.floor_xy.data), title);
            
            EventService.dispatch('on-reload-planner');
            updateTaskStatus({id, status: 'completed', progress: 100});
        } catch (error) {
            if (isMounted && !controller.aborted) {
                if(error.toString() ===  'Error: Network error' && repeat < 3) {
                    setTimeout(() => {
                        removeTaskStatus({id});
                        uploadWithProgress({...response, repeat: repeat + 1, title})
                    }, 1000);
                    return;
                }

                updateTaskStatus({...response, id, status: 'failed', error: error.toString(), progress: 100});
            }
        }

        return cleanup;
    }

    const addFloor = async (blob) => {
        const zipHandler = new ZipHandler();
        return zipHandler.unzipFiles(blob);
    };

    const removeTaskAfterDelay = (id) => {
        setQueue((prevQueue) => prevQueue.filter((task) => task.id !== id));
    };

    const removeWithProgress = () => {
        cancelUpload();
        setShow(false);
        setQueue([]);
    }

    const cancelUpload = () => {
        if (abortController.length) {
            abortController.forEach((controller) => {
                controller.abort(); 
            })
            setAbortController([]); 
        }
    };

    useEffect(() => {
        const totalTasks = queue.length;
        const completedTasks = queue.filter(({ status }) => ['completed', 'failed'].includes(status)).length;
        const calculatedProgress = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
        const active = queue.find(({ status }) => ['sending'].includes(status));
        const startingTasks = queue.filter(({ status }) => ['waiting'].includes(status))

        if(!active && startingTasks.length) {
            sendToServer({...startingTasks[0], progress: 0});
        }

        setProgress(calculatedProgress);

        let timeoutId;
        if (totalTasks > 0 && totalTasks === completedTasks) {
            timeoutId = setTimeout(() => {
                removeWithProgress();
            }, 5000);
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [queue]);

    return (
        <UploadQueueContext.Provider value={{ uploadWithProgress, queue, removeWithProgress }}>
            {children}
            {show && (
                <UploadTool
                    queue={queue}
                    progress={progress}
                    setShow={setShow}
                    removeTaskAfterDelay={removeTaskAfterDelay}
                    removeWithProgress={removeWithProgress}
                />
            )}
        </UploadQueueContext.Provider>
    );
};

UploadQueueContext.contextTypes = {
    projectActions: PropTypes.object.isRequired,
};

export default UploadQueueContext;