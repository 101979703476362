import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ElementEditor from './element-editor/element-editor';
import AreasMerge from './areas-merge/areas-merge';
import SceneConfig from './scene-config/scene-config'
import LayersManager from './layers-manager/layers-manager'
import diff from 'immutablediff';
import {getSelectedElement} from 'a_root-folder/utils/layer-operations';

import {
  MODE_CONFIGURING_PROJECT, MODE_MANAGING_LAYERS,
  CATALOG_ROOT_NAME, MODE_ROTATING_LAYER, MODE_DRAGGING_LAYER
} from 'a_root-folder/constants'

import s from './sidebar.scss';

export default class Sidebar extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {selected: getSelectedElement(props.state.scene)};
  }

  shouldComponentUpdate(newProps){
    if (this.props.state.scene.selectedLayer !== newProps.state.scene.selectedLayer) return true;
    if (diff(this.props.state.scene.layers, newProps.state.scene.layers).size) return true;
    if (diff(this.props.state.catalog, newProps.state.catalog).size) return true;
    if (diff(this.props.state.mode, newProps.state.mode).size) return true;
    if (diff(this.props.width, newProps.width).size || diff(this.props.height, newProps.height).size) return true;

    return false;
  }

  render() {
    let {
      props, props: {state, width, height}
    } = this;

    let {scene, mode, draggingSupport, catalog} = state;
    let selected = getSelectedElement(scene);

    let currentCategory = this.context.catalog.getCategory(catalog.page);
    let activeLayer = scene.layers.get(scene.selectedLayer);

    const getHeaderText = () => {
      if (selected) return 'Edit element';
      if (mode === MODE_CONFIGURING_PROJECT) return 'Config scene';
      if ([MODE_ROTATING_LAYER, MODE_MANAGING_LAYERS, MODE_DRAGGING_LAYER].includes(mode)) return 'Layers';
      if (currentCategory.name !== CATALOG_ROOT_NAME) return currentCategory.name;

      return 'Elements';
    };

    const layerRenderer = () => {
      if (selected && activeLayer.getIn(['selected', 'areas']).size > 1 && ![MODE_MANAGING_LAYERS, MODE_ROTATING_LAYER, MODE_DRAGGING_LAYER].includes(mode)) {
        return <AreasMerge merge={true} mode={mode} key={selected.id} element={selected} layer={activeLayer} state={state} draggingSupport = {draggingSupport} />
      } 

      if (selected && ![MODE_MANAGING_LAYERS, MODE_ROTATING_LAYER, MODE_DRAGGING_LAYER].includes(mode)) {
        return <React.Fragment>
          <ElementEditor mode={mode} key={selected.id} element={selected} layer={activeLayer} state={state} draggingSupport = {draggingSupport} />
          <AreasMerge merge={false} mode={mode} key={`${selected.id}-areas-merge`} element={selected} layer={activeLayer} state={state} draggingSupport = {draggingSupport} />
       </React.Fragment>
      } else {
        if(MODE_CONFIGURING_PROJECT === mode) {
          return <SceneConfig scene={scene}/>
        } else if([MODE_MANAGING_LAYERS, MODE_ROTATING_LAYER, MODE_DRAGGING_LAYER].includes(mode)) {
          return <React.Fragment><SceneConfig scene={scene}/> <LayersManager scene={scene}/></React.Fragment>
        }
      }
      return null
    };

    return (
      <aside style={{maxWidth: width, maxHeight: height}} className={s.sidebar}>
        <div className={s.body}>
          {layerRenderer()}
        </div>
      </aside>
    )
  }
}

Sidebar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Sidebar.contextTypes = {
  viewer2DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
