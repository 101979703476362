import  {USER_ID, TOUR_ID} from '../constants';

export const setItem = (key, value) => {
    if (!key || typeof key !== 'string') {
    console.error('Invalid key provided for localStorage');
    return;
    }

    try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
    } catch (error) {
    console.error('Error setting item to localStorage:', error);
    }
};
  
export const getItem = (key) => {
    if (!key || typeof key !== 'string') {
    console.error('Invalid key provided for localStorage');
    return null;
    }

    try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
    } catch (error) {
    console.error('Error getting item from localStorage:', error);
    return null;
    }
};
  
export const updateItem = (key, value) => {
    if (!key || typeof key !== 'string') {
    console.error('Invalid key provided for localStorage');
    return;
    }

    try {
    const existingItem = localStorageHelper.getItem(key);
    if (existingItem) {
        const updatedValue = { ...existingItem, ...value };
        localStorageHelper.setItem(key, updatedValue);
    } else {
        console.warn(`Item with key "${key}" not found. Creating a new one.`);
        localStorageHelper.setItem(key, value);
    }
    } catch (error) {
    console.error('Error updating item in localStorage:', error);
    }
};
  
export const removeItem = (key) => {
    if (!key || typeof key !== 'string') {
    console.error('Invalid key provided for localStorage');
    return;
    }

    try {
    localStorage.removeItem(key);
    } catch (error) {
    console.error('Error removing item from localStorage:', error);
    }
};
  
export const clearAll = () => {
    try {
    localStorage.clear();
    } catch (error) {
    console.error('Error clearing localStorage:', error);
    }
};
  
export const exists = (key) => {
    if (!key || typeof key !== 'string') {
    console.error('Invalid key provided for localStorage');
    return false;
    } d

    return localStorage.getItem(key) !== null;
};

export const getFloorplanItems = (prefix) => {
    const result = [];

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key.startsWith(prefix)) {
            result.push(JSON.parse(localStorage.getItem(key)));
        }
    }

    return result;
}

export const getUserID = () => { 
    return getItem(USER_ID) || '';
}

export const getTourId = () => { 
    return getItem(TOUR_ID) || '';
}