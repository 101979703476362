export default class MessageEmitter {
    constructor() {
        this.parent = window.parent;
        this.self = window;

        // this.transferOrigin = window.location.origin;
        this.transferOrigin = '*';

        this._events = {};

        this.self.addEventListener('message', (m) => {
            if (this._events[m.data.event] && this._events[m.data.event].length) {
                this._events[m.data.event].forEach((callback) => {
                    callback.call(null, m.data);
                })
            }
        });
    }

    on(name, callback) {
        this._events[name] = this._events[name] || [];
        this._events[name].push(callback);
    }

    off(name, callback) {
        if(!name) {
            this._events = {};
            return;
        }
        if(callback) {
            let events = this._events[name];
            if(!events || !events.length) {
                return;
            }
            let index = events.indexOf(callback);
            if(~index) {
                events.splice(index, 1);
                return;
            }
            if(!events.length)
                delete this._events[name];
            return;
        }

        delete this._events[name];
    }

    send(message) {
        this.parent.postMessage(message, this.transferOrigin);
    }
}
