exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.edit-page--label {\n  display: flex;\n  gap: 16px;\n  width: 100%;\n  cursor: pointer;\n  justify-content: center;\n  align-items: center; }\n  .edit-page--label img {\n    width: 24px;\n    height: 24px; }\n\n.edit-page--span {\n  flex-grow: 1;\n  font-size: 24px; }\n  @media (max-width: 768px) {\n    .edit-page--span {\n      font-size: 4vw; } }\n\n.edit-page--view {\n  flex-grow: 1;\n  border-bottom: 1px solid #d8d8d8;\n  height: 30px;\n  position: relative;\n  display: flex;\n  align-items: center; }\n  .edit-page--view .edit {\n    width: 100%; }\n    .edit-page--view .edit .save {\n      position: absolute;\n      right: 8px;\n      top: 50%;\n      transform: translate(0, -50%);\n      width: 24px;\n      height: 24px; }\n  .edit-page--view .view {\n    width: 100%; }\n    .edit-page--view .view svg {\n      width: 24px;\n      height: 24px; }\n  .edit-page--view .edit-input {\n    flex-grow: 1;\n    width: 100%;\n    height: 30px; }\n  .edit-page--view.last {\n    border-bottom: none; }\n  .edit-page--view svg {\n    fill: #d8d8d8; }\n\n.edit-page--title {\n  width: 100%;\n  gap: 16px; }\n  .edit-page--title input {\n    flex-grow: 1; }\n", ""]);

// exports
exports.locals = {
	"edit-page--label": "edit-page--label",
	"edit-page--span": "edit-page--span",
	"edit-page--view": "edit-page--view",
	"edit": "edit",
	"save": "save",
	"view": "view",
	"edit-input": "edit-input",
	"last": "last",
	"edit-page--title": "edit-page--title"
};