import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { MdUndo, MdScreenRotation, MdDelete, MdClose, MdSave, MdCompare } from 'react-icons/md';
import ToolbarButton from './toolbar-button';
import If from '../../utils/react-if';
import {
  MODE_IDLE,
  MODE_3D_VIEW,
  MODE_3D_FIRST_PERSON,
  MODE_VIEWING_CATALOG,
  OPEN_CATALOG
} from '../../constants';
import * as SharedStyle from '../../shared-style';
import EventService from '../../../demo/src/helpers/eventService';

const iconTextStyle = {
  fontSize: '19px',
  textDecoration: 'none',
  fontWeight: 'bold',
  margin: '0px',
  userSelect: 'none'
};
const newroom = require('../../../demo/src/assets/newroom.png');
const catalog = require('../../../demo/src/assets/catalog.png');
const catalog_focused = require('../../../demo/src/assets/catalog_focused.png');
const newroom_focused = require('../../../demo/src/assets/newroom_focused.png');
import {FLOORPLAN_NAME} from '../../../demo/src/constants';
import  {guessDoor} from '../../../demo/src/helpers/area'

const Icon2D = ( {style} ) => <p style={{...iconTextStyle, ...style}}>2D</p>;
const Icon3D = ( {style} ) => <p style={{...iconTextStyle, ...style}}>3D</p>;

import './style.scss';

const ASIDE_STYLE = {
  backgroundColor: SharedStyle.PRIMARY_COLOR.main,
  padding: '10px'
};

const IMAGE = {
  WRAPPER: {
    position: 'relative',
    height: '30px',
    width: '30px',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  HOVER: {
    width: '25px',
    height: '25px'
  },
  MAIN: {
    width: '25px',
    height: '25px'
  }
}
const sortButtonsCb = (a, b) => {
  if (a.index === undefined || a.index === null) {
    a.index = Number.MAX_SAFE_INTEGER;
  }

  if (b.index === undefined || b.index === null) {
    b.index = Number.MAX_SAFE_INTEGER;
  }

  return a.index - b.index;
};

const mapButtonsCb = (el, ind) => {
  return (
    <If
      key={ind}
      condition={el.condition}
      style={{ position: 'relative' }}
    >
      {el.dom}
    </If>
  );
};

export default class Toolbar extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.state.mode !== nextProps.state.mode ||
  //     this.props.height !== nextProps.height ||
  //     this.props.width !== nextProps.width ||
  //     this.props.state.alterate !== nextProps.state.alterate;
  // }

  findSelectedObject (layer) {
    const collections = ['areas', 'items', 'holes'];
  
    for (const collection of collections) {
      const selectedObject = layer.get(collection).find(item => item.get('selected'));
      if (selectedObject) return selectedObject;
    }
  
    return null;
  };

  render() {
    let {
      props: { state, width, height, toolbarButtons, allowProjectFileSupport },
      context: { projectActions, viewer3DActions, holesActions, viewer2DActions, areaActions, translator, itemsActions }
    } = this;

    let mode = state.get('mode');
    let alterate = state.get('alterate');
    let alterateColor = alterate ? SharedStyle.MATERIAL_COLORS[500].orange : '';
    let obj = null;

    state.getIn(['scene', 'layers']).some(layer => {
      obj = this.findSelectedObject(layer);
      return obj !== null;
    });

    let sorter = [
      {
        index: 2, condition: true,
        dom: <ToolbarButton
          active={[MODE_VIEWING_CATALOG].includes(mode)}
          tooltip={translator.t('Open catalog')}
          onClick={event => {
            if ([OPEN_CATALOG].includes(mode)) {
              projectActions.rollback();
            } else {
              projectActions.openCatalog();
            }      
          }}>
          <div className="image-container" style={IMAGE.WRAPPER}>
            <img src={catalog} alt="catalog" style={IMAGE.MAIN} className="default-image" />
            <img src={catalog_focused} alt="catalog" style={IMAGE.HOVER} className="hover-image" />
          </div>
        </ToolbarButton>
      },
      {
        index: 10, condition: true, dom: <ToolbarButton
          active={false}
          tooltip={translator.t('Undo (CTRL-Z)')}
          onClick={event => projectActions.undo()}>
          <MdUndo />
        </ToolbarButton>
      },
      {
        index: 1, condition: true, dom: <ToolbarButton
          active={false}
          tooltip={translator.t('Add room')}
          onClick={event => {
            projectActions.unselectAll();
            localStorage.setItem(FLOORPLAN_NAME, JSON.stringify(state.scene.toJS()));
            EventService.dispatch("on-route", { go: "/add-room" });
          }}>
          <div className="image-container" style={IMAGE.WRAPPER}>
            <img src={newroom} alt="catalog" style={IMAGE.MAIN} className="default-image" />
            <img src={newroom_focused} alt="catalog" style={IMAGE.HOVER} className="hover-image" />
          </div>
        </ToolbarButton>
      },
      {
        index: 99, condition: true, dom: <ToolbarButton
          tooltip={translator.t('Close')}
          active={false}
          onClick={event => {
            localStorage.setItem(FLOORPLAN_NAME, JSON.stringify(state.scene.toJS()));
            EventService.dispatch("on-close", { message: "" });
          }}>
          <MdClose />
        </ToolbarButton>
      },
      {
        index: 18, condition: true, dom: <ToolbarButton
          active={false}
          tooltip={translator.t('Save svg')}
          onClick={event => {
            viewer2DActions.selectToolPanScreenshot();
          }}>
          <MdSave />
        </ToolbarButton>
      },
    ];

    if (![MODE_3D_FIRST_PERSON, MODE_3D_VIEW].includes(mode)) {
      sorter.push({
        index: 6, condition: true, dom: <ToolbarButton
          active={[MODE_3D_VIEW].includes(mode)}
          tooltip={translator.t('3D View')}
          onClick={event => viewer3DActions.selectTool3DView()}>
          <Icon3D />
        </ToolbarButton>
      })
    } else {
      sorter.push({
        index: 6, condition: true, dom: <ToolbarButton
          active={[MODE_IDLE].includes(mode)}
          tooltip={translator.t('2D View')}
          onClick={event => projectActions.setMode( MODE_IDLE )}>
          {<Icon2D style={{color: alterateColor}} />}
        </ToolbarButton>
      })
    }


    if (obj) {
      sorter.push({
        index: 5, condition: true, dom: <ToolbarButton
          active={true}
          tooltip={translator.t('Remove')}
          onClick={event => {
            const selectedLayer = state.getIn(['scene', 'selectedLayer']);
            const type = obj.constructor.name === 'Area' || obj.get('type') === 'area' ? 'area' :
                         obj.constructor.name === 'Hole' || obj.get('type') === 'window' ? 'hole' : 'item';
            
            switch (type) {
              case 'area':
                areaActions.removeArea(selectedLayer, obj.get('id'));
                break;
              case 'hole':
                holesActions.removeHole(selectedLayer, obj.get('id'));
                break;
              default:
                itemsActions.removeItem(selectedLayer, obj.get('id'));
                break;
            }
          }}>   
          <MdDelete />
        </ToolbarButton>
      })

      sorter.push({
        index: 3, condition: true, dom: <ToolbarButton
          active={true}
          tooltip={translator.t('Door choose')}
          onClick={event => {
            const selectedLayer = state.getIn(['scene', 'selectedLayer']);
            const type = obj.constructor.name === 'Area' || obj.get('type') === 'area' ? 'area' :
                         obj.constructor.name === 'Hole' || obj.get('type') === 'window' ? 'hole' : 'item';
            
            switch (type) {
              case 'area':
                localStorage.setItem(FLOORPLAN_NAME, JSON.stringify(state.scene.toJS()));
                guessDoor();
                EventService.dispatch("on-reload-planner");
                break;
              break;
            }
          }}>   
          <MdCompare />
        </ToolbarButton>
      })

      if (obj.constructor.name !== 'Hole' ) {
        sorter.push({
          index: 2, condition: true, dom: <ToolbarButton
            active={true}
            tooltip={translator.t('Rotation')}
            onClick={event => {
              const selectedLayer = state.getIn(['scene', 'selectedLayer']);
  
              if (obj.constructor.name === 'Area' || obj.get('type') === 'area') {
                areaActions.rotatearea(obj.get('angle') -45)
              } else {
                itemsActions.rotateItem(selectedLayer, obj.get('id'), obj.get('rotation') -45);
              }
            }}>
            <MdScreenRotation />
          </ToolbarButton>
        })
      }
    }

    sorter = sorter.concat(toolbarButtons.map((Component, key) => {
      return Component.prototype ? 
        {
          condition: true,
          dom: React.createElement(Component, { mode, state, key })
        } :
        {                         
          index: Component.index,
          condition: Component.condition,
          dom: React.createElement(Component.dom, { mode, state, key })
        };
    }));

    return (
      <aside style={{ ...ASIDE_STYLE, maxWidth: width, maxHeight: height }} className='toolbar'>
        {sorter.sort(sortButtonsCb).map(mapButtonsCb)}
      </aside>
    )
  }
}

Toolbar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array
};

Toolbar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
