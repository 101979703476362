exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.choose-shape {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 20px;\n  align-items: stretch;\n  justify-items: center;\n  width: 100%;\n  padding: 16px; }\n  .choose-shape--block {\n    padding: 20px;\n    text-align: center;\n    border: 1px solid;\n    width: 100%; }\n    .choose-shape--block img {\n      max-width: 100px;\n      flex-grow: 1;\n      display: flex; }\n", ""]);

// exports
exports.locals = {
	"choose-shape": "choose-shape",
	"choose-shape--block": "choose-shape--block"
};