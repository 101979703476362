import areapolygon from 'area-polygon';

export function getPolygonSquare(polygon) {
    return areapolygon(polygon);
}

export function getPolygonBounding(polygon) {
    let minX = Number.POSITIVE_INFINITY, minY = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY, maxY = Number.NEGATIVE_INFINITY;

    polygon.forEach((v) => {
        const [x, y] = v;
        minX = x < minX ? x : minX;
        minY = y < minY ? y : minY;
        maxX = x > maxX ? x : maxX;
        maxY = y > maxY ? y : maxY;
    });

    return  {minX, minY, maxX, maxY}
}