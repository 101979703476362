import React from "react";
import * as Geometry from 'a_root-folder/utils/geometry';
import {generatePointArea} from "a_root-folder/utils/layer-operations";
import {
    COUNT_SNAP_DISSEMBLE,
    DISCLAIMER,
    FONT_FAMILY,
} from 'a_root-folder/constants';
import {
    calculateHoleOffsetPoint
} from 'a_root-folder/utils/layer-operations';
import * as d3 from "d3";
import {FONT_SIZE_ASPECT, LABEL_STYLES} from "../constants";

require('url-polyfill');

export function getParamsFromUrl(location = window.location.href, type) {
    var url = new URL(location);

    return url.searchParams.get(type);
}

export function orientation(p, q, r) {
    return (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
}

export function convexHull(points) {
    if (points.length < 3) {
        return []; // Convex hull cannot be formed with less than 3 points
    }

    const hull = [];
    let leftmost = 0;
    const n = points.length;

    // Find the leftmost point as the starting point
    for (let i = 1; i < n; i++) {
        if (points[i].x < points[leftmost].x) {
        leftmost = i;
        }
    }

    let p = leftmost;
    let q;

    do {
        hull.push(points[p]);
        q = (p + 1) % n;

        for (let i = 0; i < n; i++) {
        if (orientation(points[p], points[i], points[q]) < 0) {
            q = i;
        }
        }

        p = q;
    } while (p !== leftmost);

    return hull;
}

export function findBoundinBox(layers) {
    let {vertices} = layers;
    let minX = Number.POSITIVE_INFINITY, minY = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY, maxY = Number.NEGATIVE_INFINITY;
    vertices.forEach((vertice) => {
        minX = Math.min(minX, vertice.x);
        minY = Math.min(minY, vertice.y);
        maxX = Math.max(maxX, vertice.x);
        maxY = Math.max(maxY, vertice.y);
    })

    return {
        minX,
        minY,
        maxX,
        maxY
    }
}

export function getBase64FromSVG({layers, fromPanel, cut, svg=false, transform=false, hideMeasurement=false}) {
    let boundingBox = layers && findBoundinBox(layers);
    let offsets = {};
    let getOnlySqueryWithPlan = svg => svg.querySelector('g') || svg;
    return new Promise((req, rej) => {
        // First of all I need the svg content of the viewer
        let svgElements = document.getElementsByTagName('svg');

        // I get the element with max width (which is the viewer)
        let maxWidthSVGElement = svgElements[0];
        for (let i = 1; i < svgElements.length; i++) {
            if (svgElements[i].width.baseVal.value > maxWidthSVGElement.width.baseVal.value) {
                maxWidthSVGElement = svgElements[i];
            }
        }
        let serializer = new XMLSerializer();

        let img = new Image;

        // I create the new canvas to draw
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let planSquer = getOnlySqueryWithPlan(maxWidthSVGElement).querySelector('#svg-drawing-paper').getBoundingClientRect();
        let padding = 10;
        let quality = 3;
        let layerSquer = getOnlySqueryWithPlan(maxWidthSVGElement).querySelector('#svg-drawing-paper').getBoundingClientRect();

        if (cut) layerSquer = getOnlySqueryWithPlan(maxWidthSVGElement).getBoundingClientRect();

        let size = planSquer.width;
        if (planSquer.height > planSquer.width) {
            size = planSquer.height;
        }

        // Set width and height for the new canvas
        let heightAtt = document.createAttribute('height');
        heightAtt.value = layerSquer.height * quality;
        if (cut) heightAtt.value = planSquer.height * quality;
        canvas.setAttributeNode(heightAtt);

        let widthAtt = document.createAttribute('width');
        widthAtt.value = layerSquer.width * quality;
        if (cut) widthAtt.value = planSquer.width * quality;
        canvas.setAttributeNode(widthAtt);

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        img.crossOrigin = 'anonymous';
        img.onload = () => {
            if (cut) {
                let offsetLeft = 0;
                let offsetTop = 0;
                let sWidth = 0;
                let sHeight = 0;

                if (fromPanel) {
                    offsetLeft = (planSquer.left - layerSquer.left) - padding / 2;
                    offsetTop = planSquer.top - padding / 2 ;
                    sWidth = planSquer.width + padding;
                    sHeight = planSquer.height + padding;
                } else {
                    offsetLeft = planSquer.left - padding;
                    offsetTop = planSquer.top - padding ;
                    sWidth = planSquer.width + padding * 2;
                    sHeight = planSquer.height + padding * 2 ;
                }

                ctx.drawImage(
                    img,

                    offsetLeft,
                    offsetTop,

                    sWidth,
                    sHeight,

                    0, 0, canvas.width, canvas.height);

                offsets = {
                    boundingBox,
                    offsetLeft: offsetLeft / layerSquer.width,
                    offsetTop: offsetTop / layerSquer.height,
                    width: layerSquer.width / sWidth,
                    height: layerSquer.height / sHeight,
                }
            } else {
                ctx.drawImage(img, 0, 0, layerSquer.width, layerSquer.height, 0, 0, canvas.width, canvas.height);
            }

            if (svg) {
                svgBase64();
            } else {
                req({data: canvas.toDataURL('image/jpeg'), offsets});
            }

            function svgBase64() {
                const svgEl = getOnlySqueryWithPlan(maxWidthSVGElement);
                svgEl.removeAttribute('transform');
                const layerEl = svgEl.querySelector('#svg-drawing-paper');
                const layerBounding = layerEl.getBoundingClientRect();
                const xmlns = "http://www.w3.org/2000/svg";
                const width = Math.ceil(layerBounding.width + padding * 2);
                const height = Math.ceil(layerBounding.height + padding * 2);
                const svgElem = document.createElementNS(xmlns, "svg");
                svgElem.setAttributeNS(null, "width", `${width}`);
                svgElem.setAttributeNS(null, "height", `${height}`);
                svgElem.setAttributeNS(null, "x", `0`);
                svgElem.setAttributeNS(null, "y", `0`);
                svgElem.setAttributeNS(null, "viewBox", `0 0 ${width} ${height}`);

                svgElem.style.display = "block";

                if(transform) layerEl.setAttributeNS(null, "transform", `translate(-${layerBounding.left - padding}, ${layerBounding.top - padding})`);

                const main = svgEl.cloneNode(true);

                svgElem.appendChild(main);
                const linkSvg = serializer.serializeToString(svgElem).replace(/#/g, '%23');

                req({data: `data:image/svg+xml;utf8,${linkSvg}`, offsets, height});
            }
        }

        const link = serializer.serializeToString(maxWidthSVGElement).replace(/#/g, '%23');

        img.src = `data:image/svg+xml;utf8,${link}`;

    })
}

export function isSnapActive(layers) {
    let vertices = 0;

    layers.forEach((layer) => {
        vertices += layer.vertices.size;
    })

    return vertices < COUNT_SNAP_DISSEMBLE;
}

export function isInverted(layer, lines, {x1, y1, x2, y2}) {
    let inverted = false;
    let vertices = Object.values(layer.get('vertices').toJS());
    let prev = Geometry.sortPoints(generatePointArea(lines, vertices));
    let sortArr = getPairIndexes(prev);
    if (!sortArr.length) return false;

    sortArr.reduce((prev, current) => {
        if (prev.x == x1 && prev.y == y1 && current.x == x2 && current.y == y2) {
            inverted = false;
        } else if (prev.x == x2 && prev.y == y2 && current.x == x1 && current.y == y1) {
            inverted = true;
        }

        return current;
    })

    return inverted;
}

export function getPairIndexes(arr) {
    let a = [];
    for (var i = 0; i <= arr.length - 1; i++) {
        if (i % 2 == 0) {
            a.push(arr[i])
        }
    }
    return a;
}

export function calculateMinSizes(layers, sceneWidth, sceneHeight, coef = 0.1) {
    let position = {
        left: Infinity,
        offsetPlanX: 0,
        offsetPlanY: 0,
        right: 0,
        top: 0,
        bottom: Infinity,
        paddingX: 150,
        paddingY: 150
    };

    layers.forEach((layer) => {
        layer.vertices.forEach((vertex) => {
            if (vertex.x > position.right) position.right = vertex.x;
            if (vertex.x < position.left) position.left = vertex.x;
            if (vertex.y > position.top) position.top = vertex.y;
            if (vertex.y < position.bottom) position.bottom = vertex.y;
        })
        layer.items.forEach((item) => {
            let itemsWidth = item.properties.getIn(['width', 'length']) / 2;
            let itemsHeight = item.properties.getIn(['depth', 'length']) / 2;
            let x = item.x - itemsWidth;
            let y = item.y - itemsHeight;
            let x1 = item.x + itemsWidth;
            let y1 = item.y + itemsHeight;

            if (x > position.right) position.right = x;
            if (x < position.left) position.left = x;
            if (y > position.top) position.top = y;
            if (y < position.bottom) position.bottom = y;

            if (x1 > position.right) position.right = x1;
            if (x1 < position.left) position.left = x1;
            if (y1 > position.top) position.top = y1;
            if (y1 < position.bottom) position.bottom = y1;
        })
        layer.holes.forEach((hole) => {
            let holesWidth = hole.properties.getIn(['offsetHeight', 'length']) / 2;
            let holesHeight = hole.properties.getIn(['offsetHeight', 'length']) / 2;
            if (!layer.lines.get(hole.line)) {
                return;
            }

            let {offsetPosition} = calculateHoleOffsetPoint(hole, layer);
            let {x: xx, y: yy} = offsetPosition;

            hole.x = xx;
            hole.y = yy;

            let x = hole.x - holesWidth;
            let y = hole.y - holesHeight;
            let x1 = hole.x + holesWidth;
            let y1 = hole.y + holesHeight;

            if (x > position.right) position.right = x;
            if (x < position.left) position.left = x;
            if (y > position.top) position.top = y;
            if (y < position.bottom) position.bottom = y;

            if (x1 > position.right) position.right = x1;
            if (x1 < position.left) position.left = x1;
            if (y1 > position.top) position.top = y1;
            if (y1 < position.bottom) position.bottom = y1;
        })
    });

    position.offsetX = position.left < 0 ? Math.abs(position.left) + 500 : 0;
    position.offsetY = position.bottom < 0 ? Math.abs(position.bottom) + 500 : 0;

    position.offsetPlanX = (sceneWidth - position.right) < 0 ? Math.abs(sceneWidth - position.right) + 500 : 0;
    position.offsetPlanY = (sceneHeight - position.top) < 0 ? Math.abs(sceneHeight - position.top) + 500 : 0;

    return {
        allow: [
            sceneWidth < position.right,
            sceneHeight < position.top,
            position.left < 0,
            position.bottom < 0
        ],
        y: position.offsetY,
        x: position.offsetX,
        offsetPlanY: position.offsetPlanY,
        offsetPlanX: position.offsetPlanX
    }
}

export function parse2DItemProps(renderItem, type) {
    if (!type) return null;

    // todo: describe available item types
    switch (type) {
        case 'simple-stair':
            let transform = renderItem.props ? renderItem.props.transform : null;

            if (!transform) return null;

            let [x, y] = transform.match(/-?\d+/gm);

            return {x: Number(x), y: Number(y)};
        default:
            return null
    }
}

export function getBBox(text, {fontFamily, fontSize, letterSpacing, fontWeight}) {
    const span_element = document.createElement("span");
    span_element.id='svg-size'
    document.body.appendChild(span_element);

    const svg = d3.select(span_element).append("svg");
    const canvas = svg.append("text")
        .style("font-family", fontFamily)
        .style("font-size", fontSize)
        .style("font-weight", fontWeight)
        .style("letter-spacing", letterSpacing)
        .append("tspan")
        .text(text)


    const bbox = canvas.node().getBBox();
    document.body.removeChild(span_element);

    return bbox
}

export function getGroupBBox(text, measurementText, fontSize) {
    const span_element = document.createElement("span");
    span_element.id='svg-size'
    document.body.appendChild(span_element);

    const svg = d3.select(span_element).append("svg");
    const {labelShadow, measurementShadow} = LABEL_STYLES;
    const container = svg.append("g");

    const label = container.append("text")
        .style("font-family", labelShadow.fontFamily)
        .style("font-size", fontSize)
        .style("font-weight", labelShadow.fontWeight)
        .style("letter-spacing", labelShadow.letterSpacing)
        .append("tspan")
        .text(text)

    const measurement = container.append("text")
        .style("font-family", measurementShadow.fontFamily)
        .style("font-size", fontSize * FONT_SIZE_ASPECT)
        .style("font-weight", measurementShadow.fontWeight)
        .style("letter-spacing", measurementShadow.letterSpacing)
        .attr("dy", '-1.2em')
        .append("tspan")
        .attr("dy", '1.5em')
        .text(measurementText)

    const bbox = container.node().getBBox();
    document.body.removeChild(span_element);

    return bbox
}

export function getTextWidth(text, font = FONT_FAMILY.ARIAL_BOLD) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
}

export function breakString(word, maxWidth, hyphenCharacter='-') {
    const characters = word.split("");
    const lines = [];
    let currentLine = "";
    characters.forEach((character, index) => {
        const nextLine = `${currentLine}${character}`;
        const lineWidth = getTextWidth(nextLine);
        if (lineWidth >= maxWidth) {
            const currentCharacter = index + 1;
            const isLastLine = characters.length === currentCharacter;
            const hyphenatedNextLine = `${nextLine}${hyphenCharacter}`;
            lines.push(isLastLine ? nextLine : hyphenatedNextLine);
            currentLine = "";
        } else {
            currentLine = nextLine;
        }
    });
    return { hyphenatedStrings: lines, remainingWord: currentLine };
}

export function wrapLabel(label, maxWidth) {
    maxWidth = maxWidth;
    const words = label.split(" ");
    const completedLines = [];
    let nextLine = "";
    words.forEach((word, index) => {
        const wordLength = getTextWidth(`${word} `);
        const nextLineLength = getTextWidth(nextLine);
        if (wordLength > maxWidth) {
            const { hyphenatedStrings, remainingWord } = breakString(word, maxWidth);
            completedLines.push(nextLine, ...hyphenatedStrings);
            nextLine = remainingWord;
        } else if (nextLineLength + wordLength >= maxWidth) {
            completedLines.push(nextLine);
            nextLine = word;
        } else {
            nextLine = [nextLine, word].filter(Boolean).join(" ");
        }
        const currentWord = index + 1;
        const isLastWord = currentWord === words.length;
        if (isLastWord) {
            completedLines.push(nextLine);
        }
    });
    return completedLines.filter(line => line !== "");
}

export function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }