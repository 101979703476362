import {COMPONENT_WAIT_CONFIG} from 'a_root-folder/constants'

export default function (state, action) {
  switch(action.type) {
    case COMPONENT_WAIT_CONFIG:
      return state.set('loading', true);
    default:
      return state;
  }
}
