import React from 'react';
import ReactRange from '@mapbox/react-range';
import FormTextInput from './form-text-input';

const sliderContainerStyle = {display: 'inline-block', width: '80%', gap: '8px', paddingRight: '16px', display: 'flex', flexDirection: 'column'};
const sliderStyle = { display: 'block', width: '100%', height: '30px' };
const textContainerStyle = {display: 'inline-block', width: '15%', float: 'right'};
const textStyle = {height:'34px', textAlign:'center', padding: '0', backgroundColor: '#fff', border: '1px solid #c2cad8', borderRadius: '4px'};
const wrapperStyle = {display:'flex', justifyContent:'center', alignItems:'center'};

export default function FormNumberInput({value, label, onChange, ...rest}) {
  const [num, setNum] = React.useState(value || 0);

  const handleChange = (e) => {
    setNum(e.target.value);
    onChange(e)
  };

  return (
    <div style={wrapperStyle}>
      <div style={sliderContainerStyle}>
        <span>{label}</span>
        <input type="range" min="1" max="360" style={sliderStyle} onChange={handleChange} value={num} {...rest} />
        {/* <ReactRange type='range' style={sliderStyle} onChange={onChange} value={value} {...rest}/> */}
      </div>

      <div style={textContainerStyle}>
        <FormTextInput value={value} onChange={onChange} style={textStyle}/>
      </div>
    </div>
  )
}
