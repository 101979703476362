import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {GeometryUtils} from '../../utils/export';

const STYLE = {
  stroke: "#0096fd",
  strokeWidth: "1px"
};

const STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: "12px",
  fontFamily: "'Courier New', Courier, monospace",
  pointerEvents: "none",
  fontWeight: "bold",

  //http://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css
  WebkitTouchCallout: "none", /* iOS Safari */
  WebkitUserSelect: "none",   /* Chrome/Safari/Opera */
  MozUserSelect: "none",      /* Firefox */
  MsUserSelect: "none",       /* Internet Explorer/Edge */
  userSelect: "none"
};


function findMidpoint(point1, point2) {
  const x_m = (point1.x + point2.x) / 2;
  const y_m = (point1.y + point2.y) / 2;
  return { x: x_m, y: y_m };
}

function isPointInPolygon(point, polygon) {
  const {x, y} = point;
  let isInside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const {x: xi, y: yi} = polygon[i];
      const {x: xj, y: yj} = polygon[j];

      const intersect = ((yi > y) !== (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) isInside = !isInside;
  }

  return isInside;
}

// Function to calculate the midpoint
function calculateMidpoint(point1, point2) {
  const midpointX = (point1.x + point2.x) / 2;
  const midpointY = (point1.y + point2.y) / 2;
  return { x: midpointX, y: midpointY };
}

// Function to check if a point is inside the polygon using the ray-casting algorithm
function isPointInsidePolygon(point, polygon) {
  let x = point.x, y = point.y;
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi = polygon[i].x, yi = polygon[i].y;
      let xj = polygon[j].x, yj = polygon[j].y;
      
      let intersect = ((yi > y) !== (yj > y)) &&
                      (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  return inside;
}

function movePointInside(midpoint, polygon, moveBy = 20, angle) {
  const directions = [
      { x: 0, y: -1 }, 
      { x: 1, y: 0 },   
      { x: 0, y: 1 },  
      { x: -1, y: 0 }  
  ];

  for (let dir in directions) {
      const movedPoint = {
          x: midpoint.x + directions[dir].x * moveBy,
          y: midpoint.y + directions[dir].y * moveBy
      };
      
      if (isPointInsidePolygon(movedPoint, polygon)) {
          if (dir == 0) {
            return 20;
          } else if(dir == 1) {
            return -20;
          } else if(dir == 2) {
            return 20;
          } else {
            return 20 * Math.sign(angle) * -1;
          }
      }
  }
}

export default function Ruler({length, unit, layer, lineId, transform}) {
  let yPos = -20;
  let distanceText = `${length.toFixed(2)} ${unit}`;
  let areaId = null;

  layer.get('areas')
    .entrySeq()
    .forEach(function ([areaID, area]) {
      if (area.vertices.includes(layer.lines.get(lineId).vertices.get(0))) {
        areaId = area.get('id');
      }
    });

    const vertices = [];
    const line = layer.lines.getIn([lineId, 'vertices']).toJS();

    layer.get('areas').get(areaId).vertices.forEach((verticeID) => {
      const {x, y} = layer.get('vertices').get(verticeID).toJS();
      vertices.push({
        verticeID, x, y
      })
    });

    const start = vertices.find((vertice) => vertice.verticeID === line[0]);
    const end = vertices.find((vertice) => vertice.verticeID === line[1]);
    const textCoord = findMidpoint(start, end);

    let angle = GeometryUtils.angleBetweenTwoPointsAndOrigin(start.x, start.y, end.x, end.y);

    yPos = movePointInside(textCoord, vertices, 20, angle);

  return (
    <g transform={transform}>
      <text x={length / 2} y={yPos} transform={`scale(1, -1)`} style={STYLE_TEXT}>{distanceText}</text>
      <line x1="0" y1="-5" x2="0" y2="5" style={STYLE}/>
      <line x1={length} y1="-5" x2={length} y2="5" style={STYLE}/>
      <line x1="0" y1="0" x2={length} y2="0" style={STYLE}/>
    </g>
  );

}

Ruler.propTypes = {
  length: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  layer: PropTypes.object.isRequired,
  transform: PropTypes.string.isRequired,
  lineId: PropTypes.string.isRequired
};
