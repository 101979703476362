/**
 * @param {number} alpha
 * @returns {number[]}
 */

export function rad2deg(alpha) {
    return alpha * 180 / Math.PI;
}

export function cart2sphere(x, y, z, posePitchCos=0, posePitchSin=0, poseRollCos=0, poseRollSin=0) {
    if ((posePitchCos && posePitchSin) || (poseRollCos && poseRollSin)) {
        posePitchSin = -posePitchSin;
        posePitchCos = -posePitchCos;
        poseRollCos = -poseRollCos;
        poseRollSin = -poseRollSin;

        let ox = px * poseRollCos + pz * poseRollSin;
        let oy = py * posePitchCos + posePitchSin * (px * poseRollSin - pz * poseRollCos);
        z = py * posePitchSin - posePitchCos * (px * poseRollSin - pz * poseRollCos);
        x = ox; y = oy;
    }

    let r = Math.sqrt(x * x + y * y + z * z);
    let pitch = Math.asin(Math.max(Math.min(z / r, 1), -1));
    let yaw = Math.atan2(x, y);

    return [yaw, pitch];
}

export function equi2sphere(x, y) {
    let xp = x / this._buffer.width;
    let yp = y / this._buffer.height;

    let yaw = xp * 360 - 180;
    let pitch = (yp - 0.5) * -180;

    return [deg2rad(yaw), deg2rad(pitch)];
}

export function get2DLineAngle([x1, y1], [x2, y2]) {
    return Math.atan2(y2 - y1, x2 - x1);
}

export function deg2rad(alpha) {
    return alpha * Math.PI / 180;
}

export function sphere2equi(yaw, pitch, width, height, posePitchCos=0, posePitchSin=0, poseRollCos=0, poseRollSin=0){
    [yaw, pitch] = sphereCoordsCorrection(yaw, pitch, {posePitchCos, posePitchSin, poseRollCos, poseRollSin})

    let yp = rad2deg(pitch) / -180 + 0.5;
    let xp = (rad2deg(yaw) + 180) / 360;

    return [xp * width, yp * height];
}

function sphereCoordsCorrection(yaw, pitch, {posePitchCos, posePitchSin, poseRollCos, poseRollSin}) {
    let [x, y, z] = spher2Cart(yaw, pitch, {posePitchCos, posePitchSin, poseRollCos, poseRollSin});

    let r = Math.sqrt(x*x + y*y + z*z);

    pitch = Math.asin(Math.max(Math.min(z/r, 1), -1));
    yaw = Math.atan2(x, y);

    return [yaw, pitch];
}

export function sphere22d(yaw, pitch) {
    let dist = pitch < 0 ? Math.tan(pitch + Math.PI / 2) : Math.tan(Math.PI / 2 - pitch) ;
    let angle = -yaw + Math.PI / 2;

    return [dist * Math.cos(angle), dist * Math.sin(angle)];
}

export function spher2Cart(yaw, pitch, {posePitchCos, posePitchSin, poseRollCos, poseRollSin}){
    let x = Math.cos(pitch)*Math.sin(yaw),
        y = Math.cos(pitch)*Math.cos(yaw),
        z = Math.sin(pitch);

    if ((posePitchCos && posePitchSin) || (poseRollCos && poseRollSin)) {
        posePitchSin = -posePitchSin;
        posePitchCos = -posePitchCos;
        poseRollCos = -poseRollCos;
        poseRollSin = -poseRollSin;

        let ox = px * poseRollCos + pz * poseRollSin;
        let oy = py * posePitchCos + posePitchSin * (px * poseRollSin - pz * poseRollCos);
        z = py * posePitchSin - posePitchCos * (px * poseRollSin - pz * poseRollCos);
        x = ox; y = oy;
    }

    return [x, y, z];
}

/**
 * Normalize yaw value within the range from -Math.PI to Math.PI
 * @param {number} yaw
 * @returns {number}
 */
export function normalizeYaw(yaw) {
    let PI2 = Math.PI * 2;
    return ((yaw + PI2 + Math.PI) % PI2) - Math.PI;
}