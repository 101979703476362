exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.planner {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column; }\n  .planner--header {\n    padding-bottom: 0 16px 16px 16px; }\n  .planner--wrapper {\n    display: flex;\n    width: 100%;\n    flex-grow: 1; }\n", ""]);

// exports
exports.locals = {
	"planner": "planner",
	"planner--header": "planner--header",
	"planner--wrapper": "planner--wrapper"
};