import React, { createContext, useState } from 'react';
import './style.scss';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [visible, setVisible] = useState(false); // Control visibility

  const showMessage = (msg, type) => {
    setMessage(msg);
    setMessageType(type);
    setVisible(true);
    setTimeout(() => setVisible(false), 3000); // Hide after 3 seconds
  };

  const showSuccess = (msg) => showMessage(msg, 'success');
  const showWarning = (msg) => showMessage(msg, 'warning');
  const showDanger = (msg) => showMessage(msg, 'danger');
  const showInfo = (msg) => showMessage(msg, 'info');

  return (
    <MessageContext.Provider value={{ showSuccess, showWarning, showDanger, showInfo }}>
      <div>
        {message && (
          <div className={`message ${messageType} ${visible ? 'visible' : ''}`}>
            {message}
          </div>
        )}
        {children}
      </div>
    </MessageContext.Provider>
  );
};

export default MessageContext;