import {areaCamera, describePolygon} from "a_root-folder/utils/areas-helper";
import polylabel from "polylabel";
import {getBBox} from "a_root-folder/utils/helper";
import {inside, findHighestPointInPolygon} from "a_root-folder/utils/geometry";
import {LABEL_STYLES} from "a_root-folder/constants";
import {getGroupBBox} from "a_root-folder/utils/helper";
import {polygonsIntersect} from "a_root-folder/utils/polygon-intersect";
import {FONT_SIZE, FONT_SIZE_ASPECT, FONT_SIZE_MEAUREMENT} from "../constants";
import {List} from 'immutable';

const paddingX = 10;

export const calculateMergeShadowParams = (fontSize, center, label, state, paddingY) => {
    const style ={...LABEL_STYLES[state], ...{fontSize}}
    const {y, width, height} = getBBox(label, style);
    const centerPaddingV = state === 'measurementShadow' ? 1 : 32;
    const directions = state === 'measurementShadow' ? 1 : 1;

    // move down from marker
    center[1] = center[1] - paddingY;

    return {
        x: -width / 2 - paddingX/2,
        y: y * directions - centerPaddingV,
        width: width + paddingX,
        height: height,
        center,
    }
};

export const calculateShadowParams = (area, layer, label, state, paddingY, unit) => {
    if (area.vertices.size < 3) return {...shadowDefault};

    const polygon = describePolygon(area, layer, unit);
    const center = polylabel([polygon], 1.0);

    const fontSizeArea = area.properties.get('fontSize') || FONT_SIZE;
    const fontSize = state === 'measurementShadow' ? fontSizeArea * FONT_SIZE_ASPECT : fontSizeArea;

    const style ={...LABEL_STYLES[state], ...{fontSize}}
    const {y, width, height} = getBBox(label, style);
    const centerPaddingV = state === 'measurementShadow' ? 2 : 3;
    const directions = state === 'measurementShadow' ? -1 : 1;

    // move down from marker
    center[1] = center[1] - paddingY;

    return {
        x: -width / 2 - paddingX/2,
        y: y * directions - centerPaddingV,
        width: width + paddingX,
        height: height,
        center,
    }
};

export const findCameraPadding = (area, layer, {label, measurement}, center, unit) => {
    const fontSizeArea = area.properties.get('fontSize') || FONT_SIZE;
    const {y, width, height} = getGroupBBox(label, measurement, fontSizeArea);
    const paddingV = 8;
    const xLabel = center[0] - width / 2 - paddingX / 2;
    const yLabel = center[1] - height / 2 - paddingV;

    const labelPolygon = [
        {x: xLabel, y: yLabel},
        {x: xLabel + width + paddingX, y: yLabel},
        {x: xLabel + width + paddingX, y: yLabel+height+paddingV},
        {x: xLabel, y: yLabel+height+paddingV},
    ]

    const camera = areaCamera(area, layer, unit);
    const markerWidth = 28;
    const markerHeight = 50;
    const xMarker = camera.x - markerWidth / 2;
    const yMarker = camera.y
    const markerPolygon = [
        {x: xMarker, y: yMarker},
        {x: xMarker + markerWidth, y: yMarker},
        {x: xMarker + markerWidth, y: yMarker+markerHeight},
        {x: xMarker, y: yMarker+markerHeight},
    ]
    let paddingY = 0;

    if((polygonsIntersect(labelPolygon, markerPolygon))  && !area.selected){
        const point = findHighestPointInPolygon(labelPolygon);
        paddingY = point.y - camera.y;
    }

    return paddingY;
}


export const shadowDefault = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    center: [0, 0],
}
