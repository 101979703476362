import {getPolygonBounding} from "a_common-folder/utils/geometry";
const polygonsIntersect = require('polygons-intersect');
import {THICKNESS} from '../constants';
import {convertMetric} from "../../../common/utils/metric";
import { AREA, CTHICKNESS, LENGTH, UNIT_COEF, UNIT_METER, UNIT_MILLIMETER} from "../../../common/constants";
import {isEdge} from 'a_common-folder/utils/detectBrowser';
import areapolygon from "area-polygon";
import {getVertexRenderCoords} from './layer-operations';

export const areaCamera = (area, layer, unit) => {
    const coff = UNIT_COEF[unit]
    const camera = layer.areas.get(area.get('id')).properties.get('scene').get('camera').toArray();
    const x = camera[0] * coff;
    const y = camera[1] * coff;

    return {
        x, y
    }
};

export const describePolygon = (area, layer, unit) => {
    return area.vertices.toArray().map(vertexID => {
        let {x, y} = getVertexRenderCoords(layer.vertices.get(vertexID), unit);
        return [x, y];
    })
};

export const calculateAreaSize = (area, layer, unit = UNIT_MILLIMETER) => {
    let polygon = describePolygon(area, layer, unit);
    let areaSize = areapolygon(polygon, false);

    return areaSize;
};

export function canMove(layer, areaID, points, x, y, thikness = THICKNESS) {
    let area = layer.areas.get(areaID);
    let verticesIds = area.vertices.toJS();

    return update(layer, areaID, updateVerticesPos(verticesIds, points, offset(x), offset(y)))
        && update(layer, areaID, updateVerticesPos(verticesIds, points, offset(x, -THICKNESS), offset(y, -THICKNESS)))
        && update(layer, areaID, updateVerticesPos(verticesIds, points, offset(x, -THICKNESS), offset(y)))
        && update(layer, areaID, updateVerticesPos(verticesIds, points, offset(x), offset(y, -THICKNESS)));
}

export function getPatternColors(scene) {
    const patternColors = [];

    scene.get('layers').map((layer) => {
        layer.get('areas').forEach((area) => patternColors.push(area.getIn(['properties', 'patternColor'])));
        // layer.get('mergeAreas').forEach((mergeAreas) => patternColors.push(mergeAreas.getIn ? mergeAreas.getIn(['patternColor']) : mergeAreas.patternColor));
    });

    return [...new Set(patternColors)];
}


export function getAreaSize(element, layer, scene, unit) {
    const metrical = scene.metrical
    let areaSize = calculateAreaSize(element, layer);
    const polygon = describePolygon(element, layer, unit);
    let pWidth = 0;
    let pHeight = 0;

    if (polygon.length) {
        const solver = new RotatingCalipers(polygon);
        pWidth = solver.minAreaEnclosingRectangle().width;
        pHeight = solver.minAreaEnclosingRectangle().height;
    } else {
        const {minX, minY, maxX, maxY} = getPolygonBounding(polygon);
        pWidth = maxX - minX;
        pHeight = maxY - minY;
    }

    let {value: pv, unit: pu} = convertMetric(areaSize / 10000, AREA, metrical, UNIT_METER, true);
    let {value: wv, unit: wu} = convertMetric(pWidth / 100, LENGTH, metrical, UNIT_METER, true, true, 1);
    let {value: hv, unit: hu} = convertMetric(pHeight / 100, LENGTH, metrical, UNIT_METER, true, true, 1);

    return {
        pv,
        wv,
        hv,
        pu,
        wu,
        hu,
    }
}

export function updatePoints(layer, areaID, points, cameraOldPos, x, y) {
    let area = layer.areas.get(areaID);
    let verticesIds = area.vertices.toJS();

    verticesIds.forEach((vertice) => {
        layer.updateIn(['vertices', vertice], vertex => vertex.merge({
            x: points[vertex.id].x + x,
            y: points[vertex.id].y + y
        }));
    })

    // layer.updateIn(['areas', areaID, 'properties', 'scene', 'camera'], camera => camera.merge({
    //     x: cameraOldPos.get('x') + x,
    //     y: cameraOldPos.get('y') + y
    // }));

    return layer;
}

export function updatePointForRotation(state) {
    let [area, layer] = getDataFromState(state);
    let verticesIds = area.vertices.toJS();
    let vertices = layer.vertices;

    let pointsForRotate = {};
    verticesIds.forEach((vertice) => {
        pointsForRotate[vertice] = {x: vertices.get(vertice).x, y: vertices.get(vertice).y};
    })

    return pointsForRotate;
}

export function getDataFromState(state) {
    let draggingSupport = state.draggingSupport;
    let layerID = draggingSupport.get('layerID');
    let areaID = draggingSupport.get('areaID');
    let layer = state.scene.layers.get(layerID);
    let area = layer.areas.get(areaID);

    return [area, layer, areaID, layerID, draggingSupport];
}

export function updateCameraPosition(state) {
    let [area] = getDataFromState(state);
    let camera = area.get('properties').get('scene').get('camera');

    return camera;
}

export function canScaling(layer, areaID, points) {
    return update(layer, areaID, points);
}

export function update(layer, areaID, pointsAfterChange) {
    let update = true;

    layer.areas.map((area) => {
        if (area.id !== areaID) {
            let verticesIds = area.vertices.toJS();
            let points = [];
            verticesIds.forEach((vertex) => {
                let ver = layer.vertices.get(vertex);
                points.push({
                    x: ver.x,
                    y: ver.y
                })
            })
            if (polygonsIntersect(pointsAfterChange, points).length) {
                update = false;
            }
        }
    })

    return update;
}

export function updateVerticesPos(verticesIds, points, x, y) {
    let pointsAfter = [];

    verticesIds.forEach((vertex) => {
        pointsAfter.push({
            x: points[vertex].x + x,
            y: points[vertex].y + y
        })
    })

    return pointsAfter;
}

export function offset(val, padding = THICKNESS) {
    return val + (Math.sign(val) == 0 ? 1 : Math.sign(val)) * padding;
}

