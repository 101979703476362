import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {diff} from "deep-object-diff";

export default class Outline extends Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const oldVertices = [{'selected': this.props.area.selected}];
        const newVertices = [{'selected': nextProps.area.selected}];

        if(!nextProps.area.vertices || !nextProps.area.vertices.size) return true;

        nextProps.area.vertices.forEach((vertexID, ind) => {
            if(!nextProps.layer.vertices.get(vertexID) || !this.props.layer.vertices.get(vertexID)) return true;

            const {x, y} = this.props.layer.vertices.get(vertexID);
            oldVertices.push({
                '2d': this.props.layer.vertices.get(vertexID)['2d'], x, y
            });

            const {x: x1, y: y1} = nextProps.layer.vertices.get(vertexID);
            newVertices.push({
                '2d': nextProps.layer.vertices.get(vertexID)['2d'], x: x1, y: y1
            });
        });

        if (Object.entries(diff(oldVertices, newVertices)).length || oldVertices.length === 1 || !nextProps.area.vertices.size || !this.props.area.vertices.size){
            return true;
        }else{
            return false;
        }
    }

    render() {
        // const Outline = React.memo(({layer, area, catalog, scene, rotatingSupport, mode}) => {
        let {layer, area, catalog, scene, rotatingSupport, mode} = this.props;
        let rendered = catalog.getElement(area.type).render2DOutline(area, layer, scene, rotatingSupport, mode, 20);

        return (
            <g
                data-element-root
                data-prototype={area.prototype}
                data-id={area.id}
                data-selected={area.selected}
                data-layer={layer.id}
                className='outline'
            >
                {rendered}
            </g>
        )
    }
}

Outline.propTypes = {
  area: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};



