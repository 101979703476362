import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormNumberInput from 'a_component-styles/form-number-input';
import FormTextInput from 'a_component-styles/form-text-input';

import c from 'a_component-styles/common.scss'

export default function ItemAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {
  let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;
  let renderedX = attributeFormData.has('x') ? attributeFormData.get('x') : element.x;
  let renderedY = attributeFormData.has('y') ? attributeFormData.get('y') : element.y;
  let renderedR = attributeFormData.has('rotation') ? attributeFormData.get('rotation') : element.rotation;

  return <div className={c['form-group-wrapper']}>
    <div className={`${c['form-group-row']} form-group`}>
      <label className={c['form-group-label']}>{translator.t('Name')}</label>
      <div className={c['form-group-field']}>
        <FormTextInput
          value={name}
          onChange={event => onUpdate('name', event.target.value)}
        />
      </div>
    </div>
    <div className={`${c['form-group-row']} form-group`}>
      <label className={c['form-group-label']}>X</label>
      <div className={c['form-group-field']}>
        <FormNumberInput
          value={renderedX}
          onChange={event => onUpdate('x', event.target.value)}
          state={state}
          configs={{precision: 2}}
          {...rest}
        />
      </div>
    </div>
    <div className={`${c['form-group-row']} form-group`}>
      <label className={c['form-group-label']}>Y</label>
      <div className={c['form-group-field']}>
        <FormNumberInput
          value={renderedY}
          onChange={event => onUpdate('y', event.target.value)}
          state={state}
          configs={{precision: 2}}
          {...rest}
        />
      </div>
    </div>
    <div className={`${c['form-group-row']} form-group`}>
      <label className={c['form-group-label']}>{translator.t('Rotation')}</label>
      <div className={c['form-group-field']}>
        <FormNumberInput
          value={renderedR}
          onChange={event => onUpdate('rotation', event.target.value)}
          state={state}
          configs={{precision: 2}}
          {...rest}
        />
      </div>
    </div>
  </div>
}

ItemAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

ItemAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
