import React from 'react';
import PropTypes from 'prop-types';
import { FormTextInput } from '../../components/style/export';
import {Map, Seq} from 'immutable';

import c from 'a_component-styles/common.scss'

export default function PropertySceneConfig({value, onUpdate, configs, sourceElement, internalState, state}) {

  let id = value.get('id') || 'none';
  let { hook, label, values } = configs;

  let update = (sceneId) => {
    let merged = value.merge(values[sceneId] ? values[sceneId] : {});

    if (hook) {
      return hook(merged, sourceElement, internalState, state).then(val => {
        return onUpdate(val);
      });
    }

    return onUpdate(merged);
  };

  const name = Object.values(values).filter((value) => value.id == id)[0].name;

  return (
    <div className={`${c['form-group-row']} form-group`}>
      <label className={c['form-group-label']}>{configs.label}</label>
      <div className={c['form-group-field']}>
        <FormTextInput value={name} disabled={true} onChange={() => {}}></FormTextInput>
      </div>
    </div>
  )
}

PropertySceneConfig.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};
