import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FormNumberInput, FormTextInput} from 'a_component-styles/export';
import {moveLayer} from 'a_root-folder/utils/layer-operations';

import {
    UNITS_LENGTH,
    UNIT_MILLIMETER,
    UNIT_FOOT,
    LENGTH,
    UNIT_METER,
    CONVERT_METER_INCH,
    CONVERT_INCH_METER,
    MODAL_TYPE_ALERT,
    UNIT_COEF,
    FONT_SIZE,
} from 'a_root-folder/constants';

import c from 'a_component-styles/common.scss';
import s from './scene-config.scss';
import {FormSelect} from "../../style/export";
import {SET_UNIT} from "../../../constants";
import {convertMetric} from 'a_common-folder/utils/metric';
import FormSlider from "../../style/form-slider";

const PRECISION = 0;

export default class SceneConfig extends Component {
    constructor(props) {
        super(props);

        let {width, height, metrical, unit} = props.scene;
        let selectedLayer = props.scene.getIn(['layers', props.scene.get('selectedLayer')]);

        if (!metrical) {
            width = this.convertMetricMeterInch(width);
            height = this.convertMetricMeterInch(height);
        }

        this.state = {
            dataWidth: width,
            dataHeight: height,
            metrical,
            unit,
            fontSize:  selectedLayer.get('fontSize') || FONT_SIZE,
            polygonsSize: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        let {scene} = nextProps;
        let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
        this.setState({
            fontSize:  selectedLayer.get('fontSize') || FONT_SIZE,
        })

    }

    submit(e) {
        e.preventDefault();

        let {projectActions, messageActions, modalActions} = this.context;
        let {scene: {layers, unit}} = this.props;

        let {dataWidth, fontSize, dataHeight, metrical} = this.state;
        if (!metrical) {
            dataWidth = this.convertMetricInchMeter(dataWidth);
            dataHeight = this.convertMetricInchMeter(dataHeight);
        }

        dataWidth = parseInt(dataWidth);
        dataHeight = parseInt(dataHeight);

        const offsets = this.calculateMinSizes(dataWidth, dataHeight);
        if (!offsets.allow[0]) {
            modalActions.showModal(MODAL_TYPE_ALERT, {
                title: 'Scene size too small! Please, change width value.'
            });
        } else if (!offsets.allow[1]) {
            modalActions.showModal(MODAL_TYPE_ALERT, {
                title: 'Scene size too small! Please, change height value.'
            });
        } else {
            this.applyAreaSize();
            messageActions.setFloorplanProperties({width: dataWidth, unit, height: dataHeight, metrical});
            messageActions.changeFontSizeLayer({fontSize});
        }
    }

    calculateMinSizes(sceneWidth, sceneHeight) {
        let {scene: {layers, width, height}} = this.props;
        let position = {left: Infinity, right: 0, top: 0, bottom: Infinity, paddingX: 10, paddingY: 10};

        layers.forEach((layer) => {
            layer.vertices.forEach((vertex) => {
                if (vertex.x > position.right) position.right = vertex.x;
                if (vertex.x < position.left) position.left = vertex.x;
                if (vertex.y > position.top) position.top = vertex.y;
                if (vertex.y < position.bottom) position.bottom = vertex.y;
            })
        });

        position.offsetX = (sceneWidth - width) / 2;
        position.offsetY = (sceneHeight - height) / 2 + position.paddingY;

        return {
            allow: [
                sceneWidth >= (position.right - position.left) && sceneWidth >= 100,
                sceneHeight >= (position.top - position.bottom) && sceneHeight >= 100
            ],
            y: position.offsetY,
            x: position.offsetX
        }
    }

    recalculateVertexes(offsetX, offsetY) {
        const {scene: {layers}} = this.props;

        return layers.map((layer) => {
            return layer.withMutations((mLayer) => {
                mLayer.vertices = mLayer.vertices.map(vertex => {
                    const x = vertex.get('x');
                    const y = vertex.get('y');
                    return vertex.set('x', x + offsetX).set('y', y + offsetY);
                })
            })
        });
    }

    convertMetricMeterInch(size) {
        const {value} = convertMetric(size, LENGTH, true, CONVERT_METER_INCH, true, true, 1);

        return value;
    }

    convertMetricInchMeter(size) {
        const {value} = convertMetric(size, LENGTH, true, CONVERT_INCH_METER, true, true, 1);

        return value;
    }

    applyAreaSize() {
        const {polygonsSize} = this.state;

        for(let areaID in polygonsSize){
            this.context.messageActions.updatePolygonSize({...polygonsSize[areaID], areaId: areaID})
        }
    }

    updateAreaSize(isMetricalVal, unit) {
        const {polygonsSize} = this.state;
        this.props.scene.get('layers').forEach((layer) => {
            layer.get('areas').forEach((area) => {
                let polygonSize = {};
                const areaID = area.get('id');

                ['width', 'length'].forEach((i) => {
                    const properties = area.getIn(['properties', i]);


                    if (properties.size >= 3) {
                        let length = properties.get('length');
                        let _length = properties.get('_length');

                        if (isMetricalVal) {
                            length = this.convertMetricInchMeter(length);
                            _length = this.convertMetricInchMeter(_length);
                        } else {
                            length = this.convertMetricMeterInch(length);
                            _length = this.convertMetricMeterInch(_length);
                        }

                        polygonSize[i] = {
                            length,
                            _length,
                            _unit: unit,
                        }
                    }
                })

                polygonsSize[areaID] = polygonSize;
            })
        })
    }

    setMetrical(metrical) {
        let {sceneActions} = this.context;
        let {dataWidth, dataHeight} = this.state;
        const isMetricalVal = UNIT_METER === metrical ? true : false;
        const unit = isMetricalVal ? UNIT_METER : UNIT_FOOT;

        sceneActions.setMetrical(isMetricalVal);
        this.updateAreaSize(isMetricalVal, unit);

        if (isMetricalVal) {
            dataWidth = this.convertMetricInchMeter(dataWidth);
            dataHeight = this.convertMetricInchMeter(dataHeight);
        } else {
            dataWidth = this.convertMetricMeterInch(dataWidth);
            dataHeight = this.convertMetricMeterInch(dataHeight);
        }

        dataWidth = parseInt(dataWidth);
        dataHeight = parseInt(dataHeight);

        this.setState({metrical: isMetricalVal, dataWidth, dataHeight});
    }

    close() {
        this.context.projectActions.rollback();
    }

    setSize(value, type) {
        this.setState({[type]: value});
    }

    changeFontSize(value) {
        let scene = this.props.scene;
        let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);

        this.setState({fontSize: value});
        this.context.layerActions.changeFontSize(selectedLayer.id, value);
    }

    render() {
        let {dataWidth, fontSize, metrical, dataHeight} = this.state;
        const unit = metrical ? UNIT_METER : UNIT_FOOT;

        return <div className="scene-config" tabIndex="0"
                    onKeyDown={event => event.stopPropagation()}
                    onKeyUp={event => event.stopPropagation()}>
            <div className="scene-config-header">
                Config scene dimensions
            </div>
            <div className="scene-config-actions">
                <button onClick={e => this.close()} className='btn white btn-sm'>
                    <i className="fa fa-times"/> Close
                </button>
            </div>
            <div className={s['scene-config-body']}>
                <form action="" onSubmit={e => this.submit(e)}>
                    <div className={c['form-group-wrapper']}>
                        <div className={`${c['form-group-row']} form-group`}>
                            <label className={c['form-group-label']}>Width</label>
                            <div className={`${c['form-group-field']} ${c['field-measure']}`}>
                                <FormNumberInput
                                    value={dataWidth}
                                    onChange={e => this.setSize(e.target.value, 'dataWidth')}
                                    placeholder="width"
                                    configs={{precision: PRECISION}}
                                />
                                <span className='units-label'>{unit}</span>
                            </div>
                        </div>
                        <div className={`${c['form-group-row']} form-group ${c['mb-10']}`}>
                            <label className={c['form-group-label']}>Height</label>
                            {/*<div className={c['form-group-field']}>*/}
                            <div className={`${c['form-group-field']} ${c['field-measure']}`}>
                                <FormNumberInput
                                    value={dataHeight}
                                    onChange={e => this.setSize(e.target.value, 'dataHeight')}
                                    placeholder="height"
                                    configs={{precision: PRECISION}}
                                />
                                <span className='units-label'>{unit}</span>
                            </div>
                        </div>
                        <div className={`${c['form-group-row']} form-group ${c['mb-10']}`}>
                            <label className={c['form-group-label']}>Dimensions</label>
                            {/*<div className={c['form-group-field']}>*/}
                            <div className={`${c['form-group-field']} ${c['field-measure']}`}>
                                <FormSelect value={unit} onChange={event => this.setMetrical(event.target.value)}
                                            classNames={'measure-group-select'}>
                                    {
                                        UNITS_LENGTH.filter((dimension) => [UNIT_METER, UNIT_FOOT].includes(dimension)).map(el =>
                                            <option key={el} value={el}>{el}</option>)
                                    }
                                </FormSelect>
                            </div>
                        </div>
                        <div className={`${c['form-group-row']} form-group ${c['mb-10']}`}>
                            <div className="element-editor-actions" style={{padding: '0'}}>
                                <FormSlider
                                    style={{color: '#d1d6de'}}
                                    label="Font size"
                                    min={10}
                                    max={48}
                                    step={1}
                                    precision={1}
                                    value={fontSize}
                                    onChange={e =>this.changeFontSize(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={`${c['form-group-row']} form-group`}>
                            <button type="submit" className="btn blue btn-block">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    }
}

SceneConfig.propTypes = {
    scene: PropTypes.object.isRequired
};

SceneConfig.contextTypes = {
    sceneActions: PropTypes.object.isRequired,
    projectActions: PropTypes.object.isRequired,
    modalActions: PropTypes.object.isRequired,
    messageActions: PropTypes.object.isRequired,
    layerActions: PropTypes.object.isRequired,
    metrical: PropTypes.bool
};
