exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.corridor-template {\n  display: flex;\n  flex-direction: column;\n  gap: 16px; }\n  .corridor-template--container {\n    display: grid;\n    grid-template-columns: auto auto auto;\n    grid-template-rows: auto auto auto;\n    gap: 16px;\n    grid-auto-flow: row;\n    grid-template-areas: \"up up right\" \"img img right\" \". . right\";\n    place-items: center; }\n  .corridor-template .up, .corridor-template .right, .corridor-template .left, .corridor-template .down {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 8px; }\n  .corridor-template .up {\n    grid-area: up; }\n  .corridor-template .right {\n    grid-area: right; }\n  .corridor-template .img {\n    grid-area: img; }\n", ""]);

// exports
exports.locals = {
	"corridor-template": "corridor-template",
	"corridor-template--container": "corridor-template--container",
	"up": "up",
	"right": "right",
	"left": "left",
	"down": "down",
	"img": "img"
};