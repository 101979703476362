import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LayersManagerListItem from './list-item';

import s from './layers-maneger.scss';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
const debounce = require('lodash/debounce');

const SortableItem = SortableElement(({value, onLayerSelect, selectedLayer}) =>  <LayersManagerListItem
    layersSize={value.size}
    key={value.id}
    layer={value}
    selectedLayer={selectedLayer}
    onSelect={onLayerSelect.bind(this)}
/>);

const SortableList = SortableContainer(({items, selectedLayer, onLayerSelect}) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value} selectedLayer={selectedLayer} onLayerSelect={onLayerSelect.bind(this)}/>
            ))}
        </ul>
    );
});

export default class LayersManagerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layers: []
        }
        this.updateState = debounce((layers) => {
            this.setState({layers})
        }, 300)
    }

    componentWillReceiveProps(nextProps){
        const {scene: {layers: layers}} = nextProps;
        const byAltitude = this.sortByAltitude(layers);
        this.updateState(byAltitude);
    }

    sortByAltitude(layers){
        let byAltitude = !Array.isArray(layers) ? Object.values(layers.toJS()).slice(0) : layers;
        byAltitude.sort(function(a,b) {
            return a.altitude - b.altitude;
        });

        return byAltitude;
    }

    onLayerSelect(e, id) {
        e.preventDefault();
        this.context.sceneActions.selectLayer(id);
        this.context.messageActions.selectLayer(id);
    }

    changeFloors({oldIndex, newIndex}){
        let {layers} = this.state;
        let altitudes = layers.map((layer) => layer.altitude);
        let ids = layers.map((layer) => layer.id);
        ids = arrayMove(ids, oldIndex, newIndex)
        let arr = [];
        ids.forEach((id, index) => {
            let layer = layers.filter((layer) => layer.id == id)[0];
            layer.altitude = altitudes[index];
            arr.push({id, altitude: layer.altitude});
        })
        const byAltitude = this.sortByAltitude(layers);
        this.setState({layers: byAltitude});

        this.context.messageActions.changeFloors(arr);
    }

    onSortEnd({oldIndex, newIndex}) {
        if(oldIndex == newIndex){
            const {layers} = this.state;
            this.onLayerSelect({preventDefault: () => {}}, layers[oldIndex].id);
        }else{
            this.changeFloors({oldIndex, newIndex})
        }
    }
    
    render() {
        const {scene: {selectedLayer}} = this.props;
        const {layers} = this.state;

        return <div className={s['layers-manager-list']}>
            <div className={s['layers-manager-list-wrapper']}>
                <SortableList items={layers} selectedLayer={selectedLayer} onLayerSelect={this.onLayerSelect.bind(this)} onSortEnd={this.onSortEnd.bind(this)}/>;
            </div>
        </div>
    }
}

LayersManagerList.contextTypes = {
    sceneActions: PropTypes.object.isRequired,
    messageActions: PropTypes.object.isRequired,
};
