exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".upload-tool {\n  position: fixed;\n  bottom: 16px;\n  right: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  z-index: 1000;\n  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);\n  padding: 8px 16px 16px;\n  background: white;\n  width: 300px; }\n  .upload-tool--total {\n    position: relative;\n    width: 100%;\n    height: 8px;\n    margin-bottom: 0;\n    opacity: 1;\n    background: #4e4e4e; }\n    .upload-tool--total .progress-bar {\n      background: #008000;\n      height: 100%; }\n  .upload-tool--block {\n    height: 20px;\n    font-size: 0.8em;\n    color: black;\n    font-weight: bold;\n    border-top: 2px solid #e7ecf1;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    align-items: center; }\n  .upload-tool--title {\n    text-align: left;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    display: flex;\n    justify-content: start;\n    gap: 8px; }\n    .upload-tool--title img {\n      width: 24px; }\n  .upload-tool--text {\n    text-align: left;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    padding: 4px 16px 0 8px; }\n  .upload-tool--progress {\n    transform: translate(0, 2px); }\n    .upload-tool--progress span {\n      width: 100%;\n      display: block;\n      height: 8px;\n      transition: width 2s; }\n  .upload-tool--close {\n    position: absolute;\n    top: 8px;\n    right: 8px; }\n  .upload-tool--error {\n    font-size: 0.8em;\n    background-color: #fbe1e3;\n    padding: 8px; }\n", ""]);

// exports
exports.locals = {
	"upload-tool": "upload-tool",
	"upload-tool--total": "upload-tool--total",
	"progress-bar": "progress-bar",
	"upload-tool--block": "upload-tool--block",
	"upload-tool--title": "upload-tool--title",
	"upload-tool--text": "upload-tool--text",
	"upload-tool--progress": "upload-tool--progress",
	"upload-tool--close": "upload-tool--close",
	"upload-tool--error": "upload-tool--error"
};