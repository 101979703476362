import {LISTENER_READY} from 'a_root-folder/constants';
import MessageEmitter from 'a_root-folder/utils/message-emitter';
import {loadProject} from './project-actions';

const emitter = new MessageEmitter();

export function start() {
    return (dispatch) => {
        emitter.send({event:'e3.planner.ready'});
        emitter.on('e3.admin.config', (data) => {
            dispatch(loadProject(data.message));
        });
    
        return {
            type: LISTENER_READY
        }
    }
}

