import React, { useEffect, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MainApplication from './bundle';
import UploadQueueContext from '../../hoc/with-upload-queue';
import { useLocation } from 'react-router-dom';

import './style.scss';

export default () => {
    const { uploadWithProgress } = useContext(UploadQueueContext);
    const params = useParams();
    const history = useHistory();
    const appRef = useRef(null); 
    const location = useLocation();

    const getTitleFromQuery = () => {
      const params = new URLSearchParams(location.search);
      return params.get('title') || 'Default Title';
    };

    useEffect(() => {
      let wrapper = null
      async function fetchData() {
        const onSave = (obj) => {
          const title = getTitleFromQuery();
          uploadWithProgress({...obj, title});
          history.push(`/planner`);
        }
        const app = new MainApplication(params.type.toUpperCase(), onSave);
        await app.init();
        wrapper = app.getWrapper();

        if (appRef.current) {
            appRef.current.appendChild(wrapper);
        }
      }
      fetchData();

      return () => {
          if (appRef.current && wrapper) {
              appRef.current.removeChild(wrapper);
          }
      };
    }, []);

    return (
        <section className="scan-page">
            <div ref={appRef} id="app"></div>
         </section>
    )
};