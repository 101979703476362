import {
  SELECT_AREA, REMOVE_MERGE_AREAS, PUT_MERGE_AREAS, MERGE_AREAS, SELECT_AREAS, BEGIN_DRAGGING_AREA, UPDATE_DRAGGING_AREA, END_DRAGGING_AREA, BEGIN_SCALING_AREA, END_SCALING_AREA,
  UPDATE_SCALING_AREA, BEGIN_SCALING_AREA_VERTEX, ROTATE, BEGIN_ROTATING_AREA, UPDATE_ROTATING_AREA, END_ROTATING_AREA,
  MOVE_AREA, REMOVE_AREA, RENAME_AREA
} from '../constants';

export function selectArea(layerID, areaID, shiftKey=false) {
return {
  type: SELECT_AREA,
  layerID,
  areaID,
  shiftKey
}
}

export function selectAreas(layerID, areaID) {
return {
  type: SELECT_AREAS,
  layerID,
  areaID
}
}

export function putMergeAreas(layerID, selectedAreas, values) {
return {
  type: PUT_MERGE_AREAS,
  layerID,
  selectedAreas,
  values
}
}

export function mergeAreas(layerID, selectedAreas, values) {
return {
  type: MERGE_AREAS,
  layerID,
  selectedAreas,
  values
}
}

export function moveArea(diffX, diffY) {
return {
  type: MOVE_AREA,
  diffX,
  diffY
}
}

export function rotatearea(deg) {
  return {
      type: ROTATE,
      deg
  }
}

export function beginRotatingArea(layerID, areaID, x, y) {
return {
  type: BEGIN_ROTATING_AREA,
  layerID, areaID, x, y
};
}

export function updateRotatingArea(layerID, areaID, x, y) {
return {
  type: UPDATE_ROTATING_AREA,
  layerID, areaID, x, y
};
}

export function endRotatingArea(layerID, areaID, x, y) {
return {
  type: END_ROTATING_AREA,
  layerID, areaID, x, y
};
}

export function removeMergeAreas(layerID, selectedAreas) {
return {
  type: REMOVE_MERGE_AREAS,
  layerID,
  selectedAreas
}
}

export function beginDraggingArea(layerID, areaID, x, y) {
  return {
      type: BEGIN_DRAGGING_AREA,
      layerID, areaID, x, y
  };
}

export function beginScalingArea(layerID, areaID, x, y) {
  return {
      type: BEGIN_SCALING_AREA,
      layerID, areaID, x, y
  };
}

export function beginScalingAreaVertex(layerID, vertexID, x, y) {
  return {
      type: BEGIN_SCALING_AREA_VERTEX,
      layerID, vertexID, x, y
  };
}

export function updateDraggingArea(x, y) {
  return {
      type: UPDATE_DRAGGING_AREA,
      x, y
  };
}

export function updateScalingArea(x, y) {
  return {
      type: UPDATE_SCALING_AREA,
      x, y
  };
}

export function endDraggingArea(x, y) {
  return {
    type: END_DRAGGING_AREA,
    x, y
  };
}

export function endScalingArea(x, y) {
  return {
      type: END_SCALING_AREA,
      x, y
  };
}

export function removeArea(layerID, areaID) {
  return {
      type: REMOVE_AREA,
      layerID, areaID
  };
}

export function renameArea(layerID, areaID, name) {
  return {
    type: RENAME_AREA,
    layerID,
    areaID,
    name
  }
}