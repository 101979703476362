exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  --grey-color: $grey-color;\n  --white-color: $white-color;\n  --black-color: $black-color;\n  --grey-light-color: $grey-light-color; }\n\n.navigation-back {\n  width: 100%;\n  margin: 16px auto;\n  border-bottom: 1px dashed; }\n  .navigation-back--button {\n    position: absolute;\n    left: 0px;\n    top: 50%;\n    transform: translate(0, -50%); }\n  .navigation-back--heading {\n    width: 100%;\n    padding: 16px; }\n  .navigation-back--overlay {\n    margin: 0 16px;\n    position: relative; }\n", ""]);

// exports
exports.locals = {
	"navigation-back": "navigation-back",
	"navigation-back--button": "navigation-back--button",
	"navigation-back--heading": "navigation-back--heading",
	"navigation-back--overlay": "navigation-back--overlay"
};