import React, {useState, useEffect, useRef, useContext} from 'react';
import Immutable from 'immutable';
import immutableDevtools from 'immutable-devtools';
import MyCatalog from '../../catalog/mycatalog';
import {
  ReactPlanner,
  Plugins as PlannerPlugins,
} from 'react-planner';
import EventService from '../../helpers/eventService';
import { useHistory } from 'react-router-dom';
import { update }  from '../../api/floorplan';
import { getUserID, getTourId, getItem } from '../../helpers/localStorage';
import {FLOORPLAN_NAME} from '../../constants';
import LoadingContext from '../../hoc/loading/index';
import UploadQueueContext from '../../hoc/with-upload-queue/index';

import './style.scss';

let blackList = isProduction === true ? [] : [
  'UPDATE_MOUSE_COORDS',
  'UPDATE_ZOOM_SCALE',
  'UPDATE_2D_CAMERA'
];

if( !isProduction ) {
  console.info('Environment is in development and these actions will be blacklisted', blackList);
  console.info('Enable Chrome custom formatter for Immutable pretty print');
  immutableDevtools( Immutable );
}

let plugins = [
  PlannerPlugins.Keyboard(),
  PlannerPlugins.Autosave('react-planner_v0'),
  PlannerPlugins.ConsoleDebugger(),
];

let toolbarButtons = [
];

export default () => {
    const history = useHistory();
    const containerRef = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const { removeWithProgress } = useContext(UploadQueueContext);

    useEffect(() => {
        const updateSize = () => {
          if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setSize({ width, height });
          }
        };
    
        updateSize(); 
        window.addEventListener('resize', updateSize); 
    
        return () => window.removeEventListener('resize', updateSize);
      }, []);

      useEffect(() => {
        const onClose = async () => {
          removeWithProgress();
          showLoading();
          const floorplan_json = getItem(FLOORPLAN_NAME);
          await update(getUserID(), {id: getTourId(), floorplan_json});
          hideLoading();
          history.push(`/`)
        };
        const onRoute = (e) => history.push(e.detail.go);
    
        EventService.addListener("on-close", onClose);
        EventService.addListener("on-route", onRoute);

        return () => {
          EventService.removeListener("on-close", onClose);
          EventService.removeListener("on-route", onRoute);
        };
      }, []);

    return (
        <div className='planner'>   
            <div className='planner--wrapper' ref={containerRef}>
                <ReactPlanner
                    catalog={MyCatalog}
                    width={size.width}
                    height={size.height}
                    plugins={plugins}
                    toolbarButtons={toolbarButtons}
                    stateExtractor={state => state.get('react-planner')}
                />
            </div>
        </div>
    )
}

